import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class FSClientErrorHandler implements ErrorHandler {
    constructor(private _loggingService: LoggingService) {}

    public handleError(error: any): void {
        this._loggingService.logError(error.message, error.stack, [error]);
    }
  }