import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Inject, ChangeDetectorRef, Component, ContentChild, ElementRef, Input, OnInit, PLATFORM_ID, TemplateRef, ViewRef } from '@angular/core';

@Component({
    selector: 'lazy-load',
    template: `<ng-container *ngIf="inView" [ngTemplateOutlet]="template"></ng-container>`,
    standalone: true,
    imports: [CommonModule]
})
export class LazyLoadComponent implements OnInit {
    public inView: boolean;
    public hasBeenEagerlyLoaded: boolean;

    @Input() public set eagerlyLoad(value: boolean) {
        if (value === true) {
            if (!this.inView) {
                this.load();
                this.hasBeenEagerlyLoaded = true;
            }
        }
    }

    @ContentChild(TemplateRef, { read: TemplateRef }) template: TemplateRef<any>;

    constructor(
        private _element: ElementRef,
        private _changeDetector: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private _platformId) { }

    ngOnInit() {
        this.lazyLoad()
    }

    private get shouldLazyLoad(): boolean {
        if (isPlatformBrowser(this._platformId)) {
            return window && 'IntersectionObserver' in window;
        }
    }

    private lazyLoad(): void {
        if (this.shouldLazyLoad) {
            const options = { rootMargin: '500px 0px' };
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(({ isIntersecting }) => {
                    if (isIntersecting) {
                        this.load();
                        observer.unobserve(this._element.nativeElement);
                    }
                });
            }, options);
    
            observer.observe(this._element.nativeElement);
        }
    }

    private load(): void {
        this.inView = true;

        const vr: ViewRef = (this._changeDetector as ViewRef);
        if (vr && !vr.destroyed) {
            this._changeDetector.detectChanges();
        }
    }
}
