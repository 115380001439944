import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginHistoryResult } from '@shared/Modules/Identity/Models/LoginDTO';
import { SearchHistoryResult } from '@shared/Models/SearchBarModel';
import { FSWebWorkerRequest, FSWebWorkerRequestValue } from '@shared/Models/WebWorkerModel';
import { LoggingService } from './logging.service';

declare var fisheriesModule: any;

@Injectable()
export class WebWorkerService {
    public worker: Worker;

    private _fsSearchHistory: BehaviorSubject<SearchHistoryResult[]> = new BehaviorSubject(null);
    public readonly fsSearchHistorySubscription$: Observable<SearchHistoryResult[]> = this._fsSearchHistory.asObservable();

    public updateSearchHistory(searchHistory: SearchHistoryResult[]): void {
        this._fsSearchHistory.next(searchHistory);
    }
    
    private _fsUserIP: BehaviorSubject<string> = new BehaviorSubject(null);
    public readonly fsUserIPSubscription$: Observable<string> = this._fsUserIP.asObservable();

    public updateUserIP(userIP: string): void {
        this._fsUserIP.next(userIP);
    }

    private get loginHistoryBehaviorSubject(): BehaviorSubject<LoginHistoryResult[]> {
        if (this.fisheriesModule) {
            return this.fisheriesModule.loginHistoryBehaviorSubject;
        }
    }

    public get fsLoginHistorySubscription$(): Observable<LoginHistoryResult[]> {
        if (this.fisheriesModule) {
            return this.fisheriesModule.loginHistoryObservable;
        }
    }

    public updateLoginHistory(loginHistory: LoginHistoryResult[]): void {
        this.loginHistoryBehaviorSubject.next(loginHistory);
    }

    constructor(private _loggingService: LoggingService) {
        this.worker = fisheriesModule.worker;

        this.worker.onmessage = e => {
            if (e.data) {
                switch (e.data.type) {
                    case 'fsSearchHistory': {

                        if (!e.data.response) {
                            e.data.response = {};
                        }

                        this._loggingService.logDeveloperInfo('got fsSearchHistory:', e.data);

                        const searchHistoryResults: SearchHistoryResult[] = [];

                        if (e.data.response && e.data.response.dto) {
                            for (let entry of e.data.response.dto) {
                                searchHistoryResults.push(entry);
                            }
                        }

                        this.updateSearchHistory(searchHistoryResults);

                        break;
                    }

                    case 'fsLoginHistory': {
                        this._loggingService.logDeveloperInfo('got fsLoginHistory:', e.data);

                        const loginHistoryResults: LoginHistoryResult[] = [];

                        if (e.data.response && e.data.response.dto) {
                            for (let entry of e.data.response.dto) {
                                loginHistoryResults.push(entry);
                            }
                        }

                        this.updateLoginHistory(loginHistoryResults);

                        break;
                    }

                    case 'fsUserIP': {
                        this._loggingService.logDeveloperInfo('got fsUserIP:', e.data);

                        let userIP: string;

                        if (e.data.response && e.data.response.dto) {
                            userIP = e.data.response.dto;
                        }

                        this.updateUserIP(userIP);

                        break;
                    }

                }
            }
        }
    }

    public get fisheriesModule(): any {
        if (typeof window !== 'undefined') {
            return (<any>window).fisheriesModule;
        } else {
            return {};
        }
    }

    public postMessage(action: string, type: string, dto?: any): void {
        const request = new FSWebWorkerRequest();
        request.action = action;
        request.value = new FSWebWorkerRequestValue();
        request.value.dto = dto;
        request.value.type = type;

        this.worker.postMessage(request);
    }
}
