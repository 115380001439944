import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RedirectService } from '@shared/Services/redirect.service';
import { SettingsAccessor } from '@shared/Services/settings.accessor';
import { LoginDTO } from '../Models/LoginDTO';
import { RegisterCustomerDTO } from '../Models/RegistrationDTO';
import { PasswordValidationService, PasswordHint } from '../Services/password-validation.service';
import { RegistrationService } from '../Services/registration.service';

@Component({
    selector: 'shared-login-registration',
    templateUrl: './shared-login-registration.component.html'
})

export class SharedLoginRegistrationComponent implements OnInit {
    private _registrationDetails: RegisterCustomerDTO = new RegisterCustomerDTO();

    public registrationForm: UntypedFormGroup;
    public formSubmitAttempt = false;
    public shouldShowAccountNumberField = false;
    public passwordType = 'password';
    public registrationRequested: boolean;
    public initialFocus = true;

    @ViewChild('autoFocus', { read: ElementRef }) set autoFocus(content: ElementRef) {
        if (content && this.initialFocus) {
            this.initialFocus = false;
            setTimeout(() => { content.nativeElement.focus() }, 0);
        }
    }

    constructor(
        private _router: Router,
        private _passwordValidation: PasswordValidationService,
        private _registrationService: RegistrationService,
        private _loginDto: LoginDTO,
        private _builder: UntypedFormBuilder,
        private _redirect: RedirectService,
        private _settingsAccessor: SettingsAccessor) { }

    ngOnInit() {
        this.registrationForm = this._builder.group({
            email: [this._loginDto.email, [Validators.required]],
            confirmEmail: ['', [Validators.required, Validators.email]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.minLength(9)]],
            password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/[a-z]/), Validators.pattern(/[A-Z]/),
            Validators.pattern(/[\d]/), Validators.pattern(/[!"#$%&"()*+,-./:;<=>?@[\]^_`{|}~]/)]],
            confirmPassword: ['', [Validators.required]],
            account: ['no'],
            emailOptIn: [true],
            // pro: [false, []],
            // deals: [false, []],
            showPassword: ['', []],
            accountNumber: [0, []]
        }, { validator: this.emailsMatchValidator }
        );

        this.registrationForm.controls['account'].valueChanges.subscribe((val) => {
            this.changeAccount(val);
        });

        this.registrationForm.controls['showPassword'].valueChanges.subscribe((val) => {
            this.changePasswordType(val);
        });
    }

    public get hasFisheriesAccount(): boolean {
        return this.registrationForm.controls['account'].value === "yes";
    }

    public get p21ReadOnly(): boolean {
        return this._settingsAccessor.p21ReadOnly === "True" ? true : false;
    }

    // password validation

    public get passwordInputTouched(): boolean {
        return this.registrationForm.controls['password'].touched;
    }

    public get confirmPasswordInputTouched(): boolean {
        return this.registrationForm.controls['confirmPassword'].touched;
    }

    public get passwordHints(): PasswordHint[] {
        return this._passwordValidation.passwordHints;
    }

    public validatePassword(): void {
        this._passwordValidation.password = this.registrationForm.value.password;
        this._passwordValidation.validatePassword();

        if (this.registrationForm.value.confirmPassword) {
            this.setConfirmPassword();
        }
    }

    public setConfirmPassword(): void {
        this._passwordValidation.confirmPassword = this.registrationForm.value.confirmPassword;

        if (!this._passwordValidation.passwordsMatch) {
            this.registrationForm.controls['confirmPassword'].setErrors({ matchValue: false });
        } else {
            this.registrationForm.controls['confirmPassword'].setErrors(null);
        }
    }

    public changePasswordType(val: boolean): void {
        if (val === true) {
            this.passwordType = 'text';
        } else {
            this.passwordType = 'password';
        }
    }

    public get shouldShowAccountHelpMessage(): boolean {
        return this.registrationForm.controls['account'].value === 'maybe';
    }

    public emailsMatchValidator(control: AbstractControl): void {
        const parentEmail = control.get('email').parent.value.email;
        const confirmEmail = control.get('confirmEmail').value;

        if (confirmEmail !== parentEmail) {
            control.get('confirmEmail').setErrors({ 'noMatch': true });
        } else {
            control.get('confirmEmail').setErrors(null);
        }
    }

    public goToEmail(): void {
        if (this._router.url.includes('proceed')) {
            this._redirect.goToOrderLoginEmail();
        } else {
            this._redirect.goToLoginEmail();
        }
    }

    private changeAccount(val: string): void {
        if (val === 'yes' || val === 'maybe') {
            this.registrationForm.controls['accountNumber'].setValidators
            ([Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern(/^[0-9]*$/)]);
            this.shouldShowAccountNumberField = true;
        } else {
            this.registrationForm.controls['accountNumber'].clearValidators();
            this.registrationForm.controls['accountNumber'].updateValueAndValidity();
            this.shouldShowAccountNumberField = false;
        }
    }

    public isFieldInvalid(field: string): boolean {
        return (
            (!this.registrationForm.get(field).valid && this.registrationForm.get(field).touched) ||
            (!this.registrationForm.get(field).valid && this.formSubmitAttempt)
        );
    }

    public displayFieldCss(field: string) {
        const invalid: boolean = this.isFieldInvalid(field);
        if (invalid) {
            return 'fs-red';
        } else {
            return 'fs-green';
        }
    }

    public onSubmit(): void {
        this.formSubmitAttempt = true;

        if (this.registrationForm.valid) {
            this.registrationRequested = true;
            this.addValuesToRegisterCustomerDTO();

            this._registrationService.postRegisterCustomer(this._registrationDetails).subscribe((res) => {
                if (res.success) {
                    if (this._router.url.includes("order")) {
                        this._redirect.goToOrderRegistrationSuccess();
                    } else {
                        this._redirect.goToLoginRegistrationSuccess();
                    }
                }

                this.registrationRequested = false;
            });
        }
    }

    private addValuesToRegisterCustomerDTO(): void {
        this._registrationDetails.email = this.registrationForm.controls['email'].value;
        this._registrationDetails.password = this.registrationForm.controls['password'].value;
        this._registrationDetails.firstName = this.registrationForm.controls['firstName'].value;
        this._registrationDetails.lastName = this.registrationForm.controls['lastName'].value;
        this._registrationDetails.phone = this.registrationForm.controls['phone'].value;
        this._registrationDetails.signupForMailingList = this.registrationForm.controls['emailOptIn'].value;
    }
}
