export class CloudinaryImage {
  public type: CloudinaryImageType;
  public breakpoints: ImageBreakpoints;
  public createUrl: Function | undefined;

  public get src(): string {
      return this.createUrl(this.breakpoints ? this.breakpoints[1400] : null);
  }

  public get sizes(): string {
      var sizes = "";
      
      if (this.breakpoints) {
        if (this.breakpoints[1400])
          sizes += `(min-width:1400px) ${this.breakpoints[1400]}px,`
        if (this.breakpoints[1200])
          sizes += `(min-width:1200px) ${this.breakpoints[1200]}px,`
        if (this.breakpoints[768])
          sizes += `(min-width:768px) ${this.breakpoints[768]}px,`
        if (this.breakpoints[430])
          sizes += `(min-width:430px) ${this.breakpoints[430]}px,`
        if (this.breakpoints[393])
          sizes += `(min-width:393px) ${this.breakpoints[393]}px,`

        sizes += `${this.breakpoints[320]}px`
      }

      return sizes;
  }

  public get srcset(): string {
      var srcset = "";
        
      if (this.breakpoints) {
        if (this.breakpoints[320])
          srcset += `${this.createUrl(this.breakpoints[320])} ${this.breakpoints[320]}w,`
        if (this.breakpoints["x2320"])
          srcset += `${this.createUrl(this.breakpoints["x2320"])} ${this.breakpoints["x2320"]}w,`
        if (this.breakpoints["x3320"])
          srcset += `${this.createUrl(this.breakpoints["x3320"])} ${this.breakpoints["x3320"]}w,`
        if (this.breakpoints[393])
          srcset += `${this.createUrl(this.breakpoints[393])} ${this.breakpoints[393]}w,`
        if (this.breakpoints["x2393"])
          srcset += `${this.createUrl(this.breakpoints["x2393"])} ${this.breakpoints["x2393"]}w,`
        if (this.breakpoints["x3393"])
          srcset += `${this.createUrl(this.breakpoints["x3393"])} ${this.breakpoints["x3393"]}w,`
        if (this.breakpoints[430])
          srcset += `${this.createUrl(this.breakpoints[430])} ${this.breakpoints[430]}w,`
        if (this.breakpoints["x2430"])
          srcset += `${this.createUrl(this.breakpoints["x2430"])} ${this.breakpoints["x2430"]}w,`
        if (this.breakpoints["x3430"])
          srcset += `${this.createUrl(this.breakpoints["x3430"])} ${this.breakpoints["x3430"]}w,`
        if (this.breakpoints[768])
          srcset += `${this.createUrl(this.breakpoints[768])} ${this.breakpoints[768]}w,`
        if (this.breakpoints["x2768"])
          srcset += `${this.createUrl(this.breakpoints["x2768"])} ${this.breakpoints["x2768"]}w,`
        if (this.breakpoints[1200])
          srcset += `${this.createUrl(this.breakpoints[1200])} ${this.breakpoints[1200]}w,`
        if (this.breakpoints["x21200"])
          srcset += `${this.createUrl(this.breakpoints["x21200"])} ${this.breakpoints["x21200"]}w,`

        srcset += `${this.createUrl(this.breakpoints[1400])} ${this.breakpoints[1400]}w,
          ${this.createUrl(this.breakpoints["x151400"])} ${this.breakpoints["x151400"]}w,
          ${this.createUrl(this.breakpoints["x21400"])} ${this.breakpoints["x21400"]}w`
      }

      return srcset;
  }
}

export enum CloudinaryImageType {
  line,
  carouselPrimary,
  carouselIndicator,
  carouselModal,
  clickableImage,
  resultCard,
  resultLine,
  resultFeatured
}

export class ImageBreakpoints {
  public 320: number;
  public x2320: number;
  public x3320: number;
  public 393: number;
  public x2393: number;
  public x3393: number;
  public 430: number;
  public x2430: number;
  public x3430: number;
  public 768: number;
  public x2768: number;
  public 992: number;
  public x2992: number;
  public 1400: number;
  public x151400: number;
  public x21400: number;
}
