import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@shared-module';
import { AvailabilityComponent } from '@shared/Components/availability.component';
import { ChangeSkuQuantityDropdownComponent } from '@shared/Components/change-sku-quantity-dropdown.component';
import { ChangeSkuQuantityComponent } from '@shared/Components/change-sku-quantity.component';
import { ChangeSkuUomComponent } from '@shared/Components/change-sku-uom.component';
import { LineTotalComponent } from '@shared/Components/line-total.component';
import { LineUnitPriceComponent } from '@shared/Components/line-unit-price.component';
import { NoteComponent } from '@shared/Components/note.component';
import { SkuAvailabilityComponent } from '@shared/Components/sku-availability.component';
import { SkuDetailsComponent } from '@shared/Components/sku-details.component';
import { SkuPriceComponent } from '@shared/Components/sku-price.component';
import { SkuPricingScopeComponent } from '@shared/Components/sku-pricing-scope.component';
import { SubstituteComponent } from '@shared/Components/substitute.component';
import { SubstitutesModalComponent } from '@shared/Components/substitutes-modal.component';
import { SubstitutesComponent } from '@shared/Components/substitutes.component';
import { AddToCartService } from '@shared/Services/add-to-cart.service';
import { SubstituteService } from '@shared/Services/substitute.service';

@NgModule({
  declarations: [
    ChangeSkuQuantityComponent,
    ChangeSkuQuantityDropdownComponent,
    SkuPriceComponent,
    ChangeSkuUomComponent,
    SubstituteComponent,
    SubstitutesComponent,
    SubstitutesModalComponent,
    LineTotalComponent,
    LineUnitPriceComponent,
    NoteComponent
  ],
  imports: [
    SharedModule.forRoot(),
    SkuAvailabilityComponent,
    SkuPricingScopeComponent,
    SkuDetailsComponent,
    AvailabilityComponent
  ],
  exports: [
    ChangeSkuQuantityComponent,
    ChangeSkuQuantityDropdownComponent,
    SkuPriceComponent,
    ChangeSkuUomComponent,
    SkuAvailabilityComponent,
    SkuPricingScopeComponent,
    AvailabilityComponent,
    SubstituteComponent,
    SubstitutesComponent,
    SubstitutesModalComponent,
    LineTotalComponent,
    LineUnitPriceComponent,
    NoteComponent,
    SkuDetailsComponent
  ]
})
export class SkuModule {
  static forRoot(): ModuleWithProviders<SkuModule> {
    return {
      ngModule: SkuModule,
      providers: [
        SubstituteService,
        AddToCartService
      ]
    }
  }
}
