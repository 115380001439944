<div class="fsCloudinaryImage__wrap" 
    [ngClass]="{'fsCloudinaryImage__wrap--skeleton' : shouldShowSkeletonStyle, 'fsCloudinaryImage--square' : isSquare}">
    <ng-container *ngIf="!prerender">
        <div class="fsCloudinaryImage__prerender fs-print-only">
            <img [attr.srcset]="srcset" 
                [attr.sizes]="sizes"
                [src]="src" 
                [alt]="alt"
                loading="lazy"/>
        </div>

        <mat-spinner class="fs-no-print" *ngIf="shouldShowSpinner" aria-hidden="true"></mat-spinner>

        <lazy-load *ngIf="!bypassLazyLoad" class="fs-no-print" [eagerlyLoad]="eagerlyLoad" #parent>
            <ng-container *ngIf>
                <ng-container *ngTemplateOutlet="imgTag"></ng-container>
            </ng-container>
        </lazy-load>

        <ng-container *ngIf="bypassLazyLoad && (eagerlyLoad || imageLoaded)">
            <ng-container *ngTemplateOutlet="imgTag"></ng-container>
        </ng-container>
    </ng-container>

    <div class="fsCloudinaryImage__prerender" *ngIf="prerender">
        <ng-container *ngTemplateOutlet="imgTag"></ng-container>
    </div>
</div>

<ng-template #imgTag>
    <img [src]="src"
        [attr.sizes]="sizes"
        [attr.srcset]="srcset"
        [alt]="alt"
        (load)="setImageLoaded()"
        [hidden]="!prerender && !imageLoaded"
        [parentWidth]="parentWidth"
        cloudinaryResponsive />
</ng-template>