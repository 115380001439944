import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { FSResult } from '@shared/Models/FSResultModel';
import { Observable } from 'rxjs';
import { LogDTO, LogLevel, LogData } from '@shared/Models/LogDTO';

@Injectable()
export class LoggingService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string,
        @Inject(PLATFORM_ID) private platformId) {

        this._origin = originUrl;
    }

    public logHttpEvent(event: HttpEvent<any>): void {
        if (event instanceof HttpResponse) {
            var response = (event as HttpResponse<any>);
            
            if (response.body.errors?.length > 0) {
                var message = `Received ${response.status} response with additional errors in body (see attached data)`;
                this.logError(message, console.trace(), [response.url, response.body]);
            } else {
                if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
                    var message = `Received ${response.status} response`;
                    this.logError(message, console.trace(), [response.url, response.body]);
                } else {
                    var message = `Received ${response.status} response`;
                    this.logDeveloperInfo(message, [response.url, response.body]);
                }
            }
        }
    }

    public logDeveloperInfo(message: string, data?: any): void {
        if (isDevMode()) {
            console.log('%c' + message, 'color:blue;font-weight:bold;', data);
        }
    }

    public logInfo(message: string, callStack?: any, data?: any[]): void {
        console.log('%c' + message, 'color:green;font-weight:bold;', data);
        if (isPlatformBrowser(this.platformId)) {
            var log = this.formatLog(message, LogLevel.INFORMATION, data, callStack);
            this.sendLog(log).subscribe();
        }
    }

    public logWarning(message: string, callStack: any, data: any[]): void {
        console.warn('%c' + message, 'font-weight:bold;', data);
        if (isPlatformBrowser(this.platformId)) {
            var log = this.formatLog(message, LogLevel.WARNING, data, callStack);
            this.sendLog(log).subscribe();
        }
    }

    public logError(message: string, callStack: any, data: any[]): void {
        console.error('%c' + message, 'font-weight:bold;', data);
        if (isPlatformBrowser(this.platformId)) {
            var log = this.formatLog(message, LogLevel.ERROR, data, callStack);
            this.sendLog(log).subscribe();
        }
    }
    
    private formatLog(message: string, logLevel: number, data: any[], callStack?: any): LogDTO {
        var log = new LogDTO();

        log.level = logLevel;
        log.message = message;
        log.data = new LogData();
        log.data.callStack = callStack;
        log.data.additionalData = this.formatData(data);

        return log;
    }

    private formatData(data: any[]): string[] {
        if (data?.length > 0) {
            var list = new Array<string>();

            for (let entry of data) {
                list.push(entry.toString());
            }

            return list;
        }

        return null;
    }

    private sendLog(log: LogDTO): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(this._origin + '/api/logging/send-log', log);
    }
}
