import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Results } from '@shared/Models/CatalogModel';
import { FSResult } from '@shared/Models/FSResultModel';
import { AutoSuggestResults } from '@shared/Models/SearchBarModel';
import { Observable, tap } from 'rxjs';

@Injectable()
export class AutosuggestService {
    public originUrl: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl) {

        this.originUrl = originUrl;
    }

    public getAutoSuggestResults(terms: string): Observable<FSResult<AutoSuggestResults>> {
        var encodedTerms = encodeURIComponent(terms);
        return this.http.get<FSResult<AutoSuggestResults>>(`${this.originUrl}/api/autosuggest/results/${encodedTerms}`);
    }

    public getAutoSuggestHighlights(terms: string, count: number): Observable<FSResult<Results>> {
        var encodedTerms = encodeURIComponent(terms);
        return this.http.get<FSResult<Results>>(`${this.originUrl}/api/autosuggest/highlights/${encodedTerms}/${count}`)
          .pipe(tap((res: FSResult<Results>) => console.log('got results', res)));
    }
}
