import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Price, Sku, SkuPrice, UnitOfMeasure } from './SkuModel';
import { FSTransactionValues } from '@shared/Models/FSResultModel';

@Injectable()
export class PriceParameter {
    private _uom: UnitOfMeasure;
    private _quantity: number;

    public sku: Sku;
    public activePrice: Price;
    public skuPrice: SkuPrice;
    public priceRequestInProgress: boolean;

    public qtyChanged$ = new Subject<FSTransactionValues<number>>();
    public uomChanged$ = new Subject<FSTransactionValues<UnitOfMeasure>>();
    public availabilityReceived$ = new Subject<boolean>();

    public currentQuantityRequest: [number, number] = [null, null];

    public updateQuantity(transactionValues: FSTransactionValues<number>): void {
        this._quantity = transactionValues.requestedValue;
        this.qtyChanged$.next(transactionValues);
        this.updatePrice();
    }

    public updateUom(transactionValues: FSTransactionValues<UnitOfMeasure>): void {
        this.uom = transactionValues.requestedValue;
        this.uomChanged$.next(transactionValues);
        this.updatePrice();
    }

    public availabilityReceived(received: boolean): void {
        this.availabilityReceived$.next(received);
    }
    
    public get quantity(): number {
        return this._quantity;
    }
    
    public get uom(): UnitOfMeasure {
        return this._uom;
    }

    public set uom(value: UnitOfMeasure) {
        if (value) {
            const unitOfMeasureFromSku = this.sku.unitsOfMeasure.find((x) => x.unitSize === value.unitSize);
            this._uom = unitOfMeasureFromSku;
        }
    }

    public updatePrice(): void {
        if (this.uom) {
            const qty = this.quantity * this.uom.unitSize;
            if (this.skuPrice) {
                this.activePrice = this.skuPrice.getCustomerPriceForQuantity(qty);
            }
        }
    }
}
