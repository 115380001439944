import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { SearchHistoryResult, Suggestion } from '@shared/Models/SearchBarModel';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';

@Component({
    selector: 'search-bar-result-wrapper',
    templateUrl: './search-bar-result-wrapper.component.html'
})
export class SearchBarResultWrapperComponent {
    @Input() public searchBoxRef: ElementRef;

    @HostListener('document:click', ['$event'])
    public clickOutside(event): void {
        if (!this._element.nativeElement.contains(event.target)
            && !this.searchBoxRef.nativeElement.contains(event.target)
            && event.target.className !== 'fsSearchHistoryWrapper__clear') {
            this._searchBarService.resultsToggled = false;
            this._searchBarService.setResults(null);
        }
    }

    constructor(
        private _element: ElementRef,
        private _searchBarService: SearchBarService
    ) { }
	
    public get hasSearchHistoryResults(): boolean {		
        return this.searchHistoryResults?.length > 0;
    }

    public get hasAutosuggestResults(): boolean {
        return this.autosuggestResults?.length > 0;
    }

    public get searchHistoryResults(): SearchHistoryResult[] {
        return this._searchBarService.searchHistoryResults;
    }

    public get autosuggestResults(): Suggestion[] {
        return this._searchBarService.autosuggestResults;
    }

    public get hasValidCurrentTerms(): boolean {
        return this._searchBarService.hasValidCurrentTerms;
    }
}
