<input type="text"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        aria-label="Search by keyword or part number..."
        [placeholder]="placeholder"
        class="fsSearchBox"
        [value]="value"
        (input)="input([$event, fsSearchBox.value])"
        (keydown)="keydown($event)"
        (keyup)="keyup([$event, fsSearchBox.value])"
        (mouseover)="clearExplicitSelection()"
        #focusInput
        #fsSearchBox 
        inputFocusEvent
        preventDefaultTab
        inputEventId="header-search-box"/>

<input autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        type="text"
        class="fsSearchBox__ghost"
        *ngIf="shouldShowWatermark"
        [value]="watermark" />

<span class="fsSearchBox__clearIcon" (click)="clearSearchText()" *ngIf="shouldShowClearIcon()">
  <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
</span>

<button type="button" (click)="search('click')" aria-label="Search" [attr.disabled]="!hasTermsOrSelection ? true : null">
    <span class="fs-icon fs-search" aria-hidden="true"></span>
</button>

