import { Component, Input } from '@angular/core';
import { ErrorComponent } from './error.component';

@Component({
    selector: 'base-component',
    template: 'this is a base component'
})
export class InvasiveErrorComponent extends ErrorComponent {
    @Input() allowDismissal: boolean;
    @Input() displayRetry: boolean;
    @Input() hideReload: boolean;
    @Input() hideHelp: boolean;
    @Input() urgency: string;
}
