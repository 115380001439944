import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ErrorHandlerService } from '@shared/Services/error-handler.service';
import { SettingsAccessor } from '@shared/Services/settings.accessor';

declare var fisheriesModule: any;

@Component({
    selector: 'app-error-handler',
    templateUrl: './app-error-handler.component.html'
})

export class AppErrorHandlerComponent implements OnInit {

    @ViewChild('errorModalContainer', { read: ViewContainerRef, static: true }) errorModalContainer: ViewContainerRef;
    @ViewChild('errorToastContainer', { read: ViewContainerRef, static: true }) errorToastContainer: ViewContainerRef;
    @ViewChild('errorBarContainer', { read: ViewContainerRef, static: true }) errorBarContainer: ViewContainerRef;
    @ViewChild('errorHeaderContainer', { read: ViewContainerRef, static: true }) errorHeaderContainer: ViewContainerRef;

    constructor(
        private _errorHandlerService: ErrorHandlerService,
        private _changeDetector: ChangeDetectorRef,
        private _settingsAccessor: SettingsAccessor) { }

    ngOnInit() {
        this._errorHandlerService.registerErrorContainers(
            this.errorModalContainer,
            this.errorToastContainer,
            this.errorBarContainer,
            this.errorHeaderContainer
        );

        fisheriesModule.updateErrorsObservable.subscribe((res) => {
            if (res === true) {
                this._changeDetector.detectChanges();
            }
        });
    }

    public get errorBarPosition(): string {
        return this._settingsAccessor.errorBarPosition;
    }

    public get errorToastPosition(): string {
        return this._settingsAccessor.errorToastPosition;
    }

    public get hasToast(): boolean {
        return fisheriesModule.unresolvedErrors
            .filter((x) => 'dismissToastRequested' in x.componentRef.instance).length > 0;
    }

}
