import { maketype } from '@shared/Common/ClassTransformerHelpers';
import { FSResult } from '@shared/Models/FSResultModel';
import { Type } from 'class-transformer';
import { AddressStateModel } from './AddressStateModel';

export interface IAddress {
    addressee: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    id: string;
    addressType: number;
    phone: string;
}

export class AddressBookSettings {
    public allowCustomerAddress: boolean;
    public allowShopperAddress: boolean;
    public allowAdHocAddress: boolean;
}

export class AddressSettings {
    public isEditable: boolean;
    public isRemovable: boolean;
    public isDefault: boolean;
}

export class Address implements IAddress {
    public addressee: string;
    public line1: string;
    public line2: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;
    public id: string;
    public addressType: number;
    public phone: string;
    
    public get oneLineAddress(): string {
        if (this.line2) {
            return `${this.line1} ${this.line2}, ${this.oneLineCityStateZip}`;
        } else {
            return `${this.line1}, ${this.oneLineCityStateZip}`;
        }
    }

    public get oneLineCityStateZip(): string {
        return `${this.city}, ${this.state} ${this.zip}`;
    }
}

export class AddressWrapper {
    @Type(maketype(Address))
    public address: Address;

    @Type(maketype(AddressSettings))
    public settings: AddressSettings;
}

export class AddressBook {
    @Type(maketype(AddressWrapper))
    public addresses: AddressWrapper[];

    public addressStateModel: AddressStateModel;

    @Type(maketype(AddressBookSettings))
    public addressBookSettings: AddressBookSettings;

    public registerAddressBookWrapperValuesOnInit(addressStateModel: AddressStateModel) {
        this.addressStateModel = addressStateModel;
    }
}

export class ShopperAddress extends Address {
    public shopperId: string;
}

export class CustomerAddress extends Address {
    public customerId: number;
    public routeCode: string;
}

//Keep AddressType in sync with server
export enum AddressType {
    Unknown = 0,
    Shopper = 1,
    Customer = 2,
    AdHoc = 3,
    Invalid = -1
}

export class Country {
    public id: string;
    public name: string;
    public phoneValidationExpression: RegExp;
    public phoneMask: string;
    public zipLabel: string;
    public zipValidationExpression: RegExp;
    public stateLabel: string;
    public states: State[];
}

export class State {
    public id: string;
    public country: Country;
    public name: string;
}

//TODO remove FSAddressListResult once addressbook is stable
export class FSAddressListResult extends FSResult<AddressWrapper[]> {
    @Type(maketype(AddressWrapper))
    value: AddressWrapper[];
}

export class FSAddressResult extends FSResult<AddressWrapper> {
    @Type(maketype(AddressWrapper))
    value: AddressWrapper;
}

export class FSAddressBookResult extends FSResult<AddressBook> {
    @Type(maketype(AddressBook))
    value: AddressBook
}
