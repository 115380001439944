<a href="{{url | leadingslash}}" (throttledClick)="onClick($event)" [throttleMs]="1000" preventDoubleClick>
    <div class="fs-special-pricing" *ngIf="isOnSale">On Sale</div>    
    <cloudinary-image [url]="imageUrl"
                      [alt]="imageAltTag"
                      [type]="resultCard"
                      [prerender]="position < 7"></cloudinary-image>
    <h4 *ngIf="brand">
        <ng-container *ngIf="!isBrowser">{{brandName}}</ng-container>
        <a *ngIf="isBrowser" href="{{brandUrl | leadingslash}}" onclick="event.stopPropagation();">{{brandName}}</a>
    </h4>

    <h2>{{ name }}</h2>
    <p *ngIf="!shouldShowSingleSkuDetails && optionCount">Available in {{optionCount}} options</p>
    <p *ngIf="shouldShowSingleSkuDetails">SKU: {{sku}} | Item ID: {{itemId}}</p>

    <ng-content></ng-content>
    <availability [availability]="availability"></availability>
</a>