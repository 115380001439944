import { Component, Input } from '@angular/core';
import { CloudinaryImageType } from '@shared/Models/CloudinaryImageModel';
import { ClickableImage } from '@shared/Models/sharedModels';
import { calculateFSLSUrl } from '@shared/Services/fsls-calculator';

@Component({
    selector: 'clickable-image',
    templateUrl: './clickable-image.component.html'
})
export class ClickableImageComponent {
    @Input() data: ClickableImage;
    @Input() fsls : string;
    @Input() fslsid : string;
    @Input() fslsp : string[];

    public get linkUrl(): string {
        return calculateFSLSUrl(this.data.linkUrl,this.fsls,this.fslsid,this.fslsp);
    }

    public get imageUrl(): string {
        return this.data.imageUrl;
    }

    public get imageAltTag(): string {
        return this.data.imageAltTag;
    }

    public get title(): string {
        return this.data.title;
    }

    public get clickableImage(): CloudinaryImageType {
        return CloudinaryImageType.clickableImage;
    }
}
