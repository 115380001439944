import { Injectable } from "@angular/core";
import { Sku, UnitOfMeasure, SkuPrice, Price } from "./SkuModel";

@Injectable()
export class SkuHelper {
    public getSmallestUnit(sku: Sku): UnitOfMeasure {
        return sku.unitsOfMeasure.sort((a, b) => a.unitSize - b.unitSize)[0];
    }

    public orderUnitsOfMeasure(uoms: UnitOfMeasure[]): UnitOfMeasure[] {
        return uoms.sort((a, b) => {
            if (a.isDefaultUnit)
                return -1;

            return a.unitSize - b.unitSize;
        });
    }

    public getSmallestUnitSize(sku: Sku): number {
        return this.getSmallestUnit(sku).unitSize;
    }

    public getSmallestUnitName(sku: Sku): string {
        return this.getSmallestUnit(sku).displayName;
    }

    public getQuantityForSmallestUnit(sku: Sku, selectedQuantity: number, selectedUnitSize: number): number {
        let unit = this.getSmallestUnitSize(sku);
        return selectedQuantity * selectedUnitSize / unit;
    }

    public getPriceForUnit(sku: Sku, skuPrice: SkuPrice, selectedUnitSize: number): number {
        let unit = this.getSmallestUnit(sku);
        let pricing = this.getPricingArray(skuPrice);
        return pricing.find((price) => price.forQuantity == selectedUnitSize).baseUnitPrice;
    }

    public getPricingArray(skuPrice: SkuPrice): Price[] {
        return [...skuPrice.customerPrice.pricing.values()];
    }
}
