<form *ngIf="mailingListForm" [formGroup]="mailingListForm" (ngSubmit)="submit()">
    <div class="row">
        <div class="col-12 col-lg-8 pr-lg-0 pb-2 pb-lg-0">
            <input type="email" name="email" formControlName="email" placeholder="Email address">
        </div>
        <div class="col-12 col-lg-4 pl-lg-0">
            <button type="submit" class="fs-button-standard">
                Sign Up <mat-spinner *ngIf="submitInProgress" aria-label="Submit signup in progress"></mat-spinner>
            </button>
        </div>
        <div class="col-12 pt-2">
            <field-error-display [displayError]="isFieldInvalid('email')" errorMsg="Please enter a valid email" style="color: red">
            </field-error-display>
        </div>
    </div>
</form>