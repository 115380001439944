import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Output, PLATFORM_ID } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CloudinaryImageType } from '@shared/Models/CloudinaryImageModel';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { CloudinaryService } from '@shared/Services/cloudinary.service';
import { ResultComponent } from './result.component';
import { CommonModule } from '@angular/common';
import { AvailabilityComponent } from '../availability.component';
import { CloudinaryImageComponent } from '../cloudinary-image.component';
import { PreventDoubleClickDirective } from '@shared/Directives/sharedDirectives';
import { LeadingSlashPipe } from '@shared/Pipes/leading-slash.pipe';

@Component({
    selector: 'result-line',
    templateUrl: './result-line.component.html',
    standalone: true,
    imports: [
        CommonModule, 
        RouterModule,
        CloudinaryImageComponent,
        AvailabilityComponent,
        PreventDoubleClickDirective,
        LeadingSlashPipe
    ],
    host: {ngSkipHydration: 'false'},
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultLineComponent extends ResultComponent {
    @Output() sendResize = new EventEmitter<void>();

    @HostListener('window:resize')
    public onResize() {
        if (window.innerWidth < 768)
            this.sendResize.emit();
    }

    constructor(
        _cloudinaryService: CloudinaryService,
        _analyticsService: AnalyticsService,
        _router: Router,
        @Inject(PLATFORM_ID) _platformId
    ) {
        super(_cloudinaryService, _analyticsService, _router, _platformId);
    }

    public get resultLine(): CloudinaryImageType {
        return CloudinaryImageType.resultLine;
    }
}
