import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginDTO, LoginHistoryResult, ValidateEmailRequest } from '@shared/Modules/Identity/Models/LoginDTO';
import { LoginService } from '@shared/Modules/Identity/Services/login.service';
import { RedirectService } from '@shared/Services/redirect.service';

@Component({
    selector: 'email',
    templateUrl: './email.component.html'
})

export class EmailComponent implements OnInit {
    private _formSubmitAttempt: boolean;

    public emailForm: UntypedFormGroup;
    public loginRequested: boolean;

    @ViewChild('autoFocus', { read: ElementRef, static: false }) set autoFocus(content: ElementRef) {
        if (content) {
            setTimeout(() => { content.nativeElement.focus(); }, 0);
        }
    }

    constructor(
        private _loginDto: LoginDTO,
        private _builder: UntypedFormBuilder,
        private _loginService: LoginService,
        private _redirect: RedirectService) {
    }

    ngOnInit() {
        this.emailForm = this._builder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    public get loginHistoryResults(): LoginHistoryResult[] {
        return this._loginService.loginHistoryResults;
    }

    public isFieldInvalid(field: string): boolean {
        return (
            (!this.emailForm.get(field).valid && this.emailForm.get(field).touched) ||
            (!this.emailForm.get(field).valid && this._formSubmitAttempt)
        );
    }

    public displayFieldCss(field: string) {
        return {
            'has-error': this.isFieldInvalid(field),
            'has-feedback': this.isFieldInvalid(field)
        };
    }

    public submitLoginRequest(): void {
        this._formSubmitAttempt = true;
        if (this.emailForm.valid) {
            this.loginRequested = true;

            var request = new ValidateEmailRequest();
            request.email = this.emailForm.value.email;

            this._loginService.checkEmail(request).subscribe((res) => {
                if (res.success) {
                    this.checkValidation(res.value);
                }

                this.loginRequested = false;
            });
        }
    }

    private checkValidation(response: any): void {
        this._loginDto.email = this.emailForm.value.email;
        if (response.firstName && response.firstName !== '') {
            this._loginDto.firstName = response.firstName;
            this._redirect.goToLoginPassword();
        } else {
            this._redirect.goToLoginRegistration();
        }
    }

}
