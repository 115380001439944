import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Availability } from '@shared/Models/SkuModel';
import { FSResult } from '@shared/Models/FSResultModel';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public getAvailability(commaSeparatedInvMastUids: string): Observable<FSResult<Availability[]>> {
        return this.http.get<FSResult<Availability[]>>(this._origin + `/api/availability/` + commaSeparatedInvMastUids);
    }
}
