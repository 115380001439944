import { Injectable } from "@angular/core";
@Injectable()
export class P21PriceRounder {
    public roundPrice(basePrice: number, unitSize: number, quantity: number): number {
        let roundedPrice = 0;
        roundedPrice = basePrice * unitSize;
        roundedPrice = this.round(roundedPrice, 2);
        const newPrice: number = roundedPrice * quantity;
        return this.round(newPrice, 2);
    }

    public round(number: number, precision: number): number {
        return this.shift(Math.round(this.shift(number, precision, false)), precision, true);
    }

    public shift(number: number, precision: number, reverseShift: boolean): number {
        if (reverseShift) {
            precision = -precision;
        }
        const numArray: string[] = ('' + number).split('e');
        return +(numArray[0] + 'e' + (numArray[1] ? (+numArray[1] + precision) : precision));
    }
}
