<h2 class="fs-h-border">Register</h2>

<div class="fsRegistrationComponent">
    <form *ngIf="!p21ReadOnly" [formGroup]="registrationForm" (ngSubmit)="onSubmit()" id="fsRegistrationComponent__form">
        <div class="row">
            <div class="input-wrap col-lg-6">
                <label for="firstName">First Name</label>
                <input type="text" name="firstName" id="firstName" formControlName="firstName" #autoFocus maxlength="15">
                <field-error-display [displayError]="isFieldInvalid('firstName')" errorMsg="First Name is Required" [className]="displayFieldCss('firstName')">
                </field-error-display>
            </div>
            <div class="input-wrap col-lg-6">
                <label for="lastName">Last Name</label>
                <input type="text" name="lastName" id="lastName" formControlName="lastName" maxlength="24">
                <field-error-display [displayError]="isFieldInvalid('lastName')" errorMsg="Last Name is Required" [className]="displayFieldCss('lastName')">
                </field-error-display>
            </div>
        </div>
        <div class="row">
            <div class="input-wrap col-lg-6">
                <label for="confirmEmail">Confirm Email</label>
                <input type="email" name="confirmEmail" id="confirmEmail" formControlName="confirmEmail" maxlength="80">
                <field-error-display [displayError]="isFieldInvalid('confirmEmail')" errorMsg="Email needs to match the one already entered" [className]="displayFieldCss('confirmEmail')">
                </field-error-display>
            </div>
            <div class="input-wrap col-lg-6">
                <label for="phone">Phone</label>
                <input type="tel" name="phone" id="phone" formControlName="phone" maxlength="20">
                <field-error-display [displayError]="isFieldInvalid('phone')" errorMsg="Please enter a valid Phone Number" [className]="displayFieldCss('phone')">
                </field-error-display>
            </div>
        </div>
        <div class="row">
            <div class="input-wrap col-lg-6">
                <div class="fs-show-password">
                    <input type="checkbox" name="showPassword" id="showPassword" formControlName="showPassword">
                    <label *ngIf="passwordType == 'password'" for="showPassword">Show Password</label>
                    <label *ngIf="passwordType == 'text'" for="showPassword">Hide Password</label>
                </div>
                <label for="password">Choose Password</label>
                <input type="{{passwordType}}" name="password" id="password" formControlName="password" (input)="validatePassword()" >
            </div>
            <div class="input-wrap col-lg-6">
                <label for="confirmPassword">Confirm Password</label>
                <input type="{{passwordType}}" name="confirmPassword" id="confirmPassword" formControlName="confirmPassword" (input)="setConfirmPassword()" >
            </div>
        </div>
        
        <password-hints *ngIf="passwordInputTouched" 
            [shouldShowPasswordMatchMessage]="confirmPasswordInputTouched && isFieldInvalid('confirmPassword')"
            [passwordInputTouched]="passwordInputTouched"
            [passwordHints]="passwordHints"></password-hints>

        <b class="d-block mb-3 mb-md-0">Do you have a Fisheries Account?</b>
        <div class="row">
            <div class="col-lg-12 order-1">
                <div>
                    <div class="fs-radio">
                        <input type="radio" id="accountYes" formControlName="account" value="yes">
                        <label for="accountYes"><span class="fs-radio__circle"></span>Yes</label>
                        <div class="fs-note mb-3" *ngIf="hasFisheriesAccount">
                            <p>If you already have an account with us, please call us at 206-632-4462 or email us at <a href="mailto:support@fisheriessupply.com">support@fisheriessupply.com</a> to complete your registration.</p>
                        </div>
                        <input type="radio" id="accountNo" formControlName="account" value="no" [checked]="true">
                        <label for="accountNo"><span class="fs-radio__circle"></span>No</label>
                    </div>
                </div>

            </div>
            <!-- <div class="col-md-6 order-3 order-lg-4">
                <div *ngIf="shouldShowAccountNumberField" class="mb-4">
                    <label for="accountNumber">Enter Account Number</label>
                    <input type="text" name="accountNumber" id="accountNumber" class="input-small" formControlName="accountNumber">
                    <field-error-display [displayError]="isFieldInvalid('accountNumber')" errorMsg="This 6 digit number is required" class="error">
                    </field-error-display>
                </div>
            </div> -->
        </div>

        <div class="fs-checkbox--flex mt-2 mb-4">
            <input type="checkbox" name="emailOptIn" id="emailOptInCheckbox" formControlName="emailOptIn">
            <label for="emailOptInCheckbox" class="my-0"><span></span><span class="fs-checkbox__text">Join our email list for product specials, promotions, email only coupons, events & pro specific news</span></label>
        </div>

        <div class="row mt-2">
            <div class="col-md-6">
                <button type="submit"
                        class="fs-button-standard fs-button-spaced fs-button-100-mobile"
                        [ngClass]="{'fs-disabled' : hasFisheriesAccount}"
                        [disabled]="registrationRequested || hasFisheriesAccount">
                Register Your Account <mat-spinner *ngIf="registrationRequested" aria-label="Requesting registration"></mat-spinner><span class="fs-icon fs-chevron-right" *ngIf="!registrationRequested" aria-hidden="true"></span>
                </button>
            </div>
            <div class="col-md-6">
                <a (click)="goToEmail()" class="fs-bottom-right">Login with a Different Email</a>
            </div>
        </div>

    </form>

    <small *ngIf="p21ReadOnly" class="fs-grey"><i>User registration is currently offline for maintenance. Please check back later.</i></small>
</div>
