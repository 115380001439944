import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FSResult } from '@shared/Models/FSResultModel';
import { SubscriptionRequestDTO } from '@shared/Models/SubscriptionRequestDTO';
import { Observable } from 'rxjs';

@Injectable()
export class MailingListService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public subscribeEmailToMailingList(dto: SubscriptionRequestDTO): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(`${this._origin}/api/mailing-list/subscribe`, JSON.stringify(dto));
    }
}
