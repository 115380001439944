import { Component, OnInit } from '@angular/core';
import { RedirectService } from '@shared/Services/redirect.service';
declare var $: any;

@Component({
    selector: 'shared-login-validation-sent',
    templateUrl: './shared-login-validation-sent.component.html'
})

export class SharedLoginValidationSentComponent implements OnInit {
    public continueRequested: boolean;

    constructor(private _redirect: RedirectService) {}

    ngOnInit() {
        $('#fsLoginModal').click(function(): void {
            this.refreshPage();
        });
    }

    public refreshPage(): void {
        this.continueRequested = true;
        this._redirect.refreshPage();
    }

}
