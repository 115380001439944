<ng-container *ngIf="hasActivePrice">
    <!--full display-->
    <div class="fsSkuPrice__price row" *ngIf="isPurchasable && displayFull">
        <div [ngClass]="{'col-7' : shouldShowWasPrice, 'col-12' : !shouldShowWasPrice}">
            <span class="fsSkuPrice__type">{{unitPriceLabel}}</span>
            <span class="fsSkuPrice__amt">{{unitPrice | currency}}</span> <span class="fsSkuPrice__per">/ {{activeUom}}</span>
        </div>
        <div class="col-5" *ngIf="shouldShowWasPrice">
            <span class="fsSkuPrice__was-amt">Was: <span>{{wasPrice | currency}}</span></span>
            <span class="fsSkuPrice__saved-amt">You Save: <span>{{amountSaved | currency}}</span></span>
        </div>
        <div class="col-12" *ngIf="shouldShowReferencePrice">
            <span class="fsSkuPrice__list-amt">
                (
                <ng-container *ngIf="hasListPrice">List: {{listPrice | currency}}</ng-container>
                <ng-container *ngIf="hasListPrice && hasStreetPrice">, </ng-container>
                <ng-container *ngIf="hasStreetPrice">Street: {{streetPrice | currency}}</ng-container>
                )
            </span>
        </div>
    </div>

    <!--price only-->
    <div *ngIf="isPurchasable && displaySimplePrice">{{unitPrice | currency}}</div>

    <!--simple price calculated for quantity-->
    <div *ngIf="isPurchasable && displayPriceForQty" class="fsSkuPrice__price-for-qty">{{priceForQuantity | currency}}</div>

    <!--sale only-->
    <div class="fsSkuPrice__price" *ngIf="isPurchasable && displaySale && shouldShowWasPrice">
        <span class="fsSkuPrice__was-amt">Was: <span>{{wasPrice | currency}}</span></span>
        <span class="fsSkuPrice__saved-amt">You Save: <span>{{amountSaved | currency}}</span></span>
    </div>
</ng-container>

<mat-spinner *ngIf="!hasActivePrice && !displaySale" aria-hidden="true"></mat-spinner>
