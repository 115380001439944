import { AutosuggestResultComponent } from '@Search-Bar/Components/autosuggest-result.component';
import { ElementRef, Injectable } from '@angular/core';
import { IResult } from '@shared/Models/ResultModel';
import { AutoSuggestResults, SearchBarNavigationAction, SearchBarResult, SearchHistoryResult, Suggestion } from '@shared/Models/SearchBarModel';
import { FSObjectStore } from '@shared/Models/WebWorkerModel';
import { Link } from '@shared/Models/sharedModels';
import { WebWorkerService } from '@shared/Services/web-worker.service';
declare var $: any;

@Injectable()
export class SearchBarService {
    public currentTerms: string;
    public implicitSelection: SearchBarResult;
    public explicitSelection: SearchBarResult;
    public searchHistoryResultsIndex: number;
    public autoSuggestResultsIndex: number;
    public searchHistoryResults: SearchHistoryResult[];
    public autosuggestResults: Suggestion[];
    public suggestionResultId: string;
    public termHighlights: IResult[];
    public searchActionInProgress: boolean;
    public resultsToggled: boolean;
    public storedResults: Map<string, AutoSuggestResults>;
    public storedHighlights: Map<string, IResult[]>;
    public keyCount: number;
    public resultElementRefs: ElementRef[];
    public resultComponentRefs: AutosuggestResultComponent[];

    constructor(private _worker: WebWorkerService) {
        this.keyCount = 0;
    }

    public get highlightSelection(): SearchBarResult {
        if (this.explicitSelection) {
            if (this.explicitSelection['score']) { // temporary, should check for highlight
                return this.explicitSelection;
            } else {
                return this.implicitSelection;
            }
        } else {
            return this.implicitSelection;
        }
    }

    public get hasValidCurrentTerms(): boolean {
        return this.isTermValid(this.currentTerms);
    }
    
    public isTermValid(term: string): boolean {
        var alphanumericOnly = term?.replace(/[^a-z0-9]/gi, '');
        return alphanumericOnly?.length > 0;
    }

    public addSearchHistoryResult(text: string, linkUrl: string): SearchHistoryResult[] {
        let data = new SearchHistoryResult();
        data.link = new Link();
        data.link.text = text;
        data.link.linkUrl = linkUrl;

        let results = new Array<SearchHistoryResult>();

        if (this.searchHistoryResults) {
            results.push(...this.searchHistoryResults);
        }

        if (results.length === 0 ||
            results.filter((x) => x.value.toLowerCase() === data.value.toLowerCase()).length === 0) {

            results.push(data);
        }

        return results;
    }

    public navigate(action: SearchBarNavigationAction, hoverResult?: SearchBarResult, index?: number): void {
        switch (action) {
            case SearchBarNavigationAction.Hover:
                this.hover(hoverResult, index);
                break;
            case SearchBarNavigationAction.Up:
                this.navUp();
                break;
            case SearchBarNavigationAction.Down:
                this.navDown();
                break;
            default:
                break;
        }
    }

    public openSearch(term: string): void {
        this.searchActionInProgress = true;

        let urlToGoTo;
        let updatedResults;
        let selectedTerm = this.explicitSelection?.value || this.isTermValid(term) ? term : null;

        if (selectedTerm) {
            selectedTerm = selectedTerm.trim();
            var encodedSelectedTerm = encodeURIComponent(selectedTerm);
    
            urlToGoTo = `browse?terms=${encodedSelectedTerm.toLowerCase()}`;
    
            if (this.currentTerms != term) {
                urlToGoTo = `${urlToGoTo}&rterms=${this.currentTerms}`;
            }
    
            updatedResults = this.addSearchHistoryResult(selectedTerm, urlToGoTo);
            this._worker.postMessage('update', FSObjectStore.FSSearchHistory, updatedResults);
    
            if (urlToGoTo) {
                location.href = urlToGoTo;
            }
        } else {
            this.searchActionInProgress = false;
        }
    }

    public searchOrBrowse(term: string, action?: string): void {
        this.searchActionInProgress = true;

        let urlToGoTo;
        let updatedResults;
        let selectedBrowseResult = this.explicitSelection;

        if (selectedBrowseResult) {
            updatedResults = this.addSearchHistoryResult(selectedBrowseResult.value, urlToGoTo);
        } else if (term) {
            urlToGoTo = `browse?terms=${term.toLowerCase()}`;
            updatedResults = this.addSearchHistoryResult(term, urlToGoTo);
        }

        this._worker.postMessage('update', FSObjectStore.FSSearchHistory, updatedResults);

        if (urlToGoTo) {
            location.href = urlToGoTo;
        }
    }

    public storeHighlights(term: string, results: IResult[]): void {
        if (!this.storedHighlights)
            this.storedHighlights = new Map<string, IResult[]>();

        if (this.termHighlights?.length > 0)
            this.storedHighlights.set(term, results);
    }

    public setResults(res: AutoSuggestResults): void {
        this.explicitSelection = null;
        this.searchHistoryResultsIndex = null;
        this.autoSuggestResultsIndex = null;

        if (res) {
            const typedResults = new Array<Suggestion>();

            for (const item of res.suggestions)
                typedResults.push(item);
    
            this.termHighlights = res.highlights;
            this.autosuggestResults = typedResults;
            this.implicitSelection = typedResults[0];
    
            this.suggestionResultId = res.resultId;
    
            this.storeResults(res);
            this.storeHighlights(this.currentTerms, this.termHighlights);
            this._worker.updateSearchHistory(this.searchHistoryResults);
        }
    }

    private storeResults(results: AutoSuggestResults): void {
        if (!this.storedResults)
            this.storedResults = new Map<string, AutoSuggestResults>();

        this.storedResults.set(this.currentTerms, results);
    }

    private hover(hoverResult: SearchBarResult, index: number): void {
        this.explicitSelection = hoverResult;

        if (this.currentTerms && this.autosuggestResults) {
            this.autoSuggestResultsIndex = index;
        } else if (!this.currentTerms && this.searchHistoryResults) {
            this.searchHistoryResultsIndex = index;
        }
    }

    private navUp(): void {
        if (this.currentTerms && this.autosuggestResults) {
            this.autoSuggestResultsIndex ? this.autoSuggestResultsIndex-- : this.autoSuggestResultsIndex = null;
            this.assignSelectionNavUp(this.autosuggestResults, this.autoSuggestResultsIndex);
            this.setExplicitSelectionHoverState(this.autoSuggestResultsIndex);
        } else if (!this.currentTerms && this.searchHistoryResults) {
            this.searchHistoryResultsIndex ? this.searchHistoryResultsIndex-- : this.searchHistoryResultsIndex = null;
            this.assignSelectionNavUp(this.searchHistoryResults, this.searchHistoryResultsIndex);
        }
    }

    private assignSelectionNavUp(resultSet: SearchBarResult[], index: number): void {
        this.explicitSelection = resultSet[index];

        if (this.explicitSelection && this.explicitSelection['score']) {
            this.implicitSelection = this.explicitSelection;
        }
    }

    private navDown(): void {
        if (this.currentTerms && this.autosuggestResults && this.autoSuggestResultsIndex < this.autosuggestResults.length - 1) {
            this.autoSuggestResultsIndex != null ? this.autoSuggestResultsIndex++ : this.autoSuggestResultsIndex = 0;
            this.assignSelectionNavDown(this.autosuggestResults, this.autoSuggestResultsIndex);
            this.setExplicitSelectionHoverState(this.autoSuggestResultsIndex);
        } else if (!this.currentTerms && this.searchHistoryResults && !this.searchHistoryResultsIndex || this.searchHistoryResultsIndex < this.searchHistoryResults.length - 1) {
            this.searchHistoryResultsIndex != null ? this.searchHistoryResultsIndex++ : this.searchHistoryResultsIndex = 0;
            this.assignSelectionNavDown(this.searchHistoryResults, this.searchHistoryResultsIndex);
        }
    }

    private assignSelectionNavDown(resultSet: SearchBarResult[], index: number): void {
        if (this.explicitSelection == null) {
            this.explicitSelection = resultSet[0];
        } else {
            if (resultSet.length > index) {
                this.explicitSelection = resultSet[index];
            }
        }

        if (this.explicitSelection && this.explicitSelection['score']) {
            this.implicitSelection = this.explicitSelection;
        }
    }

    private setExplicitSelectionHoverState(index: number): void {
        $('#fsAutosuggestResults').find('.fsAutosuggestResults__highlight-wrapper').hide();

        if (this.resultElementRefs && this.resultElementRefs[index]) {
            $('#' + this.resultElementRefs[index]['element'].nativeElement.children[1].id).show();
            this.resultComponentRefs[index].checkHighlightsAsync();
        } else {
            $('.fsAutosuggestResults__result-wrapper--default').show().find('.fsAutosuggestResults__highlight-wrapper').show();
        }
    }
}
