import { DatePipe } from '@angular/common';
import { Type } from 'class-transformer';
import { Subject } from 'rxjs';
import { Address } from './AddressModel';
import { IPriceable } from './IPriceable';
import { NotificationSettings } from './NotificationModel';
import { GiftCard, PaymentMethod, Card } from './PaymentModel';
import { PriceParameter } from './PriceParameter';
import { Sku, SkuPrice, UnitOfMeasure } from './SkuModel';
import { TaxJurisdiction } from './SalesTaxModel';
import { FSResult, FSTransactionValues } from '@shared/Models/FSResultModel';
import { ShippingOptions } from './ShippingModel';
import { maketype } from '@shared/Common/ClassTransformerHelpers';
import { GuestDetails } from '@shared/Modules/Identity/Models/RegistrationDTO';

export class LineItem implements IPriceable {
    // these properties should not be serialized by JSON.stringify()
    private static excludedJsonProperties: Set<string> = new Set<string>(['priceParameter']);

    public priceParameter: PriceParameter;
    public qtyChanged$ = new Subject<FSTransactionValues<number>>();
    public uomChanged$ = new Subject<FSTransactionValues<UnitOfMeasure>>();
    public availabilityReceived$ = new Subject<boolean>();

    @Type(maketype(Sku))
    public sku: Sku;
    @Type(maketype(UnitOfMeasure))
    public selectedUnitOfMeasure: UnitOfMeasure;
    @Type(maketype(SkuPrice))
    public skuPrice: SkuPrice;
    public batchVersion: string;
    public id: string;
    public cartId: string;
    public quantity: number;
    public timeStamp: string;
    public note: string;
    public taxable: boolean;
    public productImageUrl: string;
    public specialHandling: SpecialHandling;

    public get isValid(): boolean {
        return this.sku != null && this.skuPrice != null;
    }

    public qtyUpdated(transactionValues: FSTransactionValues<number>): void {
        this.quantity = transactionValues.requestedValue;
        this.qtyChanged$.next(transactionValues);
    }

    public uomUpdated(transactionValues: FSTransactionValues<UnitOfMeasure>): void {
        this.selectedUnitOfMeasure = transactionValues.requestedValue;
        this.uomChanged$.next(transactionValues);
    }

    public availabilityReceived(received: boolean): void {
        this.availabilityReceived$.next(received);
    }

    public registerPriceParameterValuesOnInit(priceParameter: PriceParameter): void {
        this.priceParameter = priceParameter;

        this.priceParameter.qtyChanged$.subscribe((transactionValues) => this.qtyUpdated(transactionValues));
        this.priceParameter.uomChanged$.subscribe((transactionValues) => this.uomUpdated(transactionValues));
        this.priceParameter.availabilityReceived$.subscribe((received) => this.availabilityReceived(received));
    }

    // make a clean self json object without unwanted properties.
    public toJSON(): any {
        const obj: any = {};

        for (const pf in this) {

            if (!pf.startsWith('_') && !LineItem.excludedJsonProperties.has(pf)) {
                const prop: any = this[pf];
                if (prop && prop.toJSON) {
                    obj[pf] = prop.toJSON();
                } else {
                    obj[pf] = this[pf];
                }
            }
        }
        return obj;
    }
}

export class LineChanges {
  @Type(maketype(LineItem))
  public newLine: LineItem;
  @Type(maketype(LineItem))
  public oldLine: LineItem;
  public id: string;
  public lineAdded: boolean;
  public lineRemoved: boolean;
  public noteChanged: boolean;
  public quantityChanged: boolean;
  public taxChanged: boolean;
  public uomChanged: boolean;
}

export class SpecialHandling {
    public specialHandlingRequested: boolean;
    public deliverByDate: Date;
    public pickUpEnabled: boolean;
    public shipLocation: Address;

    public get deliveryDateFormatted(): string {
        return new DatePipe('en-US').transform(this.deliverByDate, 'MM/dd/yyyy');
    }
}

export class PromoCode {
    public code: string;
    public type: string;
    public discountAmount: number;
    public rules: string[];
    public messages: string[];
    public appliedDiscountTotal: number;

    public get isValid(): boolean {
        return !this.rules;
    }
}



export class CartDTO {

    public cartVersion: string;
    public id: string;

    @Type(maketype(LineItem))
    public cartLines: LineItem[];
    public appliedGiftCards: GiftCard[];

    public get giftCardTotal(): number {
        if (this.appliedGiftCards) {
            let base = 0;
            for (const card of this.appliedGiftCards) {
                base = base + (card.availableBalance);
            }
            return base;
        }
    }


    @Type(maketype(Address))
    public shippingAddress: Address;
    public packingBasis: number;
    public shippingOptions: ShippingOptions;
    //TODO REMOVE public shippingHash: string;
    public shippingNote: string;
    public notification: NotificationSettings;
    public willCall: boolean;
    public guestDetails: GuestDetails;
    public taxableSubtotal: number;
    public nonTaxableSubtotal: number;
    public total: number;
    //TODO REMOVE public shopperId: string;
    public tax: number;
    public taxJurisdiction: TaxJurisdiction;
    public promoCode: PromoCode;
    public poNumber: string;

    // tbd
    public otherPayment: PaymentMethod;

    @Type(maketype(Card))
    public creditCardPayment: Card;

    public isLatest: boolean;
}

export class CartResultPayloadDTO {
    @Type(maketype(CartDTO))
    public cart: CartDTO;

    @Type(maketype(LineChanges))
    public lineChanges: LineChanges[];

    public action: string;
    public clientId: string;
    public otherUser: boolean;
    public userName: string;
}

export class FSCartDTOResult extends FSResult<CartResultPayloadDTO> {
    @Type(maketype(CartResultPayloadDTO))
    value: CartResultPayloadDTO;
}
