import { FSError } from '@shared/Models/FSResultModel';
import { HttpErrorResponse } from '@angular/common/http';

export enum ErrorUrgency {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
    SUCCESS = 'SUCCESS'
}

export class UnresolvedError {
    // these properties should not be serialized by JSON.stringify()
    private static excludedJsonProperties: Set<string> = new Set<string>(['componentRef']);

    componentRef: any;
    title: string;
    message: string;
    httpErrorResponse: HttpErrorResponse;
    fsError: FSError;

    public toJSON(): any {
        const obj: any = {};

        for (const pf in this) {

            if (!pf.startsWith('_') && !UnresolvedError.excludedJsonProperties.has(pf)) {
                const prop: any = this[pf];
                if (prop && prop.toJSON) {
                    obj[pf] = prop.toJSON();
                } else {
                    obj[pf] = this[pf];
                }
            }
        }
        return obj;
    }
}
