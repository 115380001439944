import { Expose, Type } from "class-transformer";
import { AnalyzableEvent } from "./AnalyticsModel";
import { maketype } from "@shared/Common/ClassTransformerHelpers";

export class AddCartLineRequestDTO extends AnalyzableEvent{
    public items: AddToCartDTO[];
}

export class UpdateCartLineQuantityDTO extends AnalyzableEvent{
    constructor(qty : number){
        super();
        this.qty = qty;
    }
    public qty : number
}

export class AddToCartAvailabilityDTO {
    @Expose() public quantityAvailable: number;
    @Expose() public stockable: boolean;
    @Expose() public sellable: boolean;
    @Expose() public discontinuedByVendor: boolean;
    @Expose() public discontinuedByFisheries: boolean;
    @Expose() public inStockMessage: string;
    @Expose() public partialStockMessage: string;
    @Expose() public partialStockLeadTime: string;
    @Expose() public inStockLeadTime: string;
    @Expose() public inStockDisposition: string;
    @Expose() public outOfStockMessage: string;
    @Expose() public outOfStockLeadTime: string;
    @Expose() public outOfStockDisposition: string;
    @Expose() public expectedDate: Date;
    @Expose() public canExpedite: boolean;
    @Expose() public expediteDispositions: string[];
    @Expose() public stockUrgencyThreshold: number;
    @Expose() public stockUrgencyMessage: string;
    @Expose() public isPurchasable: boolean;
    @Expose() public isInStock: boolean;
    @Expose() public stockLevelUrgent: boolean;
    @Expose() public hasLimitedQuantityAvailable: boolean;
    @Expose() public isDiscontinuedByVendor: boolean;
}

export class AddToCartUnitOfMeasureDTO{
    @Expose() public isDefaultUnit: boolean;
    @Expose() public displayName: string;
    @Expose() public unitName: string;
    @Expose() public unitSize: number;
}

export class AddToCartLink {
    @Expose() public text : string;
    @Expose() public linkUrl: string;
}

export class AddToCartAttributeValue {
    @Expose() public value: string;
    @Expose() public attributeId: number;
    @Expose() public sortOrder: number;
}

export class AddToCartSkuDTO {
    @Expose() public id: number;
    @Expose() public itemId: string;
    @Expose() public invMastUid: number;
    @Expose() public name: string;
    @Expose() public shortCode: string;
    @Expose() public supplierPartNumber: string;
    @Expose() public productId: string;
    @Expose() public productName: string;
    @Expose() public quantity: number;
    @Expose() public imageUrl: string;
    @Expose() public imageAltTag: string;
    @Expose() public url: string;
    @Expose() public fullUrl: string;
    @Expose() public brandName: string;
    @Expose() public brand: AddToCartLink; 
    @Expose() public categories: AddToCartLink[];

    @Type(maketype(AddToCartUnitOfMeasureDTO))
    @Expose()
    public unitsOfMeasure: AddToCartUnitOfMeasureDTO[];

    @Type(maketype(AddToCartAvailabilityDTO))
    @Expose()
    public availability: AddToCartAvailabilityDTO;

    @Type(maketype(AddToCartAttributeValue))
    @Expose() 
    public attributeValues: AddToCartAttributeValue[];

    @Expose() public discontinued: boolean;
}

//Formerly CartLineDto
export class AddToCartDTO {
    public id: string;
    public cartId: string;
    public notes: string[];
    public taxable: boolean;
    public sku: AddToCartSkuDTO;
    public qty: number;
    public unitOfMeasure: AddToCartUnitOfMeasureDTO;

    constructor() {
        this.sku = null;
        this.qty = 0;
        this.unitOfMeasure = null;
        this.id = null;
        this.cartId = null;
        this.notes = null;
        this.taxable = true;
    }
}