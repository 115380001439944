import { Component, Input } from '@angular/core';
import { LineItem } from '@shared/Models/CartModel';

@Component({
    selector: 'line-total',
    templateUrl: './line-total.component.html'
})

export class LineTotalComponent {
    @Input() lineItem: LineItem;
    @Input() displaySimpleTotal = false;

    public get qty(): number {
        return this.lineItem.quantity;
    }

}
