import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ResultAvailability } from '@shared/Models/ResultModel';

@Component({
    selector: 'availability',
    templateUrl: './availability.component.html',
    imports: [CommonModule],
    standalone: true
})

export class AvailabilityComponent {
    //temporary
    @Input() availability: ResultAvailability;

    public get stockMessage(): string {
        return this.availability.stockMessage;
    }

    public get stockMessageColor(): string {
        if (this.availability.stockMessageColor) {
            return this.availability.stockMessageColor.toLowerCase();
        } else {
            return this.guessBestColor();
        }
    }

    public get shippingMessage(): string {
        return this.availability.shippingMessage;
    }

    public get shippingMessageNotEmptyString(): boolean {
        return this.shippingMessage && this.shippingMessage.length > 0;
    }

    public guessBestColor(): string {
        if (this.availability.inStock) {
            if (this.availability.quantityAvailable < 5) {
                return 'yellow';
            } else {
                return 'green';
            }
        } else {
            return 'red';
        }
    }
}
