import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartDTO } from '@shared/Models/CartModel';
import { NotificationSettings } from '@shared/Models/NotificationModel';
import { LoggingService } from './logging.service';

@Injectable()
export class CartAccessor {
    public constructor(private _loggingService: LoggingService) {
        if (typeof window !== 'undefined') {
            const fsm: any = (<any>window).fisheriesModule;

            if (fsm && !fsm.currentCart && fsm.cartData) {
                const cart = plainToClass(CartDTO, <object> fsm.cartData);
                Object.defineProperty(fsm, 'currentCart', {
                    get: () => fsm.cartBehaviorSubject.value
                });

                fsm.cartBehaviorSubject = new BehaviorSubject<CartDTO>(cart);
                fsm.cartObservable = fsm.cartBehaviorSubject.asObservable();
            }
        }
    }

    public get fisheriesModule(): any {
        if (typeof window !== 'undefined') {
            return (<any>window).fisheriesModule;
        } else {
            return {};
        }
    }

    public get cartObservable(): Observable<CartDTO> {
        if (this.fisheriesModule) {
            return this.fisheriesModule.cartObservable;
        }
    }

    public get currentCart(): CartDTO {
        if (this.fisheriesModule) {
            return this.fisheriesModule.currentCart;
        }
    }

    public updateCart(value: CartDTO): void {
        if (value) {
            if (value.id) {
                if (!value.notification) {
                    value.notification = new NotificationSettings();
                }

                var cart = value;

                if (!(cart instanceof CartDTO)) {
                    cart = plainToClass(CartDTO, value);
                }

                this.fisheriesModule.cartBehaviorSubject.next(cart);

                this._loggingService.logDeveloperInfo('%ccart accessor up-to-date', value);
            }
        }
    }
}
