import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'leadingslash',
    standalone: true
})

export class LeadingSlashPipe implements PipeTransform {
    public transform(text: string): string {
        text = text ? text : "";

        if (text.startsWith("/")) {
            return text;
        }

        return "/" + text;
    }
}
