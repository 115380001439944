import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { PriceParameter } from '@shared/Models/PriceParameter';
import { Sku, Availability, StockLevel } from '@shared/Models/SkuModel';
import { AvailabilityService } from '@shared/Services/availability.service';
import { plainToClass } from 'class-transformer';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'sku-availability',
    templateUrl: './sku-availability.component.html',
    imports: [CommonModule, MatProgressSpinnerModule],
    standalone: true
})

export class SkuAvailabilityComponent implements OnInit {
    @Input() showDetailedNote = false;
    @Input() compact: boolean;

    constructor(
        private _priceParameter: PriceParameter,
        private _availabilityService: AvailabilityService,
        @Inject(PLATFORM_ID) private platformId) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId) && !this.hasAvailability) {
            this._availabilityService.getAvailability(this._priceParameter.sku.invMastUid.toString()).subscribe((res) => {
                if (res.success) {
                    this._priceParameter.sku.availability = plainToClass(Availability, res.value[0]);
                    this._priceParameter.availabilityReceived$.next(true);
                }
            });
        }
    }

    public get hasAvailability(): boolean {
        return this.sku.availability != null && !(!this.sku.availability.inStockMessage && !this.sku.availability.outOfStockMessage);
    }

    public get availabilityMessage(): string {
        if (this._priceParameter.uom) {
            return this.sku.availability.getDispositionForQuantityAndUnitSize(this._priceParameter.quantity, this._priceParameter.uom.unitSize).message;
        }
    }

    public get stockLevel(): StockLevel {
        if (this._priceParameter.uom) {
            return this.sku.availability.getDispositionForQuantityAndUnitSize(this._priceParameter.quantity, this._priceParameter.uom.unitSize).stockLevel;
        }
    }

    public get availabilityLevelInStock(): boolean {
        return this.stockLevel == StockLevel.InStock;
    }
    
    public get availabilityLevelLimited(): boolean {
        return this.stockLevel == StockLevel.PartialStock;
    }

    public get isInStock(): boolean {
        return this.sku.availability.isInStock;
    }

    public get inStockLeadTime(): string {
        return this.sku.availability.inStockLeadTime;
    }
    
    public get partialStockLeadTime(): string {
        return this.sku.availability.partialStockLeadTime;
    }
    
    public get outOfStockLeadTime(): string {
        return this.sku.availability.outOfStockLeadTime;
    }

    public get shouldShowLeadTimeMessage(): boolean {
        if (this.leadTimeMessage) {
            if (this.compact) {
                return this.leadTimeMessage.length > 0 && !this.sku.availability.isInStock;
            } else {
                return this.leadTimeMessage.length > 0;
            }
        }
    }

    public get leadTimeMessage(): string {
        return this.availabilityLevelInStock ? this.inStockLeadTime : this.availabilityLevelLimited ? this.partialStockLeadTime : this.outOfStockLeadTime;
    }

    public get hasLimitedQuantityAvailable(): boolean {
        return this.sku.availability.hasLimitedQuantityAvailable;
    }

    public get isDiscontinuedByVendor(): boolean {
        return this.sku.availability.isDiscontinuedByVendor;
    }

    public get shouldShowDetailedAvailabilityNote(): boolean {
        return this.hasLimitedQuantityAvailable && this.showDetailedNote;
    }

    private get isPurchasable(): boolean {
        return this._priceParameter.sku.availability?.isPurchasable;
    }

    private get sku(): Sku {
        return this._priceParameter.sku;
    }
}
