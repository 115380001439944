import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoginDTO, LoginHistoryResult } from '@shared/Modules/Identity/Models/LoginDTO';
import { LoginService } from '@shared/Modules/Identity/Services/login.service';
import { RedirectService } from '@shared/Services/redirect.service';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { FSObjectStore } from '@shared/Models/WebWorkerModel';
import { FSResult } from '@shared/Models/FSResultModel';

@Component({
    selector: 'password',
    templateUrl: './password.component.html'
})

export class PasswordComponent implements OnInit {
    private _errorMessage : string = "";
    public formSubmitAttempt: boolean;
    public passwordForm: UntypedFormGroup;
    public loginRequested: boolean;

    @HostListener('document:keydown', ['$event'])
    refreshPage(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.onSubmit();
        }
    }

    @ViewChild('autoFocus', { read: ElementRef, static: false }) set autoFocus(content: ElementRef) {
        if (content) {
            setTimeout(() => { content.nativeElement.focus() }, 0);
        }
    }

    constructor(
        private _loginDto: LoginDTO,
        private _builder: UntypedFormBuilder,
        private _loginService: LoginService,
        private _worker: WebWorkerService,
        private _redirect: RedirectService) {
    }

    ngOnInit() {
        this.passwordForm = this._builder.group({
            password: ['']
        });
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    public get welcomeBackMessage(): string {
        if (this._loginDto.firstName) {
            return `Hello, ${this._loginDto.firstName}`;
        } else {
            return `Welcome back!`;
        }
    }

    public get loginEmail(): string {
        return this._loginDto.email;
    }

    public get loginHistoryResults(): LoginHistoryResult[] {
        return this._loginService.loginHistoryResults;
    }

    public isFieldInvalid(field: string): boolean {
        return (
            (!this.passwordForm.get(field).valid && this.passwordForm.get(field).touched) ||
            (!this.passwordForm.get(field).valid && this.formSubmitAttempt)
        );
    }

    public displayFieldCss(field: string): object {
        return {
            'has-error': this.isFieldInvalid(field),
            'has-feedback': this.isFieldInvalid(field)
        };
    }

    public onSubmit(): void {
        this._errorMessage = "";
        this.formSubmitAttempt = true;
        if (this.passwordForm.controls['password'].value && !this.loginRequested) {
            this.loginRequested = true;
            this._loginDto.password = this.passwordForm.value.password;
            this._loginService.validateUser(this._loginDto).subscribe((res) => {
                this.checkLoginSuccess(res);
                this.loginRequested = false;
            });
        }
    }

    public checkLoginSuccess(loginResult: FSResult<boolean>): void {
        if (loginResult.value) {
            const data = new LoginHistoryResult();
            data.email = this._loginDto.email;
            data.firstName = this._loginDto.firstName;

            if (this._loginService.loginHistoryResults != null) {
                if (this._loginService.loginHistoryResults.length === 0 ||
                    this._loginService.loginHistoryResults.filter((x) => x.email === data.email).length === 0) {

                    this._loginService.loginHistoryResults.push(data);
                }

                for (let result of this._loginService.loginHistoryResults) {
                    if (result.email === data.email) {
                        result = data;
                    }
                }

                this._worker.postMessage('update', FSObjectStore.FSLoginHistory, this._loginService.loginHistoryResults);
            }

            this._redirect.refreshPage();
        } else {
            this._errorMessage = loginResult.message;
            const ac: AbstractControl = this.passwordForm.get('password');
            ac.setErrors({ invalidPassword: true });
        }
    }

    public goToEmail(): void {
        if (this.loginHistoryResults.length > 1) {
            this._redirect.goToLoginUsername();
        } else {
            this._redirect.goToLoginEmail();
        }
    }

    public goToForgotPassword(): void {
        this._redirect.goToLoginForgotPassword();
    }

}
