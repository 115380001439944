import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppErrorHandlerComponent } from '@Error-Handler/Components/app-error-handler.component';
import { ErrorBarComponent } from '@Error-Handler/Components/error-bar.component';
import { ErrorHeaderComponent } from '@Error-Handler/Components/error-header.component';
import { ErrorModalComponent } from '@Error-Handler/Components/error-modal.component';
import { ErrorToastComponent } from '@Error-Handler/Components/error-toast.component';
import { GetHelpComponent } from '@Error-Handler/Components/get-help.component';
import { SharedModule } from '@shared-module';

@NgModule({
  declarations: [
    AppErrorHandlerComponent,
    ErrorModalComponent,
    ErrorToastComponent,
    ErrorBarComponent,
    ErrorHeaderComponent,
    GetHelpComponent
  ],
  imports: [
    SharedModule.forRoot(),
    BrowserAnimationsModule
  ],
  bootstrap: [AppErrorHandlerComponent]
})
export class ErrorHandlerModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const ErrorHandlerElement = createCustomElement(AppErrorHandlerComponent, { injector: this.injector });
    customElements.get('error-handler-app') || customElements.define('error-handler-app', ErrorHandlerElement);
  }
 }

