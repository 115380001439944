<div class="fsPasswordComponent">
    <h2 class="fs-h-border">Login</h2>

    <h3><span class="fs-icon fs-user-profile" aria-hidden="true"></span> {{welcomeBackMessage}}</h3>
    <hr />

    <div><p><b>Email:</b> {{loginEmail}} </p></div>
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="input-wrap">
            <input type="text" name="username" [value]="loginEmail" hidden>
            <label for="password">Enter Password</label>
            <input *ngIf="passwordForm" type="password" name="password" id="login-password" formControlName="password" #autoFocus>
            <field-error-display [displayError]="isFieldInvalid('password')" [errorMsg]="errorMessage" [className]="displayFieldCss('password')">
            </field-error-display>
            <a (click)="goToForgotPassword()" class="fs-small-link">Forgot Password?</a>
        </div>

        <button type="submit"
                class="fs-button-standard fs-button-100 fs-button-spaced"
                [disabled]="loginRequested">
        Login <mat-spinner *ngIf="loginRequested" aria-hidden="true"></mat-spinner><span class="fs-icon fs-chevron-right" *ngIf="!loginRequested" aria-hidden="true"></span>
        </button>
        <hr class="fs-hr-secondary" />
        <div class="fs-inline-links">
            <a class="fs-classic-link" (click)="goToEmail()">Register</a>
            <small>or</small>
            <a class="fs-classic-link" (click)="goToEmail()">Login with a Different Email</a>
        </div>
    </form>
</div>
