import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GetAssortmentRequest, Assortment } from '@shared/Models/OrderHelper';
import { Observable } from 'rxjs';

@Injectable()
export class AssortmentService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }
    
    public getAssortment(request: GetAssortmentRequest): Observable<Assortment[]> {
        return this.http.post<Assortment[]>(this._origin + `/api/assortment`, request);
    }
}