<div class="modal fade"
     bsModal
     #errorModal="bs-modal"
     [config]="{ show: true }"
     tabindex="-1"
     role="dialog"
     aria-labelledby="errorModal"
     (onHidden)="onHidden()">

    <div class="modal-dialog modal-dialog-centered fsErrorModal__{{urgency}}">
        <div class="modal-content p-4">
            <span *ngIf="allowDismissal" class="fs-icon fs-close" aria-label="Close" (click)="closeModal()"></span>

            <div class="modal-body">

                <h2>{{title}}</h2>

                {{message}}

                <div class="fsErrorModal__buttons">
                    <button type="button"
                            *ngIf="displayGoBack"
                            (click)="goBack()"
                            class="fs-button-outline d-inline-flex mt-3 mr-2">

                        <mat-spinner class="fs-icon-left" *ngIf="goBackRequested" aria-hidden="true"></mat-spinner>
                        <span class="fs-icon fs-chevron-left fs-icon-left" *ngIf="!goBackRequested" aria-hidden="true"></span>
                        {{goBackText}}
                    </button>

                    <button type="button"
                            *ngIf="displayRetry"
                            (click)="retryRequest()"
                            [disabled]="retryRequestLimitReached || retryRequestInProgress"
                            [ngClass]="{'fs-button-inactive' : retryRequestLimitReached}"
                            class="fs-button-standard d-inline-flex mt-3 mr-2">

                        Retry <mat-spinner *ngIf="retryRequestInProgress" aria-label="Retrying request"></mat-spinner>

                    </button>

                    <button type="button"
                            *ngIf="!hideReload"
                            (click)="reloadPage()"
                            class="fs-button-standard d-inline-flex mt-3 mr-2">

                        Reload Page <mat-spinner *ngIf="reloadRequested" aria-hidden="true"></mat-spinner>
                    </button>

                    <button type="button"
                            (click)="closeModal()"
                            class="fs-button-outline d-inline-flex mt-3 mr-2">Close</button>
                </div>

                <field-error-display [displayError]="retryRequestUnsuccessful" errorMsg="Retry unsuccessful." [className]="'error'">
                </field-error-display>

                <field-error-display [displayError]="retryRequestLimitReached" errorMsg="Maximum retry limit reached. Please refresh and try again." [className]="'error'">
                </field-error-display>

                <div class="fs-note mt-4" *ngIf="!hideHelp">
                    <h3>Need help?</h3>

                    Give us a call at 800-426-6930.
                </div>
            </div>
        </div>
    </div>
</div>
