import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AnalyticsEventsConfig } from '@shared/Models/AnalyticsEventsConfig';
import { MetaDataKeys, SearchSubmitEvent } from '@shared/Models/AnalyticsModel';
import { IResult } from '@shared/Models/ResultModel';
import { SearchBarNavigationAction, Suggestion } from '@shared/Models/SearchBarModel';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { AutosuggestService } from '@shared/Services/autosuggest.service';
import { LoggingService } from '@shared/Services/logging.service';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';
import { lastValueFrom } from 'rxjs';
declare var $: any; 

@Component({
    selector: '[autosuggestResult]',
    templateUrl: 'autosuggest-result.component.html'
})
export class AutosuggestResultComponent {
    @Input() public result: Suggestion;
    @Input() public resultIndex: number;

    public highlights: IResult[];
    public resultId: string;

    constructor(
        private _searchBarService: SearchBarService,
        private _analyticsService: AnalyticsService,
        private _autosuggestService: AutosuggestService,
        private _loggingService: LoggingService,
        private _changeDetector: ChangeDetectorRef) { }

    public get currentTerms(): string {
        return this._searchBarService.currentTerms;
    }

    public get existsInSearchHistory(): boolean {
        return this._searchBarService.searchHistoryResults?.filter((history) => history.link.text.toLowerCase() === this.result.value.toLowerCase()).length > 0;
    }

    public get text(): string {
        return this.result.value;
    }

    public get hasAutosuggestHighlights(): boolean {
        return this.highlights?.length > 0;
    }

    public async hover(): Promise<void> {
        this._analyticsService.sendGenericEvent(AnalyticsEventsConfig.AutosuggestResult.HOVER, this.result.value);
        this._searchBarService.navigate(SearchBarNavigationAction.Hover, this.result, this.resultIndex);

        this.checkHighlightsAsync();
    }

    public async checkHighlightsAsync(): Promise<void> {
        if (!this.highlights) {
            this.highlights = await this.getHighlightsAsync();
            this._changeDetector.detectChanges();
        }
    }

    public select(trigger: string): void {
        var submitEvent = new SearchSubmitEvent();
        submitEvent.searchType = 1;
        submitEvent.suggestionPosition = this.resultIndex;
        submitEvent.terms = this.result.value;
        submitEvent.rawTerms = this.currentTerms;
        submitEvent.isClick = trigger === 'click';
        submitEvent.keyCount = this._searchBarService.keyCount;
        submitEvent.metaData = {[MetaDataKeys.AutocompleteResultId]: this._searchBarService.suggestionResultId};

        this._analyticsService.sendSearchSubmitEvent(submitEvent);

        this._searchBarService.openSearch(this.result.value);
    }
    
    public hoverHighlights(): void {
        $('#fsAutosuggestResults').removeClass('pending-activation');
    }

    private async getHighlightsAsync(): Promise<IResult[]> {
        if (this.result) {
            var results = this._searchBarService.storedHighlights?.get(this.result.value);
            if (results) {
                this._loggingService.logDeveloperInfo("found existing autosuggest highlights, returning"), results;
                return results;
            } else {
                var getHighlightsResponse$ = this._autosuggestService.getAutoSuggestHighlights(this.result.value, 4);
                var getHighlightsResponse = await lastValueFrom(getHighlightsResponse$);
                if (getHighlightsResponse.success) {
                    this._searchBarService.storeHighlights(this.result.value, getHighlightsResponse.value.results);
                    this.resultId = getHighlightsResponse.value.resultId;
                    return getHighlightsResponse.value.results;
                }
            }
        }
    }
}
