<h2>Verify Account Permissions</h2>
<div class="fsValidationComponent">
    <p>We need to confirm you have permissions to shop on this account. Please choose a preferred account verification method.</p>

    <form [formGroup]="validationForm" (ngSubmit)="onSubmit()">
        <div class="fs-radio">
            <div *ngFor="let method of verificationMethods">
                <input type="radio" [id]="getVerificationId(method)" [value]="getToken(method)" name="method" formControlName="method">
                <label [for]="getVerificationId(method)">
                    <span class="fs-radio__circle"></span>
                    <span class="d-block">
                        <span class="h5" [innerHtml]="getMethod(method)"></span>
                        <span>{{getTarget(method)}}</span>
                    </span>
                </label>
            </div>
            <div>
                <input type="radio" id="haveFisheriesApprove" value="Have fisheries approve me (takes longer)" name="method" formControlName="method">
                <label for="haveFisheriesApprove">
                    <span></span>
                    <span class="d-block">
                        <span class="h5">Have Fisheries approve me</span>
                        <span>(takes longer)</span>
                    </span>
                </label>
            </div>
        </div>
        <field-error-display [displayError]="isFieldInvalid('method')" errorMsg="Please select a verification method." style="color: red">
        </field-error-display>
        <button type="submit"
                class="fs-button-standard fs-button-spaced fs-button-100"
                [disabled]="validationRequested">
        Request Account Verification <mat-spinner *ngIf="validationRequested" aria-hidden="true"></mat-spinner><span class="fs-icon fs-chevron-right" *ngIf="!validationRequested" aria-hidden="true"></span>
        </button>
    </form>
</div>
