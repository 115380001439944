import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FSResult } from '@shared/Models/FSResultModel';
import { SkuPrice } from '@shared/Models/SkuModel';

@Injectable()
export class PriceService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public getSkuPrices(commaSeparatedInvMastUids: string): Observable<FSResult<SkuPrice[]>> {
        return this.http.get<FSResult<SkuPrice[]>>(this._origin + `/api/price/` + commaSeparatedInvMastUids);
    }
}
