import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ImpressionDirective, ProductImpressionDirective } from '@shared/Directives/sharedDirectives';
import { ProductList } from '@shared/Models/AnalyticsModel';
import { IResult } from '@shared/Models/ResultModel';
import { debounceTime, fromEvent } from 'rxjs';
import { ResultClickContext } from '@shared/Components/Result/ResultClickContext';
import { ResultClickHandler } from '@shared/Components/Result/ResultClickHandler';
import { ResultCardComponent } from '@shared/Components/Result/result-card.component';
import { ResultComponent } from '@shared/Components/Result/result.component';
import { Assortment } from '@shared/Models/OrderHelper';
import { AssortmentResultPriceComponent } from './assortment-result-price.component';
import { ResultFeaturedComponent } from '../Result/result-featured.component';


declare var $: any;

@Component({
    selector: 'assortment-results',
    templateUrl: './assortment-results.component.html',
    standalone: true,
    imports: [
        ResultCardComponent,
        ResultFeaturedComponent,
        ResultComponent,
        CommonModule,
        ProductImpressionDirective,
        ImpressionDirective,
        AssortmentResultPriceComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResultClickHandler]
})

export class AssortmentResultsComponent {
    private static __instanceCounter = 0;
    private _instanceId: number;
    private _currentSlides : number;

    @Input() assortment: Assortment;
    @Input() fsls: string;
    @Input() fslsid: string;
    @Input() fslsp: string[];

    public sliderVisible = true;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _resultClickHandler: ResultClickHandler) {
            this._instanceId = AssortmentResultsComponent.__instanceCounter++;
    }
    
    ngAfterViewInit() {
        fromEvent(window, 'resize').pipe(debounceTime(250)).subscribe(() => {
            this.slick(true);
        });

        this.slick();
    }

    public get results() : IResult[]{
        return this.assortment?.results;
    }

    public get name() : string {
        return this.assortment?.name;
    }

    public get list(): string {
        return ProductList.RecommendationResultList;
    }

    public get shouldDisplayAsFeatured(): boolean {
        return this.assortment?.results?.length < 3;
    }

    public get sliderId(): string {
        return `fsRecommendations__slider--${this._instanceId}`;
    }

    public get impressionParams() : object {
        let p : any = this.assortment.parameters || {};
        let localSlidesAtBreakpoint = this.slidesAtBreakpoint;
        let resultLength = this.assortment.results?.length || 0;
        p.resultCount = localSlidesAtBreakpoint > resultLength ? resultLength : localSlidesAtBreakpoint;

        p.products = this.assortment.results?.slice(0,p.resultCount).map(r => r.productId).join(",");

        return p;
    }

    public get slidesAtBreakpoint(): number {
        if (this.shouldDisplayAsFeatured)
            return window.innerWidth > 991 ? 2 : 1;
        if (window.innerWidth > 1200)
            return 6;
        if (window.innerWidth > 768)
            return 4;

        return 2;
    }

    public slick(reinit = false): void {
        const slidesAtThisBreakPoint = this.slidesAtBreakpoint;

        if (reinit && this._currentSlides != slidesAtThisBreakPoint) {
            this._currentSlides = slidesAtThisBreakPoint;
            this.sliderVisible = false;
            this._changeDetector.detectChanges();
            this.sliderVisible = true;
            this._changeDetector.detectChanges();
        }

        setTimeout(() => {
            $(`#${this.sliderId}`).not('.slick-initialized').slick({
                infinite: false,
                autoplay: false,
                buildDivs: false,
                slidesToShow: this.slidesAtBreakpoint,
                slidesToScroll: this.slidesAtBreakpoint      
            });
        }, 0);
    }

    public trackResults(index: number, result: IResult): string {
        return result.productId;
    }

    public setClick(clickContext: ResultClickContext, result: IResult, position: number): void {
        this._resultClickHandler.doResultClick(clickContext,this.list,position);
    }
}
