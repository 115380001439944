import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RedirectService {
    private _origin: string;

    constructor(
        private _router: Router,
        @Inject('ORIGIN_URL') originUrl: string,
        private _location: Location) {

        this._origin = originUrl;
    }

    public goTo404(): void {}

    public goToHomePage(): void {
        window.location.href = this._origin;
    }

    public goToLoginUsername(): void {
        this._router.navigate([{ outlets: { login: ['login'] } }], { skipLocationChange: true });
    }

    public goToLoginEmail(): void {
        this._router.navigate([{ outlets: { login: ['email'] } }], { skipLocationChange: true });
    }

    public goToLoginPassword(): void {
        this._router.navigate([{ outlets: { login: ['password'] } }], { skipLocationChange: true });
    }

    public goToLoginRegistration(): void {
        this._router.navigate([{ outlets: { login: ['registration'] } }], { skipLocationChange: true });
    }

    public goToLoginRegistrationSuccess(): void {
        this._router.navigate([{ outlets: { login: ['registration-success'] } }], { skipLocationChange: true });
    }

    public goToLoginValidation(): void {
        this._router.navigate([{ outlets: { login: ['validation'] } }], { skipLocationChange: true });
    }

    public goToLoginValidationRequestSent(): void {
        this._router.navigate([{ outlets: { login: ['validation-sent'] } }], { skipLocationChange: true });
    }
    
    public goToLoginForgotPassword(): void {
        this._router.navigate([{ outlets: { login: ['forgot-password'] } }], { skipLocationChange: true });
    }

    public goToOrderLoginUsername(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:login)', { skipLocationChange: true });
    }
    
    public goToOrderLoginEmail(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:email)', { skipLocationChange: true });
    }

    public goToOrderLoginEmailGuest(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:guest)', { skipLocationChange: true });
    }

    public goToOrderLoginEmailLoginRegister(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:register)', { skipLocationChange: true });
    }

    public goToOrderLoginPassword(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:password)', { skipLocationChange: true });
    }

    public goToOrderLoginRegistration(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:registration)', { skipLocationChange: true });
    }

    public goToOrderLoginValidation(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:validation)', { skipLocationChange: true });
    }

    public goToOrderLoginValidationRequestSent(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:validation-sent)', { skipLocationChange: true });
    }
    
    public goToOrderLoginForgotPassword(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:forgot-password)', { skipLocationChange: true });
    }

    public goToOrderLoginSuccess(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:password-success)', { skipLocationChange: true });
    }

    public goToOrderRegistrationSuccess(): void {
        this._router.navigateByUrl('order/cart/proceed/(orderLogin:registration-success)', { skipLocationChange: true });
    }
    
    public goToCart(): void {
        this._router.navigateByUrl('order/cart');
    }

    public goToCartAndRefresh(): void {
        this._location.go('order/cart');
        this.refreshPage();
    }

    public goToCheckout(): void {
        this._router.navigateByUrl('order/checkout');
    }
    
    public goToCheckoutAndRefresh(): void {
        this._location.go('order/checkout');
        this.refreshPage();
    }

    public proceedToCheckoutLogin(): void {
        this._router.navigate(['order/cart/proceed'], { skipLocationChange: true });
    }
    
    public proceedToCheckoutLoginWithRefresh(): void {
        this._location.go('order/cart/proceed');
        this.refreshPage();
    }

    public goToCheckoutAddress(): void {
        this._router.navigate(['order/checkout/address']);
    }

    public goToCheckoutPayment(): void {
        this._router.navigate(['order/checkout/payment']);
    }

    public goToCheckoutShipping(): void {
        this._router.navigate(['order/checkout/shipping']);
    }

    public goToCheckoutConfirm(): void {
        this._router.navigate(['order/checkout/confirm']);
    }

    public goToConfirmation(): void {
        this._router.navigateByUrl('order/confirmation');
    }

    public goToWorksheet(id: number): void {
        this._router.navigate([`worksheets/${id}`]);
    }
    
    public goToWorksheets(): void {
        this._router.navigate([`worksheets`]);
    }

    public goToInviteUser(): void {
        this._router.navigate(['invite-user'], { queryParamsHandling: 'preserve' });
    }

    public goToInviteExistingUser(): void {
        this._router.navigate(['invite-existing-user'], { queryParamsHandling: 'preserve' });
    }

    public goToInviteExistingUserLogin(): void {
        this._router.navigate(['invite-existing-user-login'], { queryParamsHandling: 'preserve' });
    }

    public goToInviteUserLogout(): void {
        this._router.navigate(['invite-user-logout'], { queryParamsHandling: 'preserve' });
    }
    
    public refreshPage(): void {
        window.location.reload();
    }
}
