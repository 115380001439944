import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FSObjectStore } from '@shared/Models/WebWorkerModel';
import { RedirectService } from '@shared/Services/redirect.service';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { Subscription } from 'rxjs';
import { LoginHistoryResult, LoginDTO } from '../Models/LoginDTO';
import { LoginService } from '../Services/login.service';

@Component({
    selector: 'shared-login-username',
    templateUrl: './shared-login-username.component.html'
})

export class SharedLoginUsernameComponent implements OnDestroy {
    private _loginHistorySubscription: Subscription;

    public emailBeingRemoved: LoginHistoryResult;

    constructor(
        private _router: Router,
        private _redirect: RedirectService,
        private _loginService: LoginService,
        private _loginDto: LoginDTO,
        private _worker: WebWorkerService,
        private _changeDetector: ChangeDetectorRef) {
    }

    ngOnDestroy() {
        if (this._loginHistorySubscription) {
            this._loginHistorySubscription.unsubscribe();
        }
    }

    public get loginHistoryResults(): LoginHistoryResult[] {
        return this._loginService.loginHistoryResults;
    }

    public getEmail(login: LoginHistoryResult): string {
        return login.email;
    }

    public goToPassword(login: LoginHistoryResult): void {
        this._loginDto.email = login.email;
        this._loginDto.firstName = login.firstName;

        if (this._router.url.includes('proceed')) {
            this._redirect.goToOrderLoginPassword();
        } else {
            this._redirect.goToLoginPassword();
        }
    }

    public goToUsername(): void {
        if (this._router.url.includes('proceed')) {
            this._redirect.goToOrderLoginUsername();
        } else {
            this._redirect.goToLoginUsername();
        }
    }

    public goToEmail(): void {
        if (this._router.url.includes('proceed')) {
            this._redirect.goToOrderLoginEmail();
        } else {
            this._redirect.goToLoginEmail();
        }
    }

    public isRemoveInProgress(login: LoginHistoryResult): boolean {
        return this.emailBeingRemoved === login;
    }

    public removeEmail(login: LoginHistoryResult): void {
        this.emailBeingRemoved = login;

        const updatedResults = this.loginHistoryResults.filter((x) => x.email !== login.email);

        this._loginHistorySubscription = this._worker.fsLoginHistorySubscription$.subscribe((res) => {
            if (res.length === updatedResults.length) {
                this._loginService.loginHistoryResults = res;
                this._changeDetector.detectChanges();

                if (res.length === 0) {
                    this.goToEmail();
                }

                if (res.length === 1) {
                    this.goToPassword(res[0]);
                }

                this._loginHistorySubscription.unsubscribe();
            }
        });

        this._worker.postMessage('update', FSObjectStore.FSLoginHistory, updatedResults);
    }

}
