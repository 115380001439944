<ng-container *ngIf="imageUrl">
    <a [href]="linkUrl | leadingslash">
        <cloudinary-image [alt]="imageAltTag"
                          [url]="imageUrl"
                          [type]="clickableImage"
                          [prerender]="true"></cloudinary-image>
    
        <h3 class="category-title pt-2">
            {{title}}
            <span class="fs-icon fs-chevron-right" aria-hidden="true"></span>
        </h3>
    </a>    
</ng-container>

<ng-container *ngIf="!imageUrl">
    <a href="{{linkUrl}}">
        <div class="fsClickableImage__image-skeleton"></div>
        <div class="fsClickableImage__text-skeleton"></div>
        <div class="fsClickableImage__text-skeleton--75"></div>
    </a>    
</ng-container>