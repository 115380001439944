import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SignalRService {
    public get connectionObservable(): Observable<number> {
        return this.fisheriesModule?.connectionObservable;
    }

    public get fisheriesModule(): any {
        if (typeof window !== 'undefined') {
            return (<any>window).fisheriesModule;
        }
    }

    public get clientHub(): any {
        if (typeof window !== 'undefined') {
            return (<any>window).fs.clientHub;
        }
    }

    public get connectionState(): number {
        return this.clientHub ? this.clientHub.connectionState : HubConnectionState.Disconnected;
    }
}

export enum HubConnectionState {
    Disconnected = 0,
    Connected = 1
}
