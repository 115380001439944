<div @animationWrapper
     [@dismissBar]="dismissBarRequested.toString()"
     (@dismissBar.done)="dismissBar($event)"
     class="fsErrorBar">

    <div class="fsErrorBar__{{urgency}}" @slideInTop>

        <span class="fs-icon fs-toggle-x"
              (click)="requestDismissBar()"></span>

        <div class="fsErrorBar__error-body">
            <h2>{{title}}</h2>
            {{message}}

            <div class="fsErrorBar__buttons">
                <button type="button"
                        *ngIf="displayGoBack"
                        (click)="goBack()"
                        class="fs-button-dark d-inline-flex mt-3 mb-2">

                    <mat-spinner class="fs-icon-left" *ngIf="goBackRequested" aria-hidden="true"></mat-spinner>
                    <span class="fs-icon fs-chevron-left fs-icon-left" *ngIf="!goBackRequested" aria-hidden="true"></span>
                    {{goBackText}}
                </button>
            </div>

            <div class="fsErrorBar__errors"
                 *ngIf="retryRequestUnsuccessful || retryRequestLimitReached">
                <field-error-display [displayError]="retryRequestUnsuccessful" errorMsg="Resubmission attempt unsuccessful." [className]="'error'">
                </field-error-display>

                <field-error-display [displayError]="retryRequestLimitReached" errorMsg="Maximum retry limit reached. Please refresh and try again." [className]="'error'">
                </field-error-display>
            </div>

            <get-help *ngIf="!isSuccess"></get-help>

        </div>

    </div>


</div>

