<a [innerHTML]="text | boldterms:currentTerms" 
     class="fsAutosuggestResults__suggestion"
     [ngClass]="{'visited' : existsInSearchHistory}" 
     (mouseenter)="hover()"
     (click)="select('click')"></a>

<ul class="fsAutosuggestResults__highlight-wrapper" id="fsAutosuggestResults__highlight--{{resultIndex}}" (mouseenter)="hoverHighlights()">
     <li>
          <autosuggest-highlight *ngIf="hasAutosuggestHighlights" 
               class="container fsAutosuggestResults__highlight" 
               [resultId]="resultId" 
               [highlights]="highlights"></autosuggest-highlight>
     </li>
</ul>