import { Address, AddressWrapper } from './AddressModel';
import { AddressStateModel } from './AddressStateModel';
import { CardBookStateModel } from './CardStateModel';
import { IAddressBookWrapper } from './IAddressBookWrapper';
import { ICardBookWrapper } from './ICardBookWrapper';
import { Type } from 'class-transformer';
import { maketype } from '@shared/Common/ClassTransformerHelpers';

export class PaymentBook implements ICardBookWrapper, IAddressBookWrapper {
    public paymentList: PaymentMethod[];
    public cardStateModel: CardBookStateModel;
    public addressStateModel: AddressStateModel;
    public isTestEnv: boolean;
    public enableCreditCardPayments: boolean;
    public enableAddingCards: boolean;

    public get authorizedPayments(): PaymentMethod[] {
        const authorized = this.paymentList.filter((x) => x.isValid === true);
        return authorized;
    }

    public get cardPaymentMethods(): PaymentMethod[] {
        return this.paymentList.filter((pm) => pm instanceof Card || pm.type === 'Card');
    }

    public get payPalSession(): PaymentMethod[] {
        return this.paymentList.filter((pm) => pm instanceof PayPal);
    }

    public get applePaySession(): PaymentMethod[] {
        return this.paymentList.filter((pm) => pm instanceof ApplePay);
    }

    public get amazonPaySession(): PaymentMethod[] {
        return this.paymentList.filter((pm) => pm instanceof AmazonPay);
    }

    public registerCardBookWrapperValuesOnInit(cardStateModel: CardBookStateModel): void {
        this.cardStateModel = cardStateModel;
        this.cardStateModel.cards = this.cardPaymentMethods;
    }

    public registerAddressBookWrapperValuesOnInit(addressStateModel: AddressStateModel): void {
        this.addressStateModel = addressStateModel;
    }
}

export abstract class PaymentMethod {
    public id: string;
    public isValid: boolean;
    public type: string;

    abstract submitPayment();
    abstract authPayment();
}

export class FisheriesAccount extends PaymentMethod {
    public id: string;
    public isValid: boolean;
    public type: string;
    public name: string;
    public termsId: string;
    public balance: number;

    submitPayment() { }
    authPayment() { }
}

export class ManualEntry extends PaymentMethod {
    public isValid = true;
    public type = 'Manual Entry';
    public adminId: string;
    public paymentTitle: string;
    public paymentDescription: string;

    public submitPayment() { }
    public authPayment() { }
}

export class PayPal extends PaymentMethod {
    isValid = true;

    type = 'PayPal';
    authorizationId: string;
    shippingAddress: Address;

    submitPayment() { }
    authPayment() { }
}

export class ApplePay extends PaymentMethod {
    isValid = true;

    type = 'Apple Pay';
    authorizationId: string;
    billingAddress: Address;
    shippingAddress: Address;

    submitPayment() { }
    authPayment() { }
}

export class AmazonPay extends PaymentMethod {
    isValid = true;

    type = 'Amazon Pay';
    authorizationId: string;
    name: string;
    email: string;
    phone: string;
    shippingAddress: Address;

    submitPayment() { }
    authPayment() { }
}

export class GiftCard extends PaymentMethod {
    isValid = true;

    type = 'Gift Card';
    id: string;
    initialBalance: number;
    availableBalance: number;

    submitPayment() { }
    authPayment() { }
}

export class Card extends PaymentMethod {
    public isValid = !this.isExpired;
    public type = 'Card';

    public last4: string;
    public expirationMonth: number;
    public expirationYear: number;
    public cardBrand: string;
    public name: string;

    @Type(maketype(AddressWrapper))
    public billingAddress: AddressWrapper;

    submitPayment() { }
    authPayment() { }

    public guessCardType(value: string): string {
        if (value) {
            if (value.substring(0, 1) === '4') {
                return 'Visa';
            } else if (value.substring(0, 2) === '51' || value.substring(0, 2) === '55') {
                return 'Mastercard';
            } else if (value.substring(0, 2) === '34' || value.substring(0, 2) === '37') {
                return 'Amex';
            } else if (value.substring(0, 2) === '65' || value.substring(0, 4) === '6011') {
                return 'Discover';
            } else if (value.length > 4) {
                return 'Mastercard';
            } else {
                return null;
            }
        }
    }

    public get isExpired(): boolean {
        const date = new Date();
        const year = parseFloat(date.getFullYear().toString().substr(2, 2));
        const month = date.getMonth() + 1;

        const expiryYear = this.expirationYear;
        const expiryMonth = this.expirationMonth;

        if (expiryYear < year) {
            return true;
        } else if ((expiryMonth < month) && (expiryYear <= year)) {
            return true;
        } else {
            return false;
        }
    }
}

export class CardTokenUpdater extends Card {
    public securityCode: string;
}

export class CustomerDTO {
    public paymentMethodId: number;
    public saved: boolean;
    public customerId: number;
}
