import { Component, Input } from '@angular/core';
import { SearchHistoryResult } from '@shared/Models/SearchBarModel';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { FSObjectStore } from '@shared/Models/WebWorkerModel';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { AnalyticsEventsConfig } from '@shared/Models/AnalyticsEventsConfig';

@Component({
    selector: 'search-history-wrapper',
    templateUrl: './search-history-wrapper.component.html'
})
export class SearchHistoryWrapperComponent {
    @Input() public results: SearchHistoryResult[];

    constructor(
        private _worker: WebWorkerService,
        private _searchBarService: SearchBarService,
        private _analyticsService: AnalyticsService) { }

    public clearSearchHistory(): void {
        this._analyticsService.sendGenericEvent(AnalyticsEventsConfig.SearchBar.CLEAR_SEARCH_HISTORY);

        this._worker.postMessage('delete', FSObjectStore.FSSearchHistory);
        this._worker.updateSearchHistory(null);
        this._searchBarService.explicitSelection = null;
    }
}
