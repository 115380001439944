import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDTO } from '@shared/Modules/Identity/Models/LoginDTO';
import { RedirectService } from '@shared/Services/redirect.service';
declare var $: any;

@Component({
    selector: 'success',
    templateUrl: './success.component.html'
})

export class SuccessComponent implements OnInit {
    public registration: boolean;
    public login: boolean;
    public name: string;
    public continueRequested: boolean;

    @HostListener('document:keydown', ['$event'])
    refreshPage(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.startShopping();
        }
    }

    constructor(
        private _router: Router,
        private _redirect: RedirectService,
        private _loginDto: LoginDTO) {
        this.name = this._loginDto.firstName;
    }

    ngOnInit(): void {
        if (this._router.url.includes('password')) {
            this.login = true;
        } else {
            this.registration = true;
        }

        $('#fsLoginModal').click(function(): void {
            this.startShopping();
        });
    }

    public startShopping(): void {
        this.continueRequested = true;
        this._redirect.refreshPage();
    }

}
