import { Injectable } from '@angular/core';
import { FSResult } from '@shared/Models/FSResultModel';
import { maketype } from '@shared/Common/ClassTransformerHelpers';
import { Type } from 'class-transformer';
import { enumerable } from '@shared/ClassHelpers';

// TO DO Angular
// Move to its on file and import

@Injectable()
export class LoginDTO {
    private _email: string;
    private _password: string;
    private _firstName: string;

    @enumerable(true)
    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        this._email = value;
    }

    @enumerable(true)
    public get firstName(): string {
        return this._firstName;
    }

    public set firstName(value: string) {
        this._firstName = value;
    }

    @enumerable(true)
    public get password(): string {
        return this._password;
    }

    public set password(value: string) {
        this._password = value;
    }

    // make a clean self json object without unwanted properties.
    public toJSON(): any {
        const obj: any = {};

        for (const pf in this) {
            if (!pf.startsWith('_')) {
                const prop: any = this[pf];
                if (prop && prop.toJSON) {
                    obj[pf] = prop.toJSON();
                } else {
                    obj[pf] = this[pf];
                }
            }
        }

        return obj;
    }
}

export class ValidationDTO {
    public registeredShopperId: number;
    public pendingShopper: PendingDTO;
}

export class PendingDTO {
    public pendingShopperId: number;
    public customerId: number;
    /*
    verifyWithEmailAddress: string;
    verifyWithSmsNumber: string;
    verifyWithCustomerAdmin: Array<string>;
    */
    public verificationMethods: VerificationMethodDTO[];
}

export class FSPendingDTO extends FSResult<PendingDTO> {
    @Type(maketype(PendingDTO))
    public value: PendingDTO;
}

export class VerificationMethodDTO {
    public verificationId: string;
    public method: string;  // Email, SMS
    public target: string; // ale**@********.com
    public token: string; // kl7843liaf7843lifa
}

export class LoginHistoryResult {
    public firstName: string;
    public email: string;
}

export class ValidateEmailRequest {
    public email: string;
}

export class ValidateEmailResponse {
    public firstName: string;
}
