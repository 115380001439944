import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AnalyticsService } from "@shared/Services/analytics.service";
import { ResultClickContext } from "./ResultClickContext";

@Injectable()
export class ResultClickHandler {
    constructor(
        private _analyticsService: AnalyticsService,
        private _router: Router
    ) {}
 
    public doResultClick(click : ResultClickContext, list: string, position: number): void {
        this._analyticsService.sendProductClick(click.result, list, position, click?.fsls,click?.fslsid,click?.fslsp );

        const serializedUrl = this._router.serializeUrl(this._router.parseUrl(click.url));

        if (click.isCtrlClick === true) {
            window.open(serializedUrl, '_blank');
        } else {
            location.href = serializedUrl;
        }
    }
}