import { Component, Input } from '@angular/core';
import { IResult } from '@shared/Models/ResultModel';
import { ClickableImage } from '@shared/Models/sharedModels';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';

@Component({
    selector: 'autosuggest-highlight',
    templateUrl: './autosuggest-highlight.component.html'
})
export class AutosuggestHighlightComponent {
    public clickableImages: ClickableImage[] = [new ClickableImage(), new ClickableImage(), new ClickableImage(), new ClickableImage()];
    public results: IResult[];

    constructor(
        private _searchBarService: SearchBarService,
        private _analyticsService: AnalyticsService) { }

    @Input() public set highlights(value: IResult[]) {
        this.createClickableImages(value);
    }

    @Input() resultId: string;
    
    public get currentTerms() : string {
        return this._searchBarService.currentTerms
    }

    public sendProductClickEvent(image: ClickableImage): void {
        var index = this.getResultIndexForImage(image);
        this._analyticsService.sendProductClick(this.getResultForImage(image, index), "Autosuggest", index, "sbh",this.currentTerms, null, this.currentTerms, this.resultId);
    }
    
    public getResultForImage(image: ClickableImage, index?: number): IResult {
        var index = index || this.getResultIndexForImage(image);
        return this.results ? this.results[index] : null;
    }

    private getResultIndexForImage(image: ClickableImage): number {
        return this.results?.findIndex(res => res.name == image.title);
    }

    private createClickableImages(results: IResult[]) {
        if (results?.length > 0) {
            this.clickableImages = new Array<ClickableImage>();
            this.results = results;

            for (let result of results) {
                var clickableImage = new ClickableImage();
                clickableImage.title = result.name;
                clickableImage.linkUrl = result.url;
                clickableImage.imageUrl = result.imageUrl;
                clickableImage.imageAltTag = result.imageAltTag;
                this.clickableImages.push(clickableImage);
            }
        }
    }
}
