<ng-container *ngIf="passwordInputTouched">
    <div *ngIf="shouldShowPasswordMatchMessage" class="fsPasswordHints__hint"> 
        <span class="fs-icon fs-fa-exclamation-triangle-solid"></span>
        <small class="fs-red">Passwords need to match</small>
    </div>
    
    <div *ngFor="let hint of passwordHints" class="fsPasswordHints__hint">
        <span class="fs-icon fs-check" *ngIf="hint.isValid"></span>
        <span class="fs-icon fs-fa-exclamation-triangle-solid" *ngIf="!hint.isValid"></span>
        <small [ngClass]="{'fs-green' : hint.isValid, 'fs-red' : !hint.isValid}" [innerHtml]="hint.message"></small>
    </div>
</ng-container>
