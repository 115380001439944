export enum ValidTransactionPrefixes {
    CartShippingOptions = 'cart-shipping-options',
    CartLineQuantity = 'cart-line-quantity',
    CartLineUom = 'cart-line-uom'
}

export class FSSettings {
    public settings: Map<string, string>;

    public getSettingValue(settingKey: string): string {
        let settingValue: string;

        if (settingKey) {
            for (const [key, value] of this.settings) {
                if (key === settingKey) {
                    settingValue = value;
                }
            }
        }

        return settingValue;
    }

}

export class PaginationLink {
    public link: string;
    public page: number;
}

export class ItemsPerPageDropdown {
    public title: string;
    public options: ItemsPerPageOption[];
}

export class ItemsPerPageOption {
    public isActive: boolean;
    public count: number;
}

export class JsonToSheet {
    public json: any;
    public headers: string[];
}

export class OnPageData {
    public isLoggedIn: boolean;
    public title: string;
    public description: string;
    public seoContent: string;
}

export class Link {
    public text: string;
    public linkUrl: string;
}

export class ContentBlock {
    public type: string;
    public preSerializedType: string;
    public html: string;
    public renderInMenu: boolean;
    public menuTitle: string;
    public contentBlocks: ContentBlock[];
}

export class ClickableImageTabs {
    public clickableImageContainers: ClickableImageContainer[];
}

export class ClickableImageContainer {
    public clickableImageContainerTitle: string;
    public clickableImageList: ClickableImage[];
    public clickableImageRenderSize: string;
    public clickableImageTextColor: string;
    public maxClickableImageCount: number;
    public renderClickableImageHorizontalRule: boolean;
}

export class ClickableImage {
    public imageUrl: string;
    public imageAltTag: string;
    public title: string;
    public linkUrl: string;
}
