import { Component, Input } from '@angular/core';
import { SearchBarNavigationAction, SearchHistoryResult } from '@shared/Models/SearchBarModel';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { SearchSubmitEvent } from '@shared/Models/AnalyticsModel';

@Component({
    selector: 'search-history-results',
    templateUrl: 'search-history-results.component.html'
})
export class SearchHistoryResultsComponent {
    @Input() results: SearchHistoryResult[];
    @Input() selectedItem: SearchHistoryResult;

    constructor(
        private _searchBarService: SearchBarService,
        private _analyticsService: AnalyticsService) { }

    public get currentTerms(): string {
        return this._searchBarService.currentTerms || '';
    }

    public getText(result: any): string {
        return result.link.text;
    }

    public getIsSelected(result: SearchHistoryResult): boolean {
        return result === (this._searchBarService.explicitSelection as SearchHistoryResult);
    }

    public hover(result: SearchHistoryResult, resultIndex: number): void {
        this._searchBarService.navigate(SearchBarNavigationAction.Hover, result, resultIndex);
    }

    public select(result: SearchHistoryResult): void {
        var submitEvent = new SearchSubmitEvent();
        submitEvent.isClick = true; //only fired from a click
        submitEvent.terms = result.link.text;
        submitEvent.rawTerms = result.link.text;
        submitEvent.searchType = 3;
        submitEvent.keyCount = this._searchBarService.keyCount;

        this._analyticsService.sendSearchSubmitEvent(submitEvent);

        location.href = this.formatLinkUrl(result);
    }

    private formatLinkUrl(result: SearchHistoryResult): string {
        return "browse?terms=" + result.value;
    }
}
