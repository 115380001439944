import { Type } from 'class-transformer';
import { FSResult } from '@shared/Models/FSResultModel';
import { maketype } from '@shared/Common/ClassTransformerHelpers';

export class RegistrationDTO {
    public registrationId: string;
    public userName: string;
    public email: string;
    public phoneNumber: string;
    public firstName: string;
    public lastName: string;
    public password: string;
    public approved: boolean;
    public shopperId: string;
    public customerId: number;
    public contactId: string;
}

export class GuestDetails {
    public id: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public password: string;

    public get isValid(): boolean {
        return this.email.length > 0
            && this.firstName.length > 0
            && this.lastName.length > 0
            && this.phoneNumber.length > 0;
    }
}

export class FSRegistrationResult extends FSResult<RegistrationDTO> {
    @Type(maketype(RegistrationDTO))
    public value: RegistrationDTO;
}

export class RegisterCustomerDTO {
    public email: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public signupForMailingList: boolean;
}

export class ValidationDTO {
    public registrationId: string;
    public code: string;
}

export class CompleteNewUserInvitationDTO extends ValidationDTO {
    public password: string;
}

export class CompleteExistingUserInvitationDTO extends ValidationDTO {
}

export class ValidationResponse {
    public errorMessage: string;
    public customerName: string;
    public email: string;
    public validated: boolean;
}

export class ExistingUserValidationResponse extends ValidationResponse {
    public wrongShopper: boolean;
}

export class NewUserValidationResponse extends ValidationResponse {
    public redirectToExisting: boolean;
}