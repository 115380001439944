<div @animationWrapper
     [@dismissHeader]="dismissHeaderRequested.toString()"
     (@dismissHeader.done)="dismissHeader($event)" 
     class="fsErrorHeader">

    <div @slideInTop>
        <span class="fs-icon fs-toggle-x"
        (click)="requestDismissHeader()"></span>

        <div class="fsErrorHeader__error-body">
            <b>{{title}}</b> <span class="mr-3">{{message}}</span>

            <button type="button"
                            (click)="reloadPage()"
                            class="fs-button-standard d-inline-flex">

                        Refresh Page <mat-spinner *ngIf="reloadRequested" aria-hidden="true"></mat-spinner>
            </button>
        </div>
    </div>

</div>

