import { NgModule, Injector } from '@angular/core';
import { LoginRoutingModule } from './login-routing.module';
import { createCustomElement } from '@angular/elements';
import { SharedModule } from '@shared-module';
import { AppLoginComponent } from '@Login/Components/app-login.component';
import { EmailComponent } from '@Login/Components/email.component';
import { PasswordComponent } from '@Login/Components/password.component';
import { SuccessComponent } from '@Login/Components/success.component';
import { IdentityModule } from '@shared/Modules/Identity/identity.module';

@NgModule({
  declarations: [
    AppLoginComponent,
    EmailComponent,
    SuccessComponent,
    PasswordComponent
  ],
  imports: [
    LoginRoutingModule,
    SharedModule.forRoot(),
    IdentityModule.forRoot()
  ],
  bootstrap: [AppLoginComponent]
})
export class LoginModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const LoginElement = createCustomElement(AppLoginComponent, { injector: this.injector });
    customElements.get('login-app') || customElements.define('login-app', LoginElement);
  }
}
