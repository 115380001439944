
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ClickableImageContainerSliderComponent } from '@shared/Components/Content-Blocks/clickable-image-container-slider.component';
import { ClickableImageContainerStaticComponent } from '@shared/Components/Content-Blocks/clickable-image-container-static.component';
import { ClickableImageContainerComponent } from '@shared/Components/Content-Blocks/clickable-image-container.component';
import { ClickableImageTabsComponent } from '@shared/Components/Content-Blocks/clickable-image-tabs.component';
import { ClickableImageComponent } from '@shared/Components/Content-Blocks/clickable-image.component';
import { MvcComponent } from '@shared/Components/Content-Blocks/mvc-html.component';
import { ResultCardComponent } from '@shared/Components/Result/result-card.component';
import { ResultLineComponent } from '@shared/Components/Result/result-line.component';
import { ResultComponent } from '@shared/Components/Result/result.component';
import { AnimatedCheckmarkComponent } from '@shared/Components/animated-checkmark.component';
import { BreadcrumbsComponent } from '@shared/Components/breadcrumbs.component';
import { CloudinaryImageComponent } from '@shared/Components/cloudinary-image.component';
import { ConfirmationDialogComponent } from '@shared/Components/confirmation-dialog.component';
import { FieldErrorDisplayComponent } from '@shared/Components/field-error-display.component';
import { FlagComponent } from '@shared/Components/flag.component';
import { ItemsPerPageDropdownComponent } from '@shared/Components/items-per-page-dropdown.component';
import { LazyLoadComponent } from '@shared/Components/lazy-load.component';
import { PaginationComponent } from '@shared/Components/pagination.component';
import { AppShellNoRenderDirective, AppShellRenderDirective, CloudinaryResponsiveDirective, FSStickyDirective, FocusEventDirective, ImpressionDirective, PreloadSlidesDirective, PreventDefaultClickDirective, PreventDefaultTabDirective, PreventDoubleClickDirective, ProductImpressionDirective, ViewModeDirective, appAutoFocus, cardNumberFormat, phoneNumberFormat } from '@shared/Directives/sharedDirectives';
import { RetryHelper } from '@shared/Models/RetryHelper';
import { SkuHelper } from '@shared/Models/SkuHelper';
import { BoldTermsPipe } from '@shared/Pipes/bold-terms.pipe';
import { LeadingSlashPipe } from '@shared/Pipes/leading-slash.pipe';
import { SafePipe } from '@shared/Pipes/safe.pipe';
import { AutosuggestService } from '@shared/Services/autosuggest.service';
import { BreadcrumbsService } from '@shared/Services/breadcrumbs.service';
import { CartAccessor } from '@shared/Services/cart.accessor';
import { ErrorHandlerService } from '@shared/Services/error-handler.service';
import { FSClientErrorHandler } from '@shared/Services/fs-client-error.handler';
import { FSErrorInterceptor } from '@shared/Services/fs-http-error.interceptor';
import { HttpHeadersInterceptor } from '@shared/Services/http-headers.interceptor';
import { HttpLoggingInterceptor } from '@shared/Services/http-logging.interceptor';
import { LoggingService } from '@shared/Services/logging.service';
import { P21PriceRounder } from '@shared/Services/p21-priceRounder.service';
import { PriceService } from '@shared/Services/price.service';
import { RedirectService } from '@shared/Services/redirect.service';
import { SignalRService } from '@shared/Services/signalr.service';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    ClickableImageContainerComponent,
    ClickableImageContainerSliderComponent,
    ClickableImageContainerStaticComponent,
    ClickableImageComponent,
    phoneNumberFormat,
    cardNumberFormat,
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    PreloadSlidesDirective,
    CloudinaryResponsiveDirective,
    MvcComponent,
    ViewModeDirective,
    PreventDefaultTabDirective,
    FSStickyDirective,
    FocusEventDirective,
    appAutoFocus,
    SafePipe,
    ClickableImageTabsComponent,
    ClickableImageContainerComponent,
    PaginationComponent,
    BoldTermsPipe,
    ConfirmationDialogComponent,
    FlagComponent,
    ItemsPerPageDropdownComponent,
    AnimatedCheckmarkComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    MatSelectModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    MatIconModule,
    SlickCarouselModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatSliderModule,
    RouterModule,
    BrowserModule,
    FieldErrorDisplayComponent,
    CloudinaryImageComponent,
    LazyLoadComponent,
    ResultComponent,
    ResultCardComponent,
    ResultLineComponent,
    BreadcrumbsComponent,
    PreventDoubleClickDirective,
    PreventDefaultClickDirective,
    LeadingSlashPipe,
    ProductImpressionDirective,
    ImpressionDirective
    
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    AlertModule,
    BsDropdownModule,
    TooltipModule,
    TabsModule,
    AccordionModule,
    CollapseModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    MatIconModule,
    SlickCarouselModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatSliderModule,
    RouterModule,
    BrowserModule,
    FieldErrorDisplayComponent,
    CloudinaryImageComponent,
    ClickableImageContainerComponent,
    ClickableImageContainerSliderComponent,
    ClickableImageContainerStaticComponent,
    ClickableImageComponent,
    phoneNumberFormat,
    cardNumberFormat,
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    PreloadSlidesDirective,
    CloudinaryResponsiveDirective,
    ProductImpressionDirective,
    ImpressionDirective,
    MvcComponent,
    LazyLoadComponent,
    ViewModeDirective,
    PreventDefaultClickDirective,
    PreventDefaultTabDirective,
    FSStickyDirective,
    PreventDoubleClickDirective,
    FocusEventDirective,
    appAutoFocus,
    SafePipe,
    ClickableImageTabsComponent,
    ClickableImageContainerComponent,
    PaginationComponent,
    BoldTermsPipe,
    LeadingSlashPipe,
    ConfirmationDialogComponent,
    FlagComponent,
    ItemsPerPageDropdownComponent,
    AnimatedCheckmarkComponent,
    BreadcrumbsComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: 'clientHub', useFactory: getClientHub },
        { provide: 'clientId', useFactory: getClientId },
        { provide: 'ORIGIN_URL', useFactory: getOriginUrl },
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpLoggingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: FSErrorInterceptor, multi: true },
        { provide: ErrorHandler, useClass: FSClientErrorHandler },
        AutosuggestService,
        BreadcrumbsService,
        CartAccessor,
        RedirectService,
        ErrorHandlerService,
        P21PriceRounder,
        DatePipe,
        SkuHelper,
        PriceService,
        WebWorkerService,
        SignalRService,
        RetryHelper,
        LoggingService
      ]
    }
  }
}

// to do: move to signalR service
export function getClientHub(): any {
  if (typeof window !== 'undefined') {
      return (<any>window).fs.clientHub;
  } else {
      return {};
  }
}

export function getClientId(): any {
  if (typeof window !== 'undefined') {
      return (<any>window).fs.clientId;
  } else {
      return {};
  }
}

export function getOriginUrl(): string {
  if (typeof window !== 'undefined') {
    return (<any>window).location.origin;
  }
}

