 <div class="fsEmailComponent">
    <h2 class="fs-h-border">My Fisheries</h2>

    <form [formGroup]="emailForm">
        <div class="input-wrap">
            <label for="email">Enter Email to Get Started</label>
           
            <input type="email" name="email" id="email" formControlName="email" #autoFocus> 
            <field-error-display [displayError]="isFieldInvalid('email')" errorMsg="Please enter a valid email" style="color: red">
            </field-error-display>
         </div>

        <button (click)="submitLoginRequest()"
                class="fs-button-standard fs-button-100 fs-button-spaced"
                [disabled]="loginRequested">
        Continue <mat-spinner *ngIf="loginRequested" aria-hidden="true"></mat-spinner><span class="fs-icon fs-chevron-right" *ngIf="!loginRequested" aria-hidden="true"></span>
        </button>
    </form>
</div> 
