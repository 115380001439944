import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FSResult } from '@shared/Models/FSResultModel';
import { Observable } from 'rxjs';

@Injectable()
export class ChangeCustomerService {
  private _origin: string;
  
  constructor(
    private http: HttpClient,
    @Inject('ORIGIN_URL') originUrl: string) {

    this._origin = originUrl;
  }

  public changeCustomer(customerId: string): Observable<FSResult<boolean>> {
    return this.http.get<FSResult<boolean>>(`${this._origin}/api/identity/change-customer/${customerId}`);
  }

}

@Injectable()
export class ChangeCustomerPageModel {
    public currentCustomerName: string;
    public availableCustomers: Map<number, string>;

    public setInitialValues(dto: ChangeCustomerPageModel): void {
      this.currentCustomerName = dto.currentCustomerName;
      this.availableCustomers = dto.availableCustomers;
    }
}
