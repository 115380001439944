import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Link } from '@shared/Models/sharedModels';
import { LeadingSlashPipe } from '@shared/Pipes/leading-slash.pipe';

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    imports: [
        CommonModule,
        LeadingSlashPipe
    ],
    standalone: true
})
export class BreadcrumbsComponent {
    @Input() breadcrumbs: Link[];
    @Input() shouldShowHome = true;
    
    public getText(link: Link): string {
        return link.text;
    }

    public getLink(link: Link): string {
        return link.linkUrl;
    }

    public shouldShowEndSeparator(index: number): boolean {
        return index < this.breadcrumbs.length - 1;
    }
}
