import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewRef, signal } from '@angular/core';
import { CloudinaryService } from '@shared/Services/cloudinary.service';
import { CloudinaryImage, CloudinaryImageType } from '@shared/Models/CloudinaryImageModel';
import { CommonModule } from '@angular/common';
import { LazyLoadComponent } from './lazy-load.component';

@Component({
    selector: 'cloudinary-image',
    templateUrl: './cloudinary-image.component.html',
    standalone: true,
    imports: [CommonModule, LazyLoadComponent]
})

export class CloudinaryImageComponent {
    public imageLoaded: boolean;
    public image = signal<CloudinaryImage>(null);

    @Input() url = 'file_missing';
    @Input() alt = this.defaultAltTag;
    @Input() type = CloudinaryImageType.line;
    @Input() prerender = false;
    @Input() eagerlyLoad = false;
    @Input() bypassLazyLoad = false;

    @ViewChild('parent', { read: ElementRef }) parentRef: ElementRef;

    constructor(
        private _cloudinaryService: CloudinaryService,
        private _changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        this.image.set(this._cloudinaryService.constructImage(this.url, this.type));
    }

    public get src(): string {
        return this.image().src;
    }

    public get srcset(): string {
        return this.image().srcset;
    }

    public get sizes(): string {
        return this.image().sizes;
    }

    public get defaultAltTag(): string {
        if (this.url === 'file_missing') {
            return 'Image coming soon';
        } else {
            return '';
        }
    }

    public get shouldShowSkeletonStyle(): boolean {
        return !this.prerender && this.isSquare && !this.imageLoaded;
    }

    public get shouldShowSpinner(): boolean {
        return !this.prerender && !this.isSquare && !this.imageLoaded;
    }

    public get isSquare(): boolean {
        return (
            this.type === CloudinaryImageType.line ||
            this.type === CloudinaryImageType.clickableImage ||
            this.type === CloudinaryImageType.carouselIndicator ||
            this.type === CloudinaryImageType.resultCard ||
            this.type === CloudinaryImageType.resultLine
        );
    }

    public get parentWidth(): number {
        if (typeof window !== 'undefined') {
            if (this.parentRef) {
                return this.parentRef.nativeElement.getBoundingClientRect().width;
            }
        }
    }

    public setImageLoaded(): void {
        this.imageLoaded = true;

        const vr: ViewRef = (this._changeDetector as ViewRef);
        if (vr && !vr.destroyed) {
            this._changeDetector.detectChanges();
        }
    }
}
