<ng-template #errorModalContainer></ng-template>

<get-help *ngIf="hasToast" class="fsGetHelp__toast--{{errorToastPosition}}"></get-help>

<div class="fsErrorHandler__toast-container--{{errorToastPosition}}">
    <ng-template #errorToastContainer></ng-template>
</div>

<div class="fsErrorHandler__bar-container--{{errorBarPosition}}">
    <ng-template #errorBarContainer></ng-template>
</div>

<div class="fsErrorHandler__header-container">
    <ng-template #errorHeaderContainer></ng-template>
</div>
