import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchHistoryResult } from '@shared/Models/SearchBarModel';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { plainToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-search-bar',
    templateUrl: './app-search-bar.component.html'
})

export class AppSearchBarComponent implements OnInit, OnDestroy {
    private _searchHistorySubscription: Subscription;

    @ViewChild('searchBoxComponent', { read: ElementRef, static: false }) searchBoxRef: ElementRef;

    constructor(
        private _worker: WebWorkerService,
        private _searchBarService: SearchBarService
    ) { }

    ngOnInit() {
        this._searchHistorySubscription = this._worker.fsSearchHistorySubscription$.subscribe((data) => {
            if (!this._searchBarService.searchActionInProgress) {
                var results = plainToInstance<SearchHistoryResult, unknown>(SearchHistoryResult, data?.reverse());
                this._searchBarService.searchHistoryResults = results;
            }
        });
    }

    ngOnDestroy() {
        this._searchHistorySubscription?.unsubscribe();
    }

    public get resultsToggled(): boolean {
        return this._searchBarService.resultsToggled;
    }
}
