import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FSResult } from '@shared/Models/FSResultModel';
import { ShopperDTO, ShopperPermissionDTO, CustomerTypeahead } from '../Models/IdentityModel';
import { PasswordResetDTO } from '../Models/PasswordResetDTO';
import { RedirectService } from '@shared/Services/redirect.service';

@Injectable()
export class IdentityService {
    private _origin: string;

    public isImpersonating: boolean;
    public isImpersonatingShopper: boolean;
    public isImpersonatingCustomer: boolean;
    public currentShopper: ShopperDTO;
    public allSegments: string[];
    public refreshInProgress: boolean;
    public readonly cartRetireWarning: string = 'Changing your context will clear your cart. Do you wish to proceed?';

    constructor(
        private http: HttpClient,
        private _redirect: RedirectService,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public refreshCustomer(shouldRefreshPage: boolean = true): void {
        this.refreshInProgress = true;

        if (shouldRefreshPage) {
            this._redirect.refreshPage();
        } else {
            this.getAllSegments().subscribe((res) => {
                if (res.success) {
                    this.allSegments = res.value;
                }
            });

            this.getCurrentShopper().subscribe((res) => {
                if (res.success) {
                    this.refreshShopperHelper(res.value);

                    this.refreshInProgress = false;
                }
            });
            
            this.getIsImpersonating().subscribe((res) => {
              if (res.success) {            
                this.isImpersonatingShopper = (res.value == "shopper");
                this.isImpersonatingCustomer = (res.value == "customer");
                this.isImpersonating = (this.isImpersonatingShopper || this.isImpersonatingCustomer);
              }
            });
        }
    }

    private refreshShopperHelper(res: ShopperDTO): void {
        this.currentShopper = new ShopperDTO();
        this.currentShopper.currentPermissions = res.currentPermissions;
        this.currentShopper.email = res.email;
        this.currentShopper.firstName = res.firstName;
        this.currentShopper.lastName = res.lastName;
        this.currentShopper.userName = res.userName;
        this.currentShopper.activeSegments = res.activeSegments;
    }

    public getCurrentShopper(): Observable<FSResult<ShopperDTO>> {
        return this.http.get<FSResult<ShopperDTO>>(this._origin + `/api/identity/currentshopper`);
    }

    public changeContext(context: string): Observable<FSResult<boolean>> {
        return this.http.get<FSResult<boolean>>(this._origin + `/api/identity/context/forshopper/` + context);
    }

    public changeAccount(accountId: number): Observable<FSResult<number>> {
        return this.http.get<FSResult<number>>(this._origin + `/api/identity/context/forcustomer/` + accountId);
    }

    public revertAdmin(): Observable<FSResult<boolean>> {
        return this.http.get<FSResult<boolean>>(this._origin + `/api/identity/context/asadmin/`);
    }

    public setPermissions(permissions: ShopperPermissionDTO): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(this._origin + `/api/identity/context/permissionsset/`, permissions);
    }

    public revertDetails(): Observable<FSResult<boolean>> {
        return this.http.get<FSResult<boolean>>(this._origin + `/api/identity/context/original/`);
    }

    public changeSegments(segments: string[]): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(`${this._origin}/api/identity/context/segments/currentshopper`, segments);
    }

    public getAllSegments(): Observable<FSResult<string[]>> {
        return this.http.get<FSResult<string[]>>(this._origin + `/api/identity/context/segments`);
    }

    public getIsImpersonating(): Observable<FSResult<string>> {
      return this.http.get<FSResult<string>>(this._origin + `/api/identity/context/impersonation`);
    }
    
    public getIsImpersonatingCustomer(): Observable<FSResult<boolean>> {
        return this.http.get<FSResult<boolean>>(this._origin + `/api/identity/context/impersonation/customer`);
    }

    public getCustomerList(query: string): Observable<FSResult<CustomerTypeahead[]>> {
        return this.http.get<FSResult<CustomerTypeahead[]>>(this._origin + `/api/identity/customerlist/` + query);
    }

    public generatePasswordReset(email: string): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(this._origin + `/api/identity/generatepasswordreset`, JSON.stringify(email));
    }

    public passwordReset(dto: PasswordResetDTO): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(this._origin + `/api/identity/passwordreset`, dto);
    }

    public signout(): Observable<void> {
        return this.http.get<void>(this._origin + `/api/identity/signout`);
    }
}



