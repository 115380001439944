import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpHeadersInterceptor implements HttpInterceptor {

    //intercepts and sets http headers

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedRequest = request.clone({
            setHeaders: {
                'Content-Type' : 'application/json; charset=utf-8'
            }
        });
        return next.handle(clonedRequest);
    }
}
