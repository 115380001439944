import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { LoginModule } from '@Login/login.module';
import { FSModuleComponent } from '@shared/Components/fs-module.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { HeaderCartFlyoutModule } from '@Header-Cart-Flyout/header-cart-flyout.module';
import { ErrorHandlerModule } from '@Error-Handler/error-handler.module';
import { ChangeCustomerModule } from '@Change-Customer/change-customer.module';
import { MailingListSignupModule } from '@Mailing-List-Signup/mailing-list-signup.module';
import { SearchBarModule } from '@Search-Bar/search-bar.module';
import { AppChangeCustomerComponent } from '@Change-Customer/Components/app-change-customer.component';
import { AppErrorHandlerComponent } from '@Error-Handler/Components/app-error-handler.component';
import { AppHeaderCartFlyoutComponent } from '@Header-Cart-Flyout/Components/app-header-cart-flyout.component';
import { AppLoginComponent } from '@Login/Components/app-login.component';
import { AppMailingListSignupComponent } from '@Mailing-List-Signup/Components/app-mailing-list-signup.component';
import { AppSearchBarComponent } from '@Search-Bar/Components/app-search-bar.component';
import { SharedModule } from '@shared-module';
import { CommonModule } from '@angular/common';
import { FSAssortmentComponent } from './Components/fs-assortment.component';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    HeaderCartFlyoutModule,
    SearchBarModule,
    ErrorHandlerModule,
    ChangeCustomerModule,
    MailingListSignupModule,
    SharedModule.forRoot()
  ],
  exports: [
    LoginModule,
    HeaderCartFlyoutModule,
    SearchBarModule,
    ErrorHandlerModule,
    ChangeCustomerModule,
    MailingListSignupModule
  ]
})

export class ElementsModule {
  constructor(private injector: Injector) { }
  ngDoBootstrap() {
      CustomBootstrap(this.injector);
  }
}

export function CustomBootstrap(inject: Injector) {
  bootstrapApplication(FSModuleComponent);

  const LoginElement = createCustomElement(AppLoginComponent, { injector: inject });
  customElements.get('app-login') || customElements.define('app-login', LoginElement);

  const HeaderCartFlyoutElement = createCustomElement(AppHeaderCartFlyoutComponent, { injector: inject });
  customElements.get('app-header-cart-flyout') || customElements.define('app-header-cart-flyout', HeaderCartFlyoutElement);

  const SearchBarElement = createCustomElement(AppSearchBarComponent, { injector: inject });
  customElements.get('app-search-bar') || customElements.define('app-search-bar', SearchBarElement);

  const ErrorHandlerElement = createCustomElement(AppErrorHandlerComponent, { injector: inject });
  customElements.get('app-error-handler') || customElements.define('app-error-handler', ErrorHandlerElement);

  const ChangeCustomerElement = createCustomElement(AppChangeCustomerComponent, { injector: inject });
  customElements.get('app-change-customer') || customElements.define('app-change-customer', ChangeCustomerElement);

  const MailingListSignupElement = createCustomElement(AppMailingListSignupComponent, { injector: inject });
  customElements.get('app-mailing-list-signup') || customElements.define('app-mailing-list-signup', MailingListSignupElement);

  const assortmentElement = createCustomElement(FSAssortmentComponent, { injector: inject });
  customElements.get('fs-assortment') || customElements.define('fs-assortment', assortmentElement);

}
