import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { IResult, IResultPrice, ResultAvailability, ResultBrand } from '@shared/Models/ResultModel';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { CloudinaryService } from '@shared/Services/cloudinary.service';
import { ResultClickContext } from './ResultClickContext';
import { calculateFSLSUrl } from '@shared/Services/fsls-calculator';

@Component({
    selector: 'base-component',
    template: 'this is a base component',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultComponent {
    @Output() sendClick = new EventEmitter<ResultClickContext>();

    @Input() result: IResult;
    @Input() list: string;
    @Input() position: number;
    @Input() displaySimple: boolean;
    @Input() fsls: string;
    @Input() fslsid: string;
    @Input() fslsp: string[];

    constructor(
        protected _cloudinaryService: CloudinaryService,
        protected _analyticsService: AnalyticsService,
        protected _router: Router,
        @Inject(PLATFORM_ID) protected _platformId) { }

    public get isBrowser(): boolean {
        return isPlatformBrowser(this._platformId);
    }

    public get name(): string {
        return this.result.name;
    }

    public get imageUrl(): string {
        return this.result.imageUrl;
    }

    public get brand(): ResultBrand {
        return this.result.topLevelBrand;
    }

    public get brandName(): string {
        return this.brand.text;
    }

    public get brandUrl(): string {
        return this.brand.linkUrl;
    }

    public get imageAltTag(): string {
        return this.result.imageAltTag;
    }

    public get url(): string {
        return calculateFSLSUrl(this.result.url,this.fsls,this.fslsid,this.fslsp);
    }

    public get rating(): number {
        return this.result.reviewScore;
    }

    public get shouldShowRating(): boolean {
        return this.rating > 0;
    }

    public get availability(): ResultAvailability {
        return this.result.availability;
    }

    public get optionCount(): number {
        return this.result.count;
    }

    public get shouldShowSingleSkuDetails(): boolean {
        return this.optionCount === 1;
    }

    public get sku(): string {
        return this.result.shortCode;
    }

    public get itemId(): string {
        return this.result.itemId;
    }

    public get price(): IResultPrice {
        return this.result.price;
    }

    public get isOnSale(): boolean {
        return this.price?.isOnSale;
    }

    public onClick(isCtrlClick = false): void {
        const ctx = new ResultClickContext();
        ctx.url = this.url;
        ctx.isCtrlClick = isCtrlClick;
        ctx.result = this.result;
        ctx.fsls = this.fsls;
        ctx.fslsid = this.fslsid;
        ctx.fslsp = this.fslsp;

        this.sendClick.emit(ctx);
    }
}
