<ng-container *ngIf="displayHeadings">
    <!--to do: display brand on sku-->
    <!--<h4 [innerHtml]="brand" *ngIf="displayBrand"></h4>-->
    <h2 [innerHtml]="name"></h2>
</ng-container>
<div class="fsSkuDetails__wrap">
    <div class="fsSkuDetails___details" *ngIf="displayDetails">
        <div *ngIf="displaySku">SKU: {{shortCode}}</div>
        <div *ngIf="displayItemId">Item ID: {{itemId}}</div>
        <ng-container *ngIf="displayAttributes">
            <div *ngFor="let att of attributes; let i = index" innerHtml="{{getAttributeValue(i)}}"></div>
        </ng-container>
    </div>
</div>
