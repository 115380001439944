import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AttributeValue, Sku } from '@shared/Models/SkuModel';

@Component({
    selector: 'sku-details',
    templateUrl: './sku-details.component.html',
    standalone: true,
    imports: [CommonModule]
})

export class SkuDetailsComponent {
    @Input() sku: Sku;
    @Input() displayBrand = true;
    @Input() displaySku = true;
    @Input() displayItemId = true;
    @Input() displayAttributes = true;
    @Input() displayDetails = true;
    @Input() displayHeadings = true;

    public get name(): string {
        return this.sku.name;
    }

    public get brand(): string {
        return `Brand Name`;
    }

    public get shortCode(): string {
        return this.sku.shortCode;
    }

    public get itemId(): string {
        return this.sku.itemId;
    }

    public get attributes(): AttributeValue[] {
        return this.sku.attributeValues;
    }

    public getAttributeValue(index: number): string {
        return this.sku.attributeValues[index].value;
    }
}
