<div class="container px-0" *ngIf="results">
    <h2 class="fs-heading-separator pb-3" *ngIf="name">{{name}}</h2>

    <div class="row pl-3 pl-md-0 pr-3 pr-md-0" impression [impressionKey]="fsls" [impressionId]="fslsid" [impressionParams]="impressionParams">
        <div id="{{sliderId}}" class="fsRecommendations__slider slick-slider col-12 px-0" *ngIf="sliderVisible">
            <span class="fs-icon fs-chevron-left slick-arrow fs-no-print"></span>
            <div class="slick-list draggable">
                <div class="slick-track">
                    <ng-container *ngFor="let result of results; let i = index; trackBy: trackResults">
                        <result-card 
                            *ngIf="!shouldDisplayAsFeatured"
                            class="slick-slide d-flex"
                            attr.data-slick-index="{{i}}"
                            [result]="result"
                            [position]="i + 1"
                            (sendClick)="setClick($event, result, i + 1)"
                            [list]="list"
                            productImpression
                            [fsls]="fsls"
                            [fslsid]="fslsid"
                            [fslsp]="fslsp">
                            <assortment-result-price></assortment-result-price>
                        </result-card>
                        <result-featured 
                            *ngIf="shouldDisplayAsFeatured"
                            class="slick-slide d-flex"
                            attr.data-slick-index="{{i}}"
                            [result]="result"
                            [position]="i + 1"
                            (sendClick)="setClick($event, result, i + 1)"
                            [list]="list"
                            productImpression
                            [fsls]="fsls"
                            [fslsid]="fslsid"
                            [fslsp]="fslsp">
                            <assortment-result-price></assortment-result-price>
                        </result-featured>
                    </ng-container>
                </div>
            </div>
            <span class="fs-icon fs-chevron-right slick-arrow fs-no-print"></span>
        </div>
    </div>    
</div>
