import { Injectable } from '@angular/core';
import { CloudinaryImage, CloudinaryImageType, ImageBreakpoints } from '@shared/Models/CloudinaryImageModel';

@Injectable({providedIn: 'root'})
export class CloudinaryService {
    private _baseSource = 'https://image.fisheriessupply.com/';
    private _version = 'v1/';
    private _location = 'static-images/';
    private _sharedTransformations = 'b_rgb:ffffff,c_lpad,dpr_1.0,ar_1:1,d_imageComingSoon-tiff/f_auto';

    public constructImage(url: string, type: CloudinaryImageType): CloudinaryImage {
        var image = new CloudinaryImage();
        
        if (url?.length == 0)
            url = null;

        switch (type) {
            case CloudinaryImageType.line: {
                image.type = CloudinaryImageType.line;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 116;
                image.breakpoints["x21400"] = 232;
                image.breakpoints["x151400"] = 174;
                image.breakpoints[992] = 79;
                image.breakpoints["x2992"] = 158;
                image.breakpoints[768] = 80; 
                image.breakpoints["x2768"] = 160; 
                image.breakpoints[430] = 100;
                image.breakpoints["x3430"] = 300;
                image.breakpoints["x2430"] = 200;
                image.breakpoints[393] = 50;
                image.breakpoints["x3393"] = 150;
                image.breakpoints["x2393"] = 100;
                image.breakpoints[320] = 44;   
                image.breakpoints["x3320"] = 132;   
                image.breakpoints["x2320"] = 88;   
                break;     
            }
            case CloudinaryImageType.carouselPrimary: {
                image.type = CloudinaryImageType.carouselPrimary;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 288;
                image.breakpoints["x21400"] = 550;
                image.breakpoints["x151400"] = 413;
                image.breakpoints[992] = 238;
                image.breakpoints["x2992"] = 476;
                image.breakpoints[320] = 288;
                image.breakpoints["x3430"] = 825;
                image.breakpoints["x2430"] = 550;
                break;     
            }
            case CloudinaryImageType.carouselIndicator: {
                image.type = CloudinaryImageType.carouselIndicator;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 66;
                image.breakpoints["x21400"] = 132;
                image.breakpoints["x151400"] = 99;
                image.breakpoints[992] = 39;
                image.breakpoints["x2992"] = 78;
                image.breakpoints[768] = 46; 
                image.breakpoints["x2768"] = 92; 
                break;     
            }
            case CloudinaryImageType.carouselModal: {
                image.type = CloudinaryImageType.carouselModal;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 619;
                image.breakpoints["x151400"] = 929;
                image.breakpoints[992] = 619;
                image.breakpoints["x2992"] = 1238;
                image.breakpoints[768] = 464; 
                image.breakpoints["x2768"] = 928; 
                image.breakpoints[430] = 506;
                image.breakpoints["x3430"] = 1518;
                image.breakpoints["x2430"] = 1012;
                image.breakpoints[393] = 383;
                image.breakpoints["x3393"] = 1149;
                image.breakpoints["x2393"] = 766;
                image.breakpoints[320] = 346;   
                image.breakpoints["x3320"] = 1038;   
                image.breakpoints["x2320"] = 692;   
                break;     
            }
            case CloudinaryImageType.clickableImage: {
                image.type = CloudinaryImageType.clickableImage;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 210;
                image.breakpoints["x21400"] = 420;
                image.breakpoints["x151400"] = 315;
                image.breakpoints[992] = 118;
                image.breakpoints["x2992"] = 236;
                image.breakpoints[768] = 127; 
                image.breakpoints["x2768"] = 254; 
                image.breakpoints[430] = 303;
                image.breakpoints["x3430"] = 909;
                image.breakpoints["x2430"] = 606;
                image.breakpoints[393] = 151;
                image.breakpoints["x3393"] = 453;
                image.breakpoints["x2393"] = 302;
                image.breakpoints[320] = 135;   
                image.breakpoints["x3320"] = 405;   
                image.breakpoints["x2320"] = 270;   
                break;     
            }
            case CloudinaryImageType.resultCard: {
                image.type = CloudinaryImageType.resultCard;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 288;
                image.breakpoints["x21400"] = 576;
                image.breakpoints["x151400"] = 432;
                image.breakpoints[992] = 167;
                image.breakpoints["x2992"] = 334;
                image.breakpoints[768] = 179; 
                image.breakpoints["x2768"] = 358; 
                image.breakpoints[430] = 154;
                image.breakpoints["x3430"] = 462;
                image.breakpoints["x2430"] = 308;
                image.breakpoints[393] = 83;
                image.breakpoints["x3393"] = 249;
                image.breakpoints["x2393"] = 166;
                image.breakpoints[320] = 75;   
                image.breakpoints["x3320"] = 225;   
                image.breakpoints["x2320"] = 150;   
                break;     
            }
            case CloudinaryImageType.resultLine: {
                image.type = CloudinaryImageType.resultLine;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 223;
                image.breakpoints["x21400"] = 446;
                image.breakpoints["x151400"] = 335;
                image.breakpoints[992] = 131;
                image.breakpoints["x2992"] = 262;
                image.breakpoints[768] = 140; 
                image.breakpoints["x2768"] = 280;
                break;     
            }
            case CloudinaryImageType.resultFeatured: {
                image.type = CloudinaryImageType.resultFeatured;
                image.createUrl = (size: number) => this._baseSource + `w_${size},${this._sharedTransformations},q_auto:eco/` + this._version + this._location + url;
                image.breakpoints = new ImageBreakpoints();
                image.breakpoints[1400] = 177;
                image.breakpoints["x21400"] = 354;
                image.breakpoints["x151400"] = 266;
                image.breakpoints[992] = 177;
                image.breakpoints["x2992"] = 354;
                image.breakpoints[768] = 170; 
                image.breakpoints["x2768"] = 340;
                break;     
            }
        }
        
        return image;
    }
}
