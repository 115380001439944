import { MailingListService } from '@Mailing-List-Signup/Services/mailing-list.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorUrgency } from '@shared/Models/ErrorHandlerModel';
import { SubscriptionRequestDTO } from '@shared/Models/SubscriptionRequestDTO';
import { ErrorHandlerService } from '@shared/Services/error-handler.service';

@Component({
    selector: 'app-mailing-list-signup',
    templateUrl: './app-mailing-list-signup.component.html'
})

export class AppMailingListSignupComponent implements OnInit {
    private _mailingListFormSubmitAttempt: boolean;

    public mailingListForm: FormGroup;
    public submitInProgress: boolean;

    constructor(
        private _builder: FormBuilder,
        private _mailingListService: MailingListService,
        private _errorHandler: ErrorHandlerService) { }

    ngOnInit(): void {
        this.mailingListForm = this._builder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    public isFieldInvalid(field: string): boolean {
        return (
            (!this.mailingListForm.get(field).valid && this.mailingListForm.get(field).touched) ||
            (!this.mailingListForm.get(field).valid && this._mailingListFormSubmitAttempt)
        );
    }

    public submit(): void {
        this._mailingListFormSubmitAttempt = true;

        if (this.mailingListForm.valid) {
            this.submitInProgress = true;

            var dto = new SubscriptionRequestDTO();
            dto.email = this.mailingListForm.value.email;

            this._mailingListService.subscribeEmailToMailingList(dto).subscribe((res) => {
                if (res.success) {
                    this._errorHandler.createErrorModal(
                        "Sign Up Successful", 
                        "You will now receive emails for product specials, promotions, email only coupons, events & pro specific news.",
                        null,
                        null,
                        true,
                        false,
                        false,
                        null,
                        null,
                        null,
                        true,
                        true,
                        ErrorUrgency.SUCCESS);

                    this._mailingListFormSubmitAttempt = false;
                    this.mailingListForm.reset();
                } else {
                    this._errorHandler.createErrorModal("Sign Up Unsuccessful", "An error has occurred. Please try again.")
                }

                this.submitInProgress = false;
            });
        }
    }

}
