import { IResult } from "./ResultModel";
import { Link } from "./sharedModels";

export enum SearchBarNavigationAction {
    Hover = 'Hover',
    Up = 'Up',
    Down = 'Down'
}

export interface SearchBarResult {
    value: string;
}

export class SearchHistoryResult implements SearchBarResult {
    public get value(): string {
        return this.link.text;
    }
    public link: Link;
}

export class Suggestion implements SearchBarResult {
    public value: string;
}

export class AutoSuggestResults {
    public suggestions: Suggestion[];
    public highlights: IResult[];
    public resultId: string
}