import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AssortmentComponent } from '@shared/Components/Assortments/assortment.component';
import { AssortmentSettings } from '@shared/Models/CatalogModel';


@Component({
    selector: "fs-assortment",
    standalone: true,
    template: `
    <assortment class="mx-3" [assortmentSettingsFactory]='getAssortments' [url]='forUrl' [fsls]='fsls'></assortment>
    `,
    imports: [
        CommonModule,
        AssortmentComponent
    ]
})
export class FSAssortmentComponent {

    @Input()
    public assortmentId : string;

    @Input() 
    public forUrl : string;

    @Input() 
    public fsls : string;

    public getAssortments = () : AssortmentSettings[] => {
        if(this.assortmentId){
            const config = new AssortmentSettings();
            config.name = this.assortmentId;

            return new Array<AssortmentSettings>(config);
        }


        return new Array<AssortmentSettings>();
    }
}