import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class HttpLoggingInterceptor implements HttpInterceptor {

    constructor(private _loggingService: LoggingService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (response) => {
                    if (response instanceof HttpResponse) {
                        if (response.body) {
                            this._loggingService.logHttpEvent(response);
                        }
                    }
                }
            )
        );
    }
}
