import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { FSSettings} from '@shared/Models/sharedModels';

declare var fisheriesModule: any;
@Injectable({providedIn: 'root'})
export class SettingsAccessor {
    private _headerSettings: FSSettings;
    
    public constructor() {
        if (typeof window !== 'undefined') {
            const settingsObj: FSSettings = plainToClass(FSSettings, fisheriesModule.settings);
            this._headerSettings = settingsObj;
        } else {
            this._headerSettings = new FSSettings();
            this._headerSettings.settings = new Map<string, string>();
        }
    }

    public get Settings(): FSSettings {
        return this._headerSettings;
    }

    public getSetting(setting: string): string {
        return this._headerSettings.getSettingValue(setting);
    }

    //valid values: dropdown, plusminus
    public get quantitySelectorCart(): string {
        return this._headerSettings.getSettingValue("quantityselector:cart");
    }

    //valid values: disabled, enabled
    public get headerCartFlyOutMobile(): string {
        return this._headerSettings.getSettingValue("headercartflyout:mobile");
    }

    //valid values: multiline, oneline
    public get creditCardFormStyle(): string {
        return this._headerSettings.getSettingValue("creditcardform:style");
    }

    //valid values: oneline, twoline, append
    public get addressLinesStyle(): string {
        return this._headerSettings.getSettingValue("addresslines:style");
    }

    //valid values: multi, single
    public get paymentMethodBookColumns(): string {
        return this._headerSettings.getSettingValue("paymentmethodbook:columns");
    }

    //valid values: inline, modal
    public get addressFormsStyle(): string {
        return this._headerSettings.getSettingValue("addressforms:style");
    }

    //valid values: inline, modal
    public get cardFormsStyle(): string {
        return this._headerSettings.getSettingValue("cardforms:style");
    }

    //valid values: top, bottom
    public get errorBarPosition(): string {
        return this._headerSettings.getSettingValue("errorbar:position");
    }

    //valid values: top, bottom
    public get errorToastPosition(): string {
        return this._headerSettings.getSettingValue("errortoast:position");
    }

    //valid values: contained, fullwidth
    public get menuLinksStyle(): string {
        return this._headerSettings.getSettingValue("menulinks:style");
    }

    //valid values: fade, slideintop
    public get resultslistAnimation(): string {
        return this._headerSettings.getSettingValue("resultslist:animation");
    }

    //valid values: all, selective
    public get browseContentBlockRendering(): string {
        return this._headerSettings.getSettingValue("browse:contentblockrendering");
    }

    //valid values: dropdown, unlinked
    public get checkoutHeaderUser(): string {
        return this._headerSettings.getSettingValue("checkoutheader:user");
    }

    //valid values: disabled, enabled
    public get taxToggleVisibility(): string {
        return this._headerSettings.getSettingValue("taxtoggle:visibility");
    }

    //valid values: true, false
    public get orderPoRequired(): string {
        return this._headerSettings.getSettingValue("order:porequired");
    }

    //valid values: true, false
    public get orderCanSubmitOrders(): string {
        return this._headerSettings.getSettingValue("order:cansubmitorders");
    }

    //valid values: true, false
    public get p21ReadOnly(): string {
        return this._headerSettings.getSettingValue("p21:readonly");
    }

    //valid values: any int
    public get typeaheadDebounceMS(): string {
        return this._headerSettings.getSettingValue("autosuggest:debouncems");
    }

    //valid values: true, false
    public get shouldShowEditDimensions(): string {
        return this._headerSettings.getSettingValue("siteedit:shouldshoweditdimensions");
    }

    //valid values: true, false
    public get shouldShowEditBrands(): string {
        return this._headerSettings.getSettingValue("siteedit:shouldshoweditbrands");
    }

    //valid values: true, false
    public get canCreateSubBrands(): string {
        return this._headerSettings.getSettingValue("siteedit:cancreatesubbrands");
    }

    //valid values: true, false
    public get canCreateTopLevelBrands(): string {
        return this._headerSettings.getSettingValue("siteedit:cancreatetoplevelbrands");
    }

    //valid values: true, false
    public get hmpActive(): boolean {
        var value = this._headerSettings.getSettingValue("hmp:active");
        return value ? JSON.parse(value) : false; 
    }
    
    //valid values: default, light_grey, bright_blue, light_orange, light_green, transparent
    public get hmpWarningScheme(): string {
        return this._headerSettings.getSettingValue("hmp:scheme"); 
    }
}
