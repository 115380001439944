import { Component } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';
declare var fisheriesModule: any;

@Component({
    selector: 'fs-module',
    standalone: true,
    template: ``
})
export class FSModuleComponent {
    ngOnInit() {
        fisheriesModule.connectionBehaviorSubject = new BehaviorSubject(null);
        fisheriesModule.connectionObservable = fisheriesModule.connectionBehaviorSubject.asObservable();

        fisheriesModule.responsiveBehaviorSubject = new BehaviorSubject(0);
        fisheriesModule.responsiveObservable = fisheriesModule.responsiveBehaviorSubject.asObservable();

        fisheriesModule.sliderBehaviorSubject = new BehaviorSubject(0);
        fisheriesModule.sliderObservable = fisheriesModule.sliderBehaviorSubject.asObservable();

        fisheriesModule.loginHistoryBehaviorSubject = new BehaviorSubject(fisheriesModule.loginHistoryResults);
        fisheriesModule.loginHistoryObservable = fisheriesModule.loginHistoryBehaviorSubject.asObservable();
    }
}
