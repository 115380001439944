import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedLoginRegistrationComponent } from '@shared/Modules/Identity/Components/shared-login-registration.component';
import { SharedLoginUsernameComponent } from '@shared/Modules/Identity/Components/shared-login-username.component';
import { SharedLoginValidationSentComponent } from '@shared/Modules/Identity/Components/shared-login-validation-sent.component';
import { SharedLoginValidationComponent } from '@shared/Modules/Identity/Components/shared-login-validation.component';
import { EmailComponent } from './Components/email.component';
import { PasswordComponent } from './Components/password.component';
import { SuccessComponent } from './Components/success.component';
import { SharedLoginForgotPasswordComponent } from '@shared/Modules/Identity/Components/shared-login-forgot-password.component';

const routes: Routes = [
    { path: 'login', component: SharedLoginUsernameComponent, outlet: 'login' },
    { path: 'email', component: EmailComponent, outlet: 'login' },
    { path: 'password', component: PasswordComponent, outlet: 'login' },
    { path: 'registration', component: SharedLoginRegistrationComponent, outlet: 'login' },
    { path: 'validation', component: SharedLoginValidationComponent, outlet: 'login' },
    { path: 'validation-sent', component: SharedLoginValidationSentComponent, outlet: 'login' },
    { path: 'registration-success', component: SuccessComponent, outlet: 'login' },
    { path: 'forgot-password', component: SharedLoginForgotPasswordComponent, outlet: 'login' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
               enableTracing: true
            }
        )
    ],
    exports: [
        RouterModule
    ]
})

export class LoginRoutingModule { }
