import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared-module';
import { IdentityModule } from '@shared/Modules/Identity/identity.module';
import { ChangeCustomerPageModel, ChangeCustomerService } from '@Change-Customer/Services/change-customer.service';
import { RetireCartService } from '@shared/Services/retire-cart.service';
import { AppChangeCustomerComponent } from './Components/app-change-customer.component';

@NgModule({
  declarations: [AppChangeCustomerComponent],
  imports: [
    BrowserModule,
    SharedModule.forRoot(),
    IdentityModule.forRoot()
  ],
  providers: [
    { provide: 'ORIGIN_URL', useFactory: getOriginUrl },
    { provide: 'changeCustomerPageModel', useFactory: pageModelFactory },
    ChangeCustomerPageModel,
    ChangeCustomerService,
    RetireCartService
  ],
  bootstrap: [AppChangeCustomerComponent]
})
export class ChangeCustomerModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const ChangeCustomerElement = createCustomElement(AppChangeCustomerComponent, { injector: this.injector });
    customElements.get('app-change-customer') || customElements.define('app-change-customer', ChangeCustomerElement);
  }
}

export function getOriginUrl(): string {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }
}

export function pageModelFactory(): any {
  if (typeof window !== 'undefined') {
    return window['changeCustomerPageModel'];
  }
}
