import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { AppHeaderCartFlyoutComponent } from './Components/app-header-cart-flyout.component';
import { createCustomElement } from '@angular/elements';
import { SharedModule } from '@shared-module';
import { SkuModule } from '@shared/Modules/Sku/sku.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppHeaderCartFlyoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SkuModule.forRoot(),
    SharedModule.forRoot()
  ],
  bootstrap: [AppHeaderCartFlyoutComponent]
})
export class HeaderCartFlyoutModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const HeaderCartFlyoutElement = createCustomElement(AppHeaderCartFlyoutComponent, { injector: this.injector });
    customElements.get('header-cart-flyout-app') || customElements.define('header-cart-flyout-app', HeaderCartFlyoutElement);  
  }
 }
