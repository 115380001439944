import { IResult, IResultPrice, ResultAvailability, ResultBrand } from "./ResultModel";

export class Assortment{
    public name: string;
    public results: AssortmentResult[]
    public assortmentId: string;
    public parameters: object;
}

export class AssortmentPrice implements IResultPrice
{
    public isOnSale: boolean;
    public fullPrice: number;
    public salePrice: number;
    public minPrice: number;
    public maxPrice: number;
}

export class AssortmentResult implements IResult{
    public name: string;
    public productId: string;
    public shortCode: string;
    public itemId: string;
    public url: string;
    public count: number;
    public imageUrl: string;
    public imageAltTag: string;
    public reviewScore: number;
    public availability: ResultAvailability;
    public topLevelBrand: ResultBrand;
    public invMastUid: number;
    public parameters: object;
    public price: AssortmentPrice;
}

export class GetAssortmentRequest {
    public url: string;
    public assortmentId : string;
    public filters: Map<string, string>;
}

