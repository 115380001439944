export class ShopperDTO {
    public userName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public currentPermissions: ShopperPermissionDTO;
    public activeSegments: string[];
}

export class ShopperPermissionDTO {
    public customerId: number;
    public customerName: string;
    public isAdmin: boolean;
    public webEnabled: boolean;
    public canEditAddresses: boolean;
    public canEditPaymentOptions: boolean;
    public canEditCreditInformation: boolean;
    public canViewInvoiceHistory: boolean;
}

export class CustomerTypeahead {
    public customerName: string;
    public customerId: number;
}
