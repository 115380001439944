import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    CheckoutStepEvent, GenericEvent, ResultImpressionEvent,
    FSAnalyticsDTO, ProductViewEvent, RoutableEvent, ResultClickEvent, ResultPaginationEvent, SearchSubmitEvent, InputFocusedEvent, MetaDataKeys, GlobalImpressionEvent as GlobalImpressionEvent} from '@shared/Models/AnalyticsModel';
import { ProductPageModel } from './Product.service';
import { isPlatformBrowser } from '@angular/common';
import { Product } from '@shared/Models/ProductModel';
import { Sku } from '@shared/Models/SkuModel';
import { IResult } from '@shared/Models/ResultModel';


@Injectable({providedIn: 'root'})
export class AnalyticsService {
    private _origin: string;

    constructor(
        @Inject('ORIGIN_URL') originUrl: string,
        @Inject(PLATFORM_ID) private platformId) {

        this._origin = originUrl;
    }

    public sendGenericEvent(eventDTO: GenericEvent, label?: string, value?: number): void {
        const fullEvent = this.completeEventDTO(eventDTO, label, value);
        this.fireRoutableEvent(fullEvent);
    }
    
    public sendResultsPaginationChangedEvent(pageNumber: number, url: string){
        if (isPlatformBrowser(this.platformId)) {
            const paginationEvent : ResultPaginationEvent = this.createResultPaginationEvent(pageNumber, url);
            this.fireRoutableEvent(paginationEvent);
        }
    }

    public sendProductImpression(result: IResult, list: string, position: number, fsls: string, fslsid:string, fslsp: string[]): void {
        if (isPlatformBrowser(this.platformId)) {
            const productImpression: ResultImpressionEvent = this.createProductImpressionEvent(result, list, position, fsls, fslsid, fslsp);
            this.fireRoutableEvent(productImpression);
        }
    }

    public sendImpression(key : string, id : string, params : object): void {
        if (isPlatformBrowser(this.platformId)) {
            const impression: GlobalImpressionEvent = this.createGlobalImpressionEvent(key, id, params);
            this.fireRoutableEvent(impression);
        }
    }


    public sendProductClick(result: IResult, list: string, position: number, fsls: string, fslsid:string, fslsp: string[], termValue: string = null, resultId: string = null): void {
        if (isPlatformBrowser(this.platformId)) {
            const productClick: ResultClickEvent = this.createProductClickEvent(result, list, position, fsls, fslsid, fslsp, resultId);
            
            if (termValue)
                this.fireRoutableEvent(productClick, ["terms", termValue]);
            else
                this.fireRoutableEvent(productClick);
        }
    }

    public async sendProductViewAsync(product: ProductPageModel, activeSku: Sku): Promise<void> {
        if (isPlatformBrowser(this.platformId)) {
            const productViewEvent: ProductViewEvent = await this.createProductViewEventAsync(product.productData, activeSku);
            this.fireRoutableEvent(productViewEvent);
        }
    }

    public sendUpdateCheckoutStep(step: number, option?: string): void {
        if (isPlatformBrowser(this.platformId)) {
            const actionDTO = this.createCheckoutStepEvent(step, option);
            this.fireRoutableEvent(actionDTO);
        }
    }

    public sendSearchSubmitEvent(data : SearchSubmitEvent): void {
        if (isPlatformBrowser(this.platformId)){
            this.fireRoutableEvent(data);
        }
    } 

    public sendInputFocusedEvent(data : InputFocusedEvent): void {
        if (isPlatformBrowser(this.platformId)){
            this.fireRoutableEvent(data);
        }
    }

    public fireRoutableEvent<T extends RoutableEvent>(evt: T, additionalContext?: [string, string]): void {
        if (isPlatformBrowser(this.platformId)) {
            const analyticsDTO = new FSAnalyticsDTO(evt, additionalContext);
            const json : string = JSON.stringify(analyticsDTO);
            (navigator.sendBeacon && navigator.sendBeacon(evt.url,json)) ||  fetch(evt.url, {body: json, method: 'POST', keepalive: true});
        }
    }

    private createCheckoutStepEvent(step: number, option: string): CheckoutStepEvent {
        const actionDTO = new CheckoutStepEvent(step);
        actionDTO.step = step;
        actionDTO.option = option;
        return actionDTO;
    }

    private createResultPaginationEvent(pageNumber : number, url: string) {
        const evt: ResultPaginationEvent = new ResultPaginationEvent();
        evt.pageNumber = pageNumber;
        evt.paginationUrl = url;
        return evt;
    }

    private createProductClickEvent(result: IResult, list: string, position: number, fsls: string, fslsid:string, fslsp: string[], resultId: string = null): ResultClickEvent {
        const evt: ResultClickEvent = new ResultClickEvent();
        evt.result = result;
        evt.position = position;
        evt.listName = list;
        evt.fsls = fsls;
        evt.fslsid = fslsid;
        evt.fslsp = fslsp;

        if (resultId)
            evt.metaData = {[MetaDataKeys.AutocompleteResultId]: resultId};

        return evt;
    }

    private createProductImpressionEvent(result: IResult, list: string, position: number, fsls : string, fslsid : string, fslsp: string[]): ResultImpressionEvent {
        const evt: ResultImpressionEvent = new ResultImpressionEvent();
        evt.result = result;
        evt.position = position;
        evt.listName = list;
        evt.fsls = fsls;
        evt.fslsid = fslsid;
        evt.fslsp = fslsp;
        return evt;
    }

    private createGlobalImpressionEvent(subModule : string, id : string, params : object){
        const evt : GlobalImpressionEvent = new GlobalImpressionEvent();
        evt.key = subModule;
        evt.id = id;
        evt.params = params;
        
        return evt;
    }

    private completeEventDTO(eventDTO: GenericEvent, label: string, value: number): GenericEvent {
        const event = eventDTO;

        if (label) {
            event.label = label;
        }

        if (value) {
            event.value = value;
        }
        return event;
    }

    private async createProductViewEventAsync(productData: Product, activeSku: Sku): Promise<ProductViewEvent> {
        const evt: ProductViewEvent = new ProductViewEvent();

        if (productData) {
            evt.productId = productData.id?.toString();
            evt.productName = productData.name;
            evt.brands = productData.brands;
            evt.categories = productData.categories;
           
            const navs = window.performance.getEntriesByType("navigation");
            if(navs && navs.length > 0 && navs[0].entryType == "navigation")
            {
                evt.isRefresh = (<PerformanceNavigationTiming>navs[0]).type == "reload";
            }
            else
            {
                evt.isRefresh = false;
            }
        }

        if (activeSku) {
            evt.skuId = activeSku.id;
            evt.itemId = activeSku.itemId
            evt.skuName = activeSku.name;
        }

        try {
            let fa : any = (<any>window).fisheriesModule?.fa;
            if(fa){
                await fa.openDb();
                let browseReferrer = await fa.getProductResultRef(evt.productId).then(function(result){
                    return result;
                });
                evt.browseReferrer = browseReferrer;
            }
        } catch (e) {console.log(e);}

        return evt;
    }
}
