import { Component, ElementRef, Input, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { IResult } from '@shared/Models/ResultModel';
import { Suggestion } from '@shared/Models/SearchBarModel';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';
import { AutosuggestResultComponent } from './autosuggest-result.component';
declare var $: any;

@Component({
    selector: 'autosuggest-results',
    templateUrl: 'autosuggest-results.component.html'
})
export class AutosuggestResultsComponent {
    private _results: Suggestion[];

    @ViewChildren(AutosuggestResultComponent) set resultComponentRefs(refs: QueryList<AutosuggestResultComponent>) {
        this._searchBarService.resultComponentRefs = refs?.toArray();
    }

    @ViewChildren('autosuggestResultRef', { read: ViewContainerRef }) set resultElementRefs(refs: QueryList<ElementRef>) {
        this._searchBarService.resultElementRefs = refs?.toArray();
    }

    constructor(private _searchBarService: SearchBarService) {}

    @Input() public set results(results: Suggestion[]) {
        this._results = results;

        setTimeout(() => {
            $('#fsAutosuggestResults').menuAim({
                rowSelector: "li.fsAutosuggestResults__result-wrapper",
                enter: function(a) {
                    $(a).addClass('selected');
                    $(a).parent().addClass('pending-activation');
                },
                exit: function(a) {
                    $('#fsAutosuggestResults').find('.selected').removeClass('selected');
                },
                activate: function(a) {
                    $(a).addClass('selected');
                    $('#fsAutosuggestResults').find('.fsAutosuggestResults__highlight-wrapper').hide();
                    $(a).children('.fsAutosuggestResults__highlight-wrapper').show();
                    $(a).parent().removeClass('pending-activation');
                },
                deactivate: function(a) {
                    $(a).removeClass('selected');
                    $(a).children('.fsAutosuggestResults__highlight-wrapper').hide();
                },
                exitMenu: function(a) {
                    $(a).removeClass('pending-activation');
                    $('#fsAutosuggestResults').find('.fsAutosuggestResults__highlight-wrapper').hide();
                    $('#fsAutosuggestResults').find('.selected').removeClass('selected');
                    $('.fsAutosuggestResults__result-wrapper--default').show().find('.fsAutosuggestResults__highlight-wrapper').show();
                },
                submenuDirection: 'right'
            });
        }, 0);
    }

    public get results(): Suggestion[] {
        return this._results;
    }

    public get defaultHighlights(): IResult[] {
        return this._searchBarService.termHighlights;
    }

    public get defaultResultId(): string {
        return this._searchBarService.suggestionResultId;
    }

    public get hasAutosuggestHighlights(): boolean {
        return this.defaultHighlights?.length > 0;
    }

    public getIsSelected(result: Suggestion): boolean {
        return result.value === (this._searchBarService.explicitSelection?.value);
    }
}
