import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AnalyticsEventsConfig } from '@shared/Models/AnalyticsEventsConfig';
import { SearchSubmitEvent } from '@shared/Models/AnalyticsModel';
import { SearchBarNavigationAction } from '@shared/Models/SearchBarModel';
import { FSObjectStore, FSWebWorkerRequestValue } from '@shared/Models/WebWorkerModel';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { AutosuggestService } from '@shared/Services/autosuggest.service';
import { LoggingService } from '@shared/Services/logging.service';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';
import { SettingsAccessor } from '@shared/Services/settings.accessor';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare var $: any;

@Component({
    selector: 'search-box',
    templateUrl: './search-box.component.html'
})
export class SearchBoxComponent {
    public manageChangedTerms$ = new Subject<void>();
    public changedTermsSubscription: Subscription;
    public shouldShowWatermark: boolean;

    @HostListener('document:click', ['$event'])
    public clickInside(event): void {
        if (this._element.nativeElement.contains(event.target)) {
            this.manageChangedTerms();
            this.shouldShowWatermark = true;
            this._searchBarService.resultsToggled = true;
        }
    }

    @ViewChild('focusInput', { read: ElementRef, static: false }) focusInput: ElementRef;

    constructor(
        private _element: ElementRef,
        private _worker: WebWorkerService,
        private _searchBarService: SearchBarService,
        private _autosuggestService: AutosuggestService,
        private _analyticsService: AnalyticsService,
        private _settingsAccessor: SettingsAccessor,
        private _loggingService: LoggingService
    ) {
        if (this.hasSearchTextFromQueryParams()) {
            this.currentTerms = this.getSearchTextFromQueryParams();
        }
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            $('#fsPrimaryNav__search-wrap').on('shown.bs.collapse', () => {
                setTimeout(() => { this.focusInput.nativeElement.focus(); }, 0);
            });
        }

        this.changedTermsSubscription = this.manageChangedTerms$.pipe(
            debounceTime(this.typeaheadDebounceMS)
        ).subscribe(() => {
            this.manageChangedTerms();
            this.shouldShowWatermark = true;
            this._searchBarService.resultsToggled = true;
        });
    }

    public get typeaheadDebounceMS(): number {
        return parseFloat(this._settingsAccessor.typeaheadDebounceMS);
    }

    public get currentTerms(): string {
        return this._searchBarService.currentTerms;
    }

    public set currentTerms(newValue: string) {
        this._searchBarService.currentTerms = newValue;
    }

    public get hasValidCurrentTerms(): boolean {
        return this._searchBarService.hasValidCurrentTerms;
    }

    public get value(): string {
        return this.currentTerms || '';
    }

    public get placeholder(): string {
        if (!this.watermark) {
            return 'Search by keyword or part number...';
        } else {
            return '';
        }
    }

    public get watermark(): string {
        if (this.currentTerms) {
            if (this._searchBarService.explicitSelection) {
                if (this._searchBarService.explicitSelection.value.toLowerCase().startsWith(this.currentTerms.toLowerCase())) {
                    let match = this._searchBarService.explicitSelection.value.slice(0, (this.currentTerms.length));
                    return this._searchBarService.explicitSelection.value.replace(match, this.currentTerms);
                } else {
                    return '';
                }
            } else if (this._searchBarService.implicitSelection) {
                if (this._searchBarService.implicitSelection.value.toLowerCase().startsWith(this.currentTerms.toLowerCase())) {
                    let match = this._searchBarService.implicitSelection.value.slice(0, (this.currentTerms.length));
                    return this._searchBarService.implicitSelection.value.replace(match, this.currentTerms);
                } else {
                    return '';
                }
            } else {
                return this.currentTerms;
            }
        } else {
            if (this._searchBarService.explicitSelection) {
                return this._searchBarService.explicitSelection.value;
            } else {
                return '';
            }
        }
    }

    public get hasTermsOrSelection(): boolean {
        return this.hasValidCurrentTerms || this._searchBarService.explicitSelection != null;
    }

    public input(value: [any, string]): void {
        this._searchBarService.explicitSelection = null;
        this._searchBarService.currentTerms = value[1];
        this.manageChangedTerms$.next();
        this.keyup(value);
    }

    public keydown(event: any): void {
        if (event && event.target && event.keyCode) {
            switch (event.keyCode) {
                case 38:
                case 40:
                    event.preventDefault();
                    break;
                default:
                    break;
            }
        }
    }

    public keyup(value: [any, string]): void {
        this.shouldShowWatermark = false;

        if (value[0] && value[0].target && value[0].keyCode) {
            const keyCode: number = value[0].keyCode;
            switch (keyCode) {
                case 9:
                    this.autocomplete();
                    break;
                case 13:
                    this._analyticsService.sendGenericEvent(AnalyticsEventsConfig.SearchBar.KEYBOARD_NAVIGATION, 'Enter');
                    this.search("enter");
                    break;
                case 38:
                    this._analyticsService.sendGenericEvent(AnalyticsEventsConfig.SearchBar.KEYBOARD_NAVIGATION, 'Up');
                    this._searchBarService.navigate(SearchBarNavigationAction.Up);
                    this.shouldShowWatermark = true;
                    break;
                case 39:
                    this.autocomplete();
                    break;
                case 40:
                    this._analyticsService.sendGenericEvent(AnalyticsEventsConfig.SearchBar.KEYBOARD_NAVIGATION, 'Down');
                    this._searchBarService.navigate(SearchBarNavigationAction.Down);
                    this.shouldShowWatermark = true;
                    break;
                default:
                    this._searchBarService.keyCount++;
                    break;
            }
        }
    }

    public search(trigger: string): void {
        if (this.hasTermsOrSelection) {
            var submitEvent = new SearchSubmitEvent();
            submitEvent.isClick = trigger === 'click';
            submitEvent.suggestionPosition = this.currentTerms ? this._searchBarService.autoSuggestResultsIndex : this._searchBarService.searchHistoryResultsIndex;
            if (this._searchBarService.explicitSelection) {
                submitEvent.rawTerms = this._searchBarService.currentTerms;
                submitEvent.terms = this._searchBarService.explicitSelection.value;
                if (!this._searchBarService.searchHistoryResults.find((result) => result.value.toLowerCase() == this._searchBarService.explicitSelection.value.toLowerCase())) {
                    submitEvent.searchType = 1; //suggestion
                } else {
                    submitEvent.searchType = 3; //recent
                }
            } else {
                submitEvent.terms = this.currentTerms;
                submitEvent.rawTerms = this.currentTerms;
                submitEvent.searchType = 0; //text
            }
    
            submitEvent.keyCount = this._searchBarService.keyCount;
            
            this._analyticsService.sendSearchSubmitEvent(submitEvent);
            this._searchBarService.openSearch(this.currentTerms);
        }  
    }

    public clearExplicitSelection(): void {
        this._searchBarService.explicitSelection = null;
    }

    private autocomplete(): void {
        this._analyticsService.sendGenericEvent(AnalyticsEventsConfig.SearchBar.KEYBOARD_NAVIGATION, 'Autocomplete');

        if (this.watermark) {
            this._searchBarService.currentTerms = this.watermark;
        } else {
            this._searchBarService.currentTerms = this._searchBarService.explicitSelection.value;
        }
        this.manageChangedTerms$.next();
    }

    private getSearchHistory(): void {
        const value = new FSWebWorkerRequestValue();
        value.type = FSObjectStore.FSSearchHistory;
        this._worker.postMessage('get', FSObjectStore.FSSearchHistory);
    }

    private manageChangedTerms(): void {
        if (!this._searchBarService.searchHistoryResults)
            this.getSearchHistory();

        if (!this.hasValidCurrentTerms) {
            this._searchBarService.explicitSelection = null;
            this._searchBarService.implicitSelection = null;
            this._searchBarService.autosuggestResults = null;
        } else {
            var results = this._searchBarService.storedResults?.get(this._searchBarService.currentTerms);
            if (results) {
                this._searchBarService.setResults(results);
                this._loggingService.logDeveloperInfo("found existing AutoSuggestResults obj, returning", results);
            } else {
                this._autosuggestService.getAutoSuggestResults(this._searchBarService.currentTerms).subscribe((res) => {
                    if (res.success) {
                        this._searchBarService.setResults(res.value);
                    }
                });
            }
        }
    }

    public clearSearchText(): void {
        this._searchBarService.currentTerms = '';
        setTimeout(() => { this.focusInput.nativeElement.focus(); }, 0);
    }

    public shouldShowClearIcon(): boolean {
        if (this.value === '')
            return false;
        else
            return true;
    }

    private hasSearchTextFromQueryParams(): boolean {
        if (this.getSearchTextFromQueryParams() === '')
            return false;
        else
            return true;
    }

    private getSearchTextFromQueryParams(): string {
        var value = '';
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window?.location?.search);
            if (params.has("terms"))
                value = params.get('terms');
        }
        return value;
    }
}
