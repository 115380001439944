import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggingService } from '@shared/Services/logging.service';
import { RedirectService } from '@shared/Services/redirect.service';
import { PendingDTO, VerificationMethodDTO } from '../Models/LoginDTO';
import { RegistrationService } from '../Services/registration.service';

@Component({
    selector: 'shared-login-validation',
    templateUrl: './shared-login-validation.component.html'
})

export class SharedLoginValidationComponent implements OnInit {
    private _customerId: number;
    private _pendingDTO: PendingDTO = new PendingDTO();
    private _selectedMethod: VerificationMethodDTO;

    public formSubmitAttempt: boolean;
    public validationForm: UntypedFormGroup;
    public validationRequested: boolean;

    constructor(
        private _router: Router,
        private _builder: UntypedFormBuilder,
        private _redirect: RedirectService,
        private _registrationService: RegistrationService,
        private _loggingService: LoggingService) {

        this._customerId = 555555;
    }

    ngOnInit() {
        this._registrationService.getPendingDTO(this._customerId).subscribe((res) => {
            if (res.success) {
                this._pendingDTO = res.value;
                this._loggingService.logDeveloperInfo('got pending dto:', this._pendingDTO);
            }
        });

        this.validationForm = this._builder.group({
            method: ['', [Validators.required]]
        });
    }

    public get verificationMethods(): VerificationMethodDTO[] {
        return this._pendingDTO.verificationMethods;
    }

    public getVerificationId(method: VerificationMethodDTO): string {
        return method.verificationId;
    }

    public getMethod(method: VerificationMethodDTO): string {
        return method.method;
    }

    public getTarget(method: VerificationMethodDTO): string {
        return method.target;
    }

    public getToken(method: VerificationMethodDTO): string {
        return method.token;
    }

    public isFieldInvalid(field: string): boolean {
        return (
            (!this.validationForm.get(field).valid && this.validationForm.get(field).touched) ||
            (!this.validationForm.get(field).valid && this.formSubmitAttempt)
        );
    }

    public displayFieldCss(field: string) {
        return {
            'has-error': this.isFieldInvalid(field),
            'has-feedback': this.isFieldInvalid(field)
        };
    }

    public onSubmit(): void {
        this.formSubmitAttempt = true;

        if (this.validationForm.valid) {
            this.validationRequested = true;
            this.getSelectedMethod();

            this._registrationService.postValidationRequest(this._selectedMethod, this._customerId).subscribe((res) => {
                if (res.success) {
                    this._loggingService.logDeveloperInfo('validation request sent:', [res, this._selectedMethod]);
                }

                this.validationRequested = false;
            });

            if (this._router.url.includes('proceed')) {
                this._redirect.goToOrderLoginValidationRequestSent();
            } else {
                this._redirect.goToLoginValidationRequestSent();
            }
        }
    }

    private getSelectedMethod(): void {
        this._selectedMethod = this._pendingDTO.verificationMethods.find((x) => x.token === this.validationForm.controls['method'].value);

        if (!this._selectedMethod) {
            this._selectedMethod = new VerificationMethodDTO();
            this._selectedMethod.method = 'Have Fisheries approve';
            this._selectedMethod.token = 'haveFisheriesApprove';
        }
    }

}
