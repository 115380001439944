<a *ngIf="isBrowser" href="{{url | leadingslash}}" (throttledClick)="onClick($event)" [throttleTime]="1000" preventDoubleClick>
    <ng-container *ngTemplateOutlet="result"></ng-container>
</a>

<ng-container *ngIf="!isBrowser">
    <ng-container *ngTemplateOutlet="result"></ng-container>
</ng-container>

<ng-template #result>
    <div class="row">
        <div class="col-4">
            <div class="fs-special-pricing" *ngIf="isOnSale">On Sale</div>
            <cloudinary-image [url]="imageUrl"
                [alt]="imageAltTag"
                [type]="resultLine"
                [prerender]="position < 3"></cloudinary-image>
        </div>
        <div class="col-8">
            <h4 *ngIf="brand"><a href="{{brandUrl | leadingslash}}" onclick="event.stopPropagation();">{{brandName}}</a></h4>
            <h2> {{ name }} </h2>
            <p *ngIf="!shouldShowSingleSkuDetails && optionCount">Available in {{optionCount}} options</p>
            <p *ngIf="shouldShowSingleSkuDetails">SKU: {{sku}} | Item ID: {{itemId}}</p>
            <ng-content></ng-content>
            <availability [availability]="availability"></availability>
            <a class="fs-button-outline mt-4" role="button">Shop Now<span class="fs-icon fs-chevron-right" aria-hidden="true"></span></a>
        </div>
    </div>
</ng-template>