export class FSResult<T> {
    public success: boolean;
    public message: string;
    public errors: FSError[];
    public value: T;
    public expectations: object;
    public completedTransactions: string[];
}

export class FSError {
    public code: number;
    public message: string;
}

export class FSExpectations {
    public msToTimeout: number;
}

export class FSExpectationManager {
    public expectations: FSExpectations;
    public transactionId: string;
    public resync: (transactionId: string) => Promise<boolean>;
    public onError: () => void;
    public onSuccess?: () => void;
    public onProgress?: (progressPercentage: number) => void;
    public manageExpectations: (manager: FSExpectationManager) => void;
    public expectationsTimer;
    public progressInterval;
    public progressTick = 0;
    public progressPercentage = 0;
}

export class FSTransactionValues<T> {
    public initialValue: T;
    public requestedValue: T;
}
