<h2 class="fs-h-border">Success!</h2>

<div class="fs-note mb-4">
    <h3 class="pb-1">Welcome, {{name}}</h3>
    <ng-container *ngIf="login">
        <p>You have successfully logged in.</p>
    </ng-container>
    <ng-container *ngIf="registration">
        <p>You have successfully registered your Fisheries Supply account.</p>
    </ng-container>
</div>

<button (click)="startShopping()" class="fs-button-standard fs-button-spaced fs-button-100">Start Shopping <mat-spinner *ngIf="continueRequested" aria-hidden="true"></mat-spinner><span class="fs-icon fs-chevron-right" *ngIf="!continueRequested" aria-hidden="true"></span></button>
