
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoginDTO } from './Models/LoginDTO';
import { LoginService } from './Services/login.service';
import { PasswordValidationService } from './Services/password-validation.service';
import { RegistrationService } from './Services/registration.service';
import { SharedLoginForgotPasswordComponent } from './Components/shared-login-forgot-password.component';
import { SharedLoginRegistrationComponent } from './Components/shared-login-registration.component';
import { SharedLoginUsernameComponent } from './Components/shared-login-username.component';
import { SharedLoginValidationSentComponent } from './Components/shared-login-validation-sent.component';
import { SharedLoginValidationComponent } from './Components/shared-login-validation.component';
import { SharedModule } from '@shared-module';
import { IdentityService } from './Services/identity.service';
import { PasswordHintsComponent } from '@shared/Components/password-hints.component';

@NgModule({
  declarations: [
    SharedLoginRegistrationComponent,
    SharedLoginUsernameComponent,
    SharedLoginValidationComponent,
    SharedLoginValidationSentComponent,
    SharedLoginForgotPasswordComponent,
    PasswordHintsComponent
  ],
  imports: [ SharedModule.forRoot() ],
  exports: [
    SharedLoginRegistrationComponent,
    SharedLoginUsernameComponent,
    SharedLoginValidationComponent,
    SharedLoginValidationSentComponent,
    SharedLoginForgotPasswordComponent,
    PasswordHintsComponent
  ]
})
export class IdentityModule {
  static forRoot(): ModuleWithProviders<IdentityModule> {
    return {
      ngModule: IdentityModule,
      providers: [
        LoginDTO,
        LoginService,
        PasswordValidationService,
        RegistrationService,
        IdentityService
      ]
    }
  }
}

export function getOriginUrl(): string {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }
}