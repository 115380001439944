<div class="fsSkuAvailability row" *ngIf="hasAvailability">
    <div class="col-12">
        <div class="fsProductPrice__stock" [ngClass]="{'fsProductPrice__stock--in-stock' : availabilityLevelInStock, 'fsProductPrice__stock--limited' : availabilityLevelLimited}">
            {{availabilityMessage}}
        </div>

        <div class="fsSkuAvailability__lead-time" *ngIf="shouldShowLeadTimeMessage">{{leadTimeMessage}}</div>
    </div>
    <div class="col-12" *ngIf="shouldShowDetailedAvailabilityNote">
        <div class="fs-note--info" *ngIf="hasLimitedQuantityAvailable">
            <span *ngIf="isDiscontinuedByFisheries">This item is no longer sold by Fisheries.</span>
            <span *ngIf="isDiscontinuedByVendor">This item is no longer produced by the manufacturer.</span> Limited quantity is available.
        </div>
        <div class="fs-note--info" *ngIf="isDiscontinuedByFisheries && !hasLimitedQuantityAvailable">
            This item is no longer sold by Fisheries. Fisheries has selected the following products as good alternatives.
        </div>
        <div class="fs-note--info" *ngIf="isDiscontinuedByVendor && !isDiscontinuedByFisheries && !hasLimitedQuantityAvailable">
            This item is no longer produced by the manufacturer. Fisheries has selected the following products as good alternatives.
        </div>
    </div>
</div>

<mat-spinner *ngIf="!hasAvailability" aria-hidden="true"></mat-spinner>
