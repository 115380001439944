import { GenericEvent } from './AnalyticsModel';

export namespace AnalyticsEventsConfig {
    export abstract class EventHelpers {
        public static createEventDTO(eventName: string, category: string, action: string): GenericEvent {
            let eventDto = new GenericEvent(eventName);
            eventDto.category = category;
            eventDto.action = action;

            return eventDto;
        }
    }

    export class Checkout extends EventHelpers {
        public static readonly SELECT_SHIPPING_ADDRESS = EventHelpers.createEventDTO("checkout/address/selected", "Checkout", "Select Shipping Address");
        public static readonly SELECT_PAYMENT = EventHelpers.createEventDTO("checkout/payment/selected", "Checkout", "Select Payment");
        public static readonly SELECT_PICK_UP_TIME = EventHelpers.createEventDTO("checkout/pickup-time/selected", "Checkout", "Select Pick Up Time");
        public static readonly SELECT_SHIPPING_OPTION = EventHelpers.createEventDTO("checkout/shipping-option/selected", "Checkout", "Select Shipping Option");
    }

    export class SearchBar extends EventHelpers {
        public static readonly EXPLICIT_SELECTION = EventHelpers.createEventDTO("search-box/search/selected-explicitly", "Search Bar", "Explicit Selection");
        public static readonly NO_EXPLICIT_SELECTION = EventHelpers.createEventDTO("search-box/search/selected", "Search Bar", "No Explicit Selection");
        public static readonly KEYBOARD_NAVIGATION = EventHelpers.createEventDTO("search-box/navigation/key-pressed", "Search Bar", "Keyboard Navigation");
        public static readonly CLEAR_SEARCH_HISTORY = EventHelpers.createEventDTO("search-box/history/cleared", "Search Bar", "Clear Search History");
    }

    export class AutosuggestResult extends EventHelpers {
        public static readonly CLICK = EventHelpers.createEventDTO("search-suggestion/product/clicked", "Autosuggest Result", "Click");
        public static readonly HOVER = EventHelpers.createEventDTO("search-suggestion/product/hovered", "Autosuggest Result", "Hover");
    }

    export class Browse extends EventHelpers {
        public static readonly CHANGE_DISPLAY_STYLE = EventHelpers.createEventDTO("results/display-style/changed", "Browse", "Change Display Style");
        public static readonly CHANGE_DISPLAY_COUNT = EventHelpers.createEventDTO("results/display-count/changed", "Browse", "Change Display Count");
        public static readonly REFINE = EventHelpers.createEventDTO("results/refinements/changed", "Browse", "Refine");
        public static readonly CHANGE_SORT_ORDER = EventHelpers.createEventDTO("results/sort/changed", "Browse", "Change Sort Order");
    }

    export class Product extends EventHelpers {
        public static readonly COMPARE_PRODUCT_OPTIONS = EventHelpers.createEventDTO("product/comparison/opened", "Product", "Compare Product Options");
        public static readonly ADD_TO_CART = EventHelpers.createEventDTO("product/sku/added-to-cart", "Product", "Add to Cart");
    }

    export class QuickOrder extends EventHelpers {
        public static readonly ADD_ITEM_TO_QUICK_ORDER_SHEET = EventHelpers.createEventDTO("quickorder/items/added-to-sheet", "Cart", "Add Item to Quick Order Sheet");
        public static readonly TOGGLE_QUICK_ORDER = EventHelpers.createEventDTO("quickorder/mode/started", "Cart", "Toggle Quick Order");
        public static readonly IMPORT_FILE = EventHelpers.createEventDTO("quickorder/import/in-progress", "Cart", "Import File to Quick Order Sheet");
    }

    export class Cart extends EventHelpers {
        public static readonly TOGGLE_ALL_TAX = EventHelpers.createEventDTO("cart/tax/toggled", "Cart", "Toggle All Tax");
        public static readonly SET_PO_NUMBER = EventHelpers.createEventDTO("cart/po/changed", "Cart", "Set PO Number");
    }

    export class ShippingEstimator extends EventHelpers {
        public static readonly ESTIMATE_FROM_ZIP = EventHelpers.createEventDTO("shipping/zip/estimated", "Shipping Estimator", "Estimate from Zip");
        public static readonly ESTIMATE_FROM_ADDRESS = EventHelpers.createEventDTO("shipping/address/estimated", "Shipping Estimator", "Estimate from Address");
    }

    export class CartLine extends EventHelpers {
        public static readonly REMOVE = EventHelpers.createEventDTO("cart-line/remove/clicked", "Cart Line", "Remove");
        public static readonly UPDATE_QUANTITY = EventHelpers.createEventDTO("cart-line/quantity/changed", "Cart Line", "Update Quantity");
        public static readonly UPDATE_UOM = EventHelpers.createEventDTO("cart-line/uom/changed", "Cart Line", "Update Uom");
        public static readonly UPDATE_NOTE = EventHelpers.createEventDTO("cart-line/note/changed", "Cart Line", "Update Note");
        public static readonly UPDATE_TAXABLE = EventHelpers.createEventDTO("cart-line/tax/changed", "Cart Line", "Update Taxable");
        public static readonly QUANTITY_DROPDOWN = EventHelpers.createEventDTO("cart-line/quantity-dropdown/opened", "Cart Line", "Quantity Dropdown");
    }

    export class PromoCode extends EventHelpers {
        public static readonly FORM_TOGGLED = EventHelpers.createEventDTO("promo/form/toggled", "Promo Code", "Form Toggled");
        public static readonly ADD_SUCCESS = EventHelpers.createEventDTO("promo/code/added", "Promo Code", "Add Success");
        public static readonly INVALID_CODE = EventHelpers.createEventDTO("promo/code/invalid", "Promo Code", "Invalid Code");
        public static readonly REMOVE = EventHelpers.createEventDTO("promo/code/removed", "Promo Code", "Remove");
    }

    export class AddressBook extends EventHelpers {
        public static readonly REMOVE = EventHelpers.createEventDTO("address/remove/clicked", "Address Book", "Remove");
        public static readonly EDIT_REQUESTED = EventHelpers.createEventDTO("address/edit/started", "Address Book", "Edit Requested");
        public static readonly ADD_REQUESTED = EventHelpers.createEventDTO("address/add/started", "Address Book", "Add Requested");
        public static readonly ADD_CANCELED = EventHelpers.createEventDTO("address/add/canceled", "Address Book", "Add Canceled");
        public static readonly CHANGE_COUNTRY = EventHelpers.createEventDTO("address/country/changed", "Address Book", "Change Country");
        public static readonly TOGGLE_LINE_2 = EventHelpers.createEventDTO("address/line2/toggled", "Address Book", "Toggle Line 2");
    }

    export class CardBook extends EventHelpers {
        public static readonly REMOVE = EventHelpers.createEventDTO("card/removed/clicked", "Card Book", "Remove");
        public static readonly EDIT_REQUESTED = EventHelpers.createEventDTO("card/edit/started", "Card Book", "Edit Requested");
        public static readonly ADD_REQUESTED = EventHelpers.createEventDTO("card/add/started", "Card Book", "Add Requested");
        public static readonly ADD_CANCELED = EventHelpers.createEventDTO("card/add/canceled", "Card Book", "Add Canceled");
        public static readonly ADD_SUCCESS = EventHelpers.createEventDTO("card/add/success", "Card Book", "Add Success");
        public static readonly SELECT_BILLING_ADDRESS_SAME_AS_SHIPPING = EventHelpers.createEventDTO("card/add/use-shipping-address/toggled", "Card Book", "Select Billing Address Same as Shipping");
    }

    export class OrderHistory extends EventHelpers {
        public static readonly REQUESTED_PAST_5_YEARS = EventHelpers.createEventDTO("order-history/requested5years", "Order History", "Requested Past 5 Years");
    }

    export class Substitutes extends EventHelpers {
        public static readonly MODAL_OPENED = EventHelpers.createEventDTO("subsitutes/opened", "Substitutes", "Modal Opened");
        public static readonly SKU_SELECTED = EventHelpers.createEventDTO("substiutes/sku/selected", "Substitutes", "Sku Selected");
        public static readonly ADD_TO_CART = EventHelpers.createEventDTO("substitutes/sku/added-to-cart", "Substitutes", "Add to Cart");
    }

}
