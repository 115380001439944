import { Component, Input, OnInit } from '@angular/core';
import { IPriceable } from '@shared/Models/IPriceable';
import { PriceParameter } from '@shared/Models/PriceParameter';
import { Sku, SkuPrice, UnitOfMeasure } from '@shared/Models/SkuModel';
import { FSTransactionValues } from '@shared/Models/FSResultModel';

@Component({
    selector: 'sku-pricing-scope,[sku-pricing-scope]',
    template: `<ng-content></ng-content>`,
    providers: [PriceParameter],
    standalone: true
})

export class SkuPricingScopeComponent implements OnInit {
    @Input() priceable: IPriceable;
    @Input() sku: Sku;
    @Input() skuPrice: SkuPrice;
    @Input() selectedUOM: UnitOfMeasure;
    @Input() qtyUpdated: boolean;

    constructor(private _priceParameter: PriceParameter) { }

    ngOnInit() {
        this.registerPriceParameterValues();
    }

    ngOnChanges() {
        this.registerPriceParameterValues();
    }

    public registerPriceParameterValues(): void {
        let transactionValues = new FSTransactionValues<number>();
        transactionValues.initialValue = this.priceable.quantity || 1;
        transactionValues.requestedValue = this.priceable.quantity || 1;

        this._priceParameter.updateQuantity(transactionValues);

        if (!this._priceParameter.sku) {
            this._priceParameter.sku = this.sku;
        }

        if (this.selectedUOM) {
            this._priceParameter.uom = this.selectedUOM;
        } else {
            this._priceParameter.uom = this.sku.unitsOfMeasure ? this.sku.unitsOfMeasure[0] : null;
        }

        if (!this._priceParameter.skuPrice) {
            this._priceParameter.skuPrice = this.skuPrice;
        }

        if (!this._priceParameter.activePrice && this._priceParameter.uom && this._priceParameter.skuPrice) {
            this._priceParameter.activePrice = this._priceParameter.skuPrice.getCustomerPriceForQuantity
                (this._priceParameter.quantity * this._priceParameter.uom.unitSize);
        }

        if (this.priceable) {
            this.priceable.registerPriceParameterValuesOnInit(this._priceParameter);
        }
    }

}
