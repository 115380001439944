export class LogDTO {
    public message: string;
    public level: number;
    public data: LogData;
}

export class LogData {
    public userId: number;
    public callStack: string;
    public additionalData: string[];
}

export enum LogLevel {
    INFORMATION,
    WARNING,
    ERROR,
}