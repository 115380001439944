<div class="fsUsernameComponent">
    <h2 class="fs-h-border">Login</h2>

    <h3>Choose Account</h3>
    <div class="fsUsernameComponent__email-wrap">
        <div *ngFor="let login of loginHistoryResults; let i = index" class="d-flex">
            <button (click)="goToPassword(login)">{{getEmail(login)}}</button>
            <button (click)="removeEmail(login)"
                    [disabled]="isRemoveInProgress(login)">
            <mat-spinner *ngIf="isRemoveInProgress(login)" aria-hidden="true"></mat-spinner><span class="fs-icon fs-close" aria-label="Close" *ngIf="!isRemoveInProgress(login)"></span>
            </button>
        </div>
    </div>
    <hr />
    <div class="fs-inline-links">
        <a class="fs-classic-link" (click)="goToEmail()">Register</a>
        <small>or</small>
        <a class="fs-classic-link" (click)="goToEmail()">Login with a Different Email</a>
    </div>
</div>
