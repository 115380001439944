import { Injectable } from '@angular/core';

@Injectable()
export class PasswordValidationService {
    public password: string;
    public confirmPassword: string;
    public passwordHints: PasswordHint[] = new Array<PasswordHint>();

    constructor() {
        const isLongEnough = new PasswordHint();
        isLongEnough.message = 'Password should be 8 or more characters';
        isLongEnough.name = 'isLongEnough';
        this.passwordHints.push(isLongEnough);

        const hasLowerCase = new PasswordHint();
        hasLowerCase.message = 'Password should have one or more lower case letters';
        hasLowerCase.name = 'hasLowerCase';
        this.passwordHints.push(hasLowerCase);

        const hasUpperCase = new PasswordHint();
        hasUpperCase.message = 'Password should have one or more upper case letters';
        hasUpperCase.name = 'hasUpperCase';
        this.passwordHints.push(hasUpperCase);

        const hasNumber = new PasswordHint();
        hasNumber.message = 'Password should have one or more numbers';
        hasNumber.name = 'hasNumber';
        this.passwordHints.push(hasNumber);

        const hasSpecialCharacter = new PasswordHint();
        hasSpecialCharacter.message = 'Password should have one or more special characters';
        hasSpecialCharacter.name = 'hasSpecialCharacter';
        this.passwordHints.push(hasSpecialCharacter);
    }

    public validatePassword(): void {
        this.checkIsLongEnough();
        this.checkHasLowerCase();
        this.checkHasUpperCase();
        this.checkHasNumber();
        this.checkHasSpecialCharacter();
    }

    public get passwordsMatch(): boolean {
        if (this.confirmPassword === this.password) {
            return true;
        } else {
            return false;
        }
    }

    public get passwordIsValid(): boolean {
        if (this.passwordHints.filter((x) => x.isValid === false).length === 0) {
            return true;
        } else {
            return false;
        }
    }

    public checkIsLongEnough(): void {
        if (this.password.length > 7) {
            this.passwordHints.find((x) => x.name === 'isLongEnough').isValid = true;
        } else {
            this.passwordHints.find((x) => x.name === 'isLongEnough').isValid = false;
        }
    }

    public checkHasLowerCase(): void {
        if (/[a-z]/.test(this.password)) {
            this.passwordHints.find((x) => x.name === 'hasLowerCase').isValid = true;
        } else {
            this.passwordHints.find((x) => x.name === 'hasLowerCase').isValid = false;
        }
    }

    public checkHasUpperCase(): void {
        if (/[A-Z]/.test(this.password)) {
            this.passwordHints.find((x) => x.name === 'hasUpperCase').isValid = true;
        } else {
            this.passwordHints.find((x) => x.name === 'hasUpperCase').isValid = false;
        }
    }

    public checkHasNumber(): void {
        if (/[\d]/.test(this.password)) {
            this.passwordHints.find((x) => x.name === 'hasNumber').isValid = true;
        } else {
            this.passwordHints.find((x) => x.name === 'hasNumber').isValid = false;
        }
    }

    public checkHasSpecialCharacter(): void {
        if (/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(this.password)) {
            this.passwordHints.find((x) => x.name === 'hasSpecialCharacter').isValid = true;
        } else {
            this.passwordHints.find((x) => x.name === 'hasSpecialCharacter').isValid = false;
        }
    }

}

export class PasswordHint {
    public isValid: boolean;
    public name: string;
    public message: string;
}
