import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FSResult } from '@shared/Models/FSResultModel';

@Injectable()
export class RetireCartService {
    private static readonly __currentCartMagicString: string = "currentcart";
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }
    
    public retireCart(cartId: string = RetireCartService.__currentCartMagicString): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(`${this._origin}/api/cart/retire/currentcart`, null);
    }
}
