import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';
import { SharedModule } from '@shared-module';
import { AppMailingListSignupComponent } from './Components/app-mailing-list-signup.component';
import { MailingListService } from '@Mailing-List-Signup/Services/mailing-list.service';

@NgModule({
  declarations: [AppMailingListSignupComponent],
  imports: [
    BrowserModule,
    SharedModule.forRoot()
  ],
  bootstrap: [AppMailingListSignupComponent],
  providers: [MailingListService]
})
export class MailingListSignupModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const MailingListSignupElement = createCustomElement(AppMailingListSignupComponent, { injector: this.injector });
    customElements.get('app-mailing-list-signup') || customElements.define('app-mailing-list-signup', MailingListSignupElement);  
  }
 }
