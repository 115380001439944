import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FSResult } from '@shared/Models/FSResultModel';
import { SubstituteData, SubstitutesDTO } from '@shared/Models/SubstituteModel';
import { Sku } from '@shared/Models/SkuModel';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';

@Injectable()
export class SubstituteService {
    private _origin: string;

    public substitutes: Map<string, SubstituteData[]>;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public getVisibleSubstitutes(sku: Sku): SubstituteData[] {
        let subsForSku = this.substitutes?.get(sku.invMastUid.toString());
        return this.filteredSubs(sku, subsForSku);
    }

    public filteredSubs(sku: Sku, substitutes: SubstituteData[]): SubstituteData[] {
        if (substitutes) {
            var filteredSubs = substitutes.filter((sub) => this.shouldDisplay(sku, sub));

            return filteredSubs?.sort((a, b) => {
                let aStock = a.sku.availability?.getDispositionForQuantityAndUnitSize(1, a.sku.unitsOfMeasure[0].unitSize).stockLevel;
                let bStock = b.sku.availability?.getDispositionForQuantityAndUnitSize(1, b.sku.unitsOfMeasure[0].unitSize).stockLevel;
                return (aStock === bStock) ? 0 : aStock > bStock ? 1 : -1;
            });
        }
    }

    public shouldDisplay(sku: Sku, substitute: SubstituteData): boolean {
        if (sku) {
            if (sku.availability.isPurchasable) {
                if (!sku.availability.isInStock) {
                    return substitute.sku.availability?.isInStock;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return substitute.sku.availability?.isInStock;
        }
    }

    public getTypedSubMap(res: [string, SubstituteData[]]): Map<string, SubstituteData[]> {
        if (res) {
            let subMap = new Map();

            for (let substitute of Array.from(Object.entries(res))) {
                let k = substitute[0];
                let v = plainToClass<SubstituteData, Object[]>(SubstituteData, <any>substitute[1])
                subMap.set(k, v);
            }
    
            return subMap;
        } 
    }
    
    public getSubstitutes(commaSeparatedInvMastUids: string): Observable<FSResult<SubstitutesDTO>> {
        return this.http.get<FSResult<SubstitutesDTO>>(this._origin + `/api/substitute?q=` + commaSeparatedInvMastUids);
    }
}
