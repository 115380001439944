<div class="fsForgotPasswordComponent">
    <h2 class="fs-h-border">Reset Password</h2>

    <ng-container *ngIf="!resetPasswordFailed">
        <mat-spinner *ngIf="!resetPasswordEmailSent" aria-label="Requesting reset"></mat-spinner>

        <ng-container *ngIf="resetPasswordEmailSent">
            <div class="fs-note mb-3">An email was sent to <b>{{email}}</b> with instructions on how to reset your password.</div>

            <button (click)="closeModal()" class="fs-button-standard fs-button-100 fs-button-spaced">Close</button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="resetPasswordFailed">
        <div class="fs-note mb-3">In order to keep your logins in sync, we are only allowing passwords to be reset on <a href="https://www.fisheriessupply.com/" class="d-inline" target="_blank">www.fisheriessupply.com</a> (not on beta). If you'd like to reset your password, please reset it on the regular site, and it will automatically change on beta within 2 hours.</div>

        <button (click)="closeModal()" class="fs-button-standard fs-button-100 fs-button-spaced">Close</button>
    </ng-container>
    
    <hr class="fs-hr-secondary" />
    <div class="fs-inline-links">
        <a class="fs-classic-link" (click)="goToEmail()">Register</a>
        <small>or</small>
        <a class="fs-classic-link" (click)="goToEmail()">Login with a Different Email</a>
    </div>

</div>
