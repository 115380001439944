import { maketype } from "@shared/Common/ClassTransformerHelpers";
import { FSTransactionValues } from "@shared/Models/FSResultModel";
import { Link } from "@shared/Models/sharedModels";
import { Type } from "class-transformer";
import { Subject } from "rxjs";
import { IPriceable } from "./IPriceable";
import { PriceParameter } from "./PriceParameter";

export class UnitOfMeasure {
    public isDefaultUnit: boolean;
    public displayName: string;
    public unitName: string;
    public unitSize: number;
}

export class SortableImage {
    public sortOrder: number;
    public key: string;
    public altTag: string;
    public url: string;
}

export class Price {
    public forQuantity: number;
    public baseUnitRegularPrice: number;
    public baseUnitPrice: number;
}

export class Specification {
    public id: number;
    public key: string;
    public value: string;
    public sortOrder: number;
}

export class AttributeValue {
    public value: string;
    public attributeId: number;
    public sortOrder: number;
}

export class Availability {
    public quantityAvailable: number;
    public stockable: boolean;
    public sellable: boolean;
    public discontinuedByVendor: boolean;
    public discontinuedByFisheries: boolean;
    public inStockMessage: string;
    public partialStockMessage: string;
    public partialStockLeadTime: string;
    public inStockLeadTime: string;
    public inStockDisposition: string;
    public outOfStockMessage: string;
    public outOfStockLeadTime: string;
    public outOfStockDisposition: string;
    public expectedDate: Date;
    public canExpedite: boolean;
    public expediteDispositions: string[];
    public stockUrgencyThreshold: number;
    public stockUrgencyMessage: string;
    public isPurchasable: boolean;
    public isInStock: boolean;
    public stockLevelUrgent: boolean;
    public hasLimitedQuantityAvailable: boolean;
    public isDiscontinuedByVendor: boolean;

    // logic below is repeated in backend - ensure it remains in sync

    public getDispositionForQuantityAndUnitSize(quantityRequested: number, unitSize: number): ExtendedDisposition {
        if (unitSize < 1)
            unitSize = 1;

        var quantityAvailableAtUnitSize = this.quantityAvailable / unitSize;

        var extendedDisposition = new ExtendedDisposition();

        if (!this.isInStock || quantityAvailableAtUnitSize < 1) {
            extendedDisposition.disposition = this.outOfStockDisposition;
            extendedDisposition.message = this.messageWithRealStockValue(this.outOfStockMessage, quantityAvailableAtUnitSize);
            extendedDisposition.stockLevel = StockLevel.OutOfStock;
            return extendedDisposition;
        }

        if (quantityAvailableAtUnitSize < quantityRequested) {
            extendedDisposition.disposition = this.outOfStockDisposition;
            extendedDisposition.message = this.messageWithRealStockValue(this.partialStockMessage, quantityAvailableAtUnitSize);
            extendedDisposition.stockLevel = StockLevel.PartialStock;
            return extendedDisposition;
        }

        if (this.stockLevelUrgent) {
            extendedDisposition.disposition = this.outOfStockDisposition;
            extendedDisposition.message = this.messageWithRealStockValue(this.stockUrgencyMessage, quantityAvailableAtUnitSize);
            extendedDisposition.stockLevel = StockLevel.InStock;
            return extendedDisposition;
        }

        extendedDisposition.disposition = this.inStockDisposition;
        extendedDisposition.message = this.messageWithRealStockValue(this.inStockMessage, quantityAvailableAtUnitSize);
        extendedDisposition.stockLevel = StockLevel.InStock;
        return extendedDisposition;
    }

    private messageWithRealStockValue(message: string, quantityAvailableAtUnitSize: number = this.quantityAvailable): string {
        return message.replace("{0}", Math.floor(quantityAvailableAtUnitSize).toString());
    }
}

export class ExtendedDisposition {
    public disposition: string;
    public message: string;
    public stockLevel: StockLevel;
}

export enum StockLevel {
    InStock,
    PartialStock,
    OutOfStock
}

export class Substitutes {

}

export class RequiredItems {
    public sku: Sku[];
    public reason: string;
}

export class ItemPrice {
    @Type(maketype(Price))
    public pricing: Map<string, Price>;

    public invMastUid: number;

    public toJSON(): any {
        const obj: any = {};

        for (const pf in this) {
            if (!pf.startsWith('pricing')) {
                obj[pf] = this[pf];
            } else {
                obj[pf] = Array.from(<any>this[pf]).reduce((obj, [key, value]) => {
                    obj[<any>key] = value;
                    return obj;
                }, {});
            }
        }
        return obj;
    }
}

export class SkuPrice {
    public baseUnitListPrice: number;
    public baseUnitStreetPrice: number;
    public baseUnitRegularPrice: number;

    public skuId: number;

    @Type(maketype(ItemPrice))
    public streetPrice: ItemPrice;

    @Type(maketype(ItemPrice))
    public regularPrice: ItemPrice;

    @Type(maketype(ItemPrice))
    public customerPrice: ItemPrice;

    private static lookupPriceForQuantity(pricing: Map<string, Price>, qty: number, regularPricing?: Map<string, Price>): Price {
        let candidate: Price;

        for (const [key, price] of pricing) {
            if (price?.forQuantity <= qty) {
                candidate = price;
            } else {
                break;
            }
        }

        if (regularPricing) {
            for (const [key, regularPrice] of regularPricing) {
                if (regularPrice?.forQuantity <= qty) {
                    candidate.baseUnitRegularPrice = regularPrice.baseUnitPrice;
                } else {
                    break;
                }
            }
        }

        return candidate;
    }

    public getStreetPriceForQuantity(qty: number): Price {
        let skuPrice;

        if (this.streetPrice) {
            skuPrice = SkuPrice.lookupPriceForQuantity(this.streetPrice.pricing, qty, this.regularPrice?.pricing);
        }

        return skuPrice;
    }

    public getCustomerPriceForQuantity(qty: number): Price {
        let skuPrice : Price;

        if (this.customerPrice) {
            skuPrice = SkuPrice.lookupPriceForQuantity(this.customerPrice.pricing, qty, this.regularPrice?.pricing);
        }

        return skuPrice;
    }
}

export class Sku implements IPriceable {
    // these properties should not be serialized by JSON.stringify()
    private static excludedJsonProperties: Set<string> = new Set<string>(['priceParameter']);

    private _priceParameter: PriceParameter;

    public id: number;
    public productId: string;
    public itemId: string;
    public invMastUid: number;
    public metaDescription: string;
    public metaTitle: string;
    public name: string;
    public shortCode: string;
    public supplierPartNumber: string;
    public quantity: number;
    public imageUrl: string;
    public imageAltTag: string;
    public url: string;
    public fullUrl: string;
    public brandName: string;
    public brand: Link; 
    public categories: Link[];
    public prop65Message: string;

    @Type(maketype(SortableImage))
    public images: SortableImage[];

    @Type(maketype(Specification))
    public specifications: Specification[];

    @Type(maketype(UnitOfMeasure))
    public unitsOfMeasure: UnitOfMeasure[];

    @Type(maketype(Availability))
    public availability: Availability;

    @Type(maketype(AttributeValue))
    public attributeValues: AttributeValue[];

    public discontinued: boolean;

    @Type(maketype(Substitutes))
    public substitutes: Substitutes;

    @Type(maketype(RequiredItems))
    public requiredItems: RequiredItems;

    public whatsInTheBox: string[];

    public get priceParameter(): PriceParameter {
        return this._priceParameter;
    }

    public uomChanged$ = new Subject<FSTransactionValues<UnitOfMeasure>>();

    public uomUpdated(transactionValues: FSTransactionValues<UnitOfMeasure>): void {
        this.uomChanged$.next(transactionValues);
    }

    public qtyChanged$ = new Subject<FSTransactionValues<number>>();

    public qtyUpdated(transactionValues: FSTransactionValues<number>): void {
        this.qtyChanged$.next(transactionValues);
    }

    public registerPriceParameterValuesOnInit(priceParameter: PriceParameter) {
        this._priceParameter = priceParameter;

        this.priceParameter.uomChanged$.subscribe((transactionValues) => this.uomUpdated(transactionValues));
        this.priceParameter.qtyChanged$.subscribe((transactionValues) => this.qtyUpdated(transactionValues));
    }

    // make a clean self json object without unwanted properties.
    public toJSON(): any {
        const obj: any = {};

        for (const pf in this) {
            if (!pf.startsWith('_') && !Sku.excludedJsonProperties.has(pf)) {
                obj[pf] = this[pf];
            }
        }
        return obj;
    }
}