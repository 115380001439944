import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, QueryList, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InputFocusedEvent } from '@shared/Models/AnalyticsModel';
import { IResult } from '@shared/Models/ResultModel';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { LoggingService } from '@shared/Services/logging.service';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';
declare var $: any;
declare var Waypoint: any;

@Directive({
    selector: '[phoneNumber]'
})
export class phoneNumberFormat implements OnInit {
    @Input() phoneNumber: UntypedFormControl;
    phoneNumberLength = 0;

    ngOnInit() {
        this.phoneNumber = this.phoneNumber;
        this.phoneNumber.valueChanges.subscribe((val) => {
            this.format(val);
        });
    }

    private format(value: string): void {
        if (value) {
            value = value.replace(/\D/g, '');

            if (value.length !== this.phoneNumberLength) {
                this.phoneNumberLength = value.length;
                if (value.length >= 1 && value.length < 4) {
                    value = '(' + value;
                } else if (value.length >= 4 && value.length < 7) {
                    value = '(' + value.substr(0, 3) + ') ' + value.substr(3);
                } else if (value.length >= 7 && value.length < 11) {
                    value = '(' + value.substr(0, 3) + ') ' + value.substr(3, 3) + '-' + value.substr(6);
                } else if (value.length === 11) {
                    value = value.substr(0, 1) + ' (' + value.substr(1, 3) + ') ' + value.substr(4, 3) + '-' + value.substr(7);
                } else if (value.length === 12) {
                    value = value.substr(0, 2) + ' (' + value.substr(2, 3) + ') ' + value.substr(5, 3) + '-' + value.substr(8);
                }
                this.phoneNumber.setValue(value);
            }
        }
    }
}

@Directive({
    selector: '[cardNumberFormatted]'
})
export class cardNumberFormat implements OnInit {
    @Input() cardNumberFormatted: UntypedFormControl;
    cardLength = 0;

    ngOnInit() {
        this.cardNumberFormatted = this.cardNumberFormatted;
        this.cardNumberFormatted.valueChanges.subscribe((val) => {
            this.format(val);
        });
    }

    private format(value: string): void {
        if (value) {
            if (value.length !== this.cardLength) {
                this.cardLength = value.length;

                value = value.replace(/\D/g, '').substr(0, 18);

                // if is Amex
                if (value.substring(0, 2) === '34' || value.substring(0, 2) === '37') {
                    if (value.length <= 4) {
                        value = value;
                    } else if (value.length >= 4 && value.length <= 10) {
                        value = value.substr(0, 4) + ' ' + value.substr(4, 6);
                    } else if (value.length >= 10) {
                        value = value.substr(0, 4) + ' ' + value.substr(4, 6) + ' ' + value.substr(10, 5);
                    }

                } else {
                    if (value.length <= 4) {
                        value = value;
                    } else if (value.length >= 4 && value.length <= 8) {
                        value = value.substr(0, 4) + ' ' + value.substr(4, 4);
                    } else if (value.length >= 8 && value.length <= 12) {
                        value = value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4);
                    } else if (value.length >= 12 && value.length <= 16) {
                        value = value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) + ' ' + value.substr(12, 4);
                    } else if (value.length >= 16) {
                        value = value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) + ' ' + value.substr(12, 4) + ' ' + value.substr(16, 2);
                    }
                }

                this.cardNumberFormatted.setValue(value);
            }
        }
    }
}

@Directive({
    selector: '[appAutoFocus]'
})
export class appAutoFocus implements AfterContentInit {
    constructor(private _element: ElementRef) { }

    ngAfterContentInit() {
        setTimeout(() => { this._element.nativeElement.focus() }, 0);
    }
}

@Directive({
    selector: '[appShellRender]'
})
export class AppShellRenderDirective implements OnInit {
    constructor(private _viewContainer: ViewContainerRef, private _templateRef: TemplateRef<any>,
        @Inject(PLATFORM_ID) private _platformId) { }

    ngOnInit() {
        if (isPlatformServer(this._platformId)) {
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
            this._viewContainer.clear();
        }
    }
}

@Directive({
    selector: '[appShellNoRender]'
})
export class AppShellNoRenderDirective implements OnInit {
    constructor(private _viewContainer: ViewContainerRef, private _templateRef: TemplateRef<any>,
        @Inject(PLATFORM_ID) private _platformId) { }

    ngOnInit() {
        if (isPlatformServer(this._platformId)) {
            this._viewContainer.clear();
        } else {
            this._viewContainer.createEmbeddedView(this._templateRef);
        }
    }
}

@Directive({
    selector: '[cloudinaryResponsive]'
})
export class CloudinaryResponsiveDirective implements OnInit {
    @Input() parentWidth: number;

    constructor(private _element: ElementRef) { }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            fromEvent(window, 'resize').pipe(debounceTime(250)).subscribe(() => {
                if (this._element.nativeElement.getBoundingClientRect().width < this.parentWidth) {
                    this._element.nativeElement.srcset = this._element.nativeElement.srcset;
                }
            });
        }
    }
}

@Directive({
    selector: '[preloadSlides]'
})
export class PreloadSlidesDirective {
    private _activeIndex: number;
    private _slides: QueryList<ElementRef>;

    @Input() public set activeIndex(value: number) {
        this._activeIndex = value;

        if (this.slides) {
            this.updateClasses();
        }
    }

    @Input() public set slides(value: QueryList<ElementRef>) {
        this._slides = value;

        if (this.slides) {
            this.updateClasses();
        }
    }

    constructor(
        private _renderer: Renderer2
    ) { }

    public get activeIndex(): number {
        return this._activeIndex;
    }

    public get slides(): QueryList<ElementRef> {
        return this._slides;
    }

    public get slideCount(): number {
        return this.slides ? this.slides.length : 0;
    }

    public get previousIndex(): number {
        if (this.activeIndex === 0) {
            return this.slideCount - 1;
        } else {
            return this.activeIndex - 1;
        }
    }

    public get nextIndex(): number {
        if (this.activeIndex === this.slideCount - 1) {
            return 0;
        } else {
            return this.activeIndex + 1;
        }
    }

    private updateClasses(): void {
        this.slides.toArray().forEach(({ nativeElement }) => {
            this._renderer.removeClass(nativeElement, 'prev');
            this._renderer.removeClass(nativeElement, 'next');
        });

        if (this.nextIndex !== this.activeIndex) {
            this._renderer.addClass(this.slides.toArray()[this.nextIndex].nativeElement, 'next');
        }

        if (this.previousIndex !== this.activeIndex) {
            this._renderer.addClass(this.slides.toArray()[this.previousIndex].nativeElement, 'prev');
        }
    }
}

@Directive({
    selector: '[productImpression]',
    standalone: true
})
export class ProductImpressionDirective implements AfterViewInit {
    @Input() result: IResult;
    @Input() list: string;
    @Input() position: number;
    @Input() fsls: string;
    @Input() fslsid: string;
    @Input() fslsp: string[];

    constructor(
        private _element: ElementRef,
        private _analyticsService: AnalyticsService,
        @Inject(PLATFORM_ID) private _platformId
    ) { }

    ngAfterViewInit() {
        this.hasIntersectionObserver ? this.waitToSendImpression() : this.sendImpression();
    }

    private get hasIntersectionObserver(): boolean {
        if (typeof window !== 'undefined') {
            return window && 'IntersectionObserver' in window;
        }
    }

    private waitToSendImpression(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    this.sendImpression();
                    observer.unobserve(this._element.nativeElement);
                }
            });
        }, { threshold: [.5] });

        observer.observe(this._element.nativeElement);
    }

    private sendImpression(): void {
        if (this.result)
            this._analyticsService.sendProductImpression(this.result, this.list, this.position, this.fsls, this.fslsid, this.fslsp);
    }
}


@Directive({
    selector: '[impression]',
    standalone: true
})
export class ImpressionDirective implements AfterViewInit {
    @Input() impressionKey : string;
    @Input() impressionId : string;
    @Input() impressionParams : object;

    constructor(
        private _element: ElementRef,
        private _analyticsService: AnalyticsService,
        @Inject(PLATFORM_ID) private _platformId
    ) { }

    ngAfterViewInit() {
        this.hasIntersectionObserver ? this.waitToSendImpression() : this.sendImpression();
    }

    private get hasIntersectionObserver(): boolean {
        if (typeof window !== 'undefined') {
            return window && 'IntersectionObserver' in window;
        }
    }

    private waitToSendImpression(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    this.sendImpression();
                    observer.unobserve(this._element.nativeElement);
                }
            });
        }, { threshold: [.5] });

        observer.observe(this._element.nativeElement);
    }

    private sendImpression(): void {
        this._analyticsService.sendImpression(this.impressionKey, this.impressionId, this.impressionParams);
    }
}

@Directive({
    selector: '[blockPaste]'
})
export class BlockPasteDirective {
    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }
}

@Directive({
    selector: '[viewMode]'
})
export class ViewModeDirective {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
    selector: '[editMode]'
})
export class EditModeDirective {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
    selector: '[preventDefaultClick]',
    standalone: true
})
export class PreventDefaultClickDirective {
    @HostListener('click', ['$event']) onClick($event: Event) {
        $event.preventDefault();
    }
}

@Directive({
    selector: '[stopPropagation]',
    standalone: true
})
export class StopPropagationDirective {
    @HostListener('click', ['$event']) onClick($event: Event) {
        $event.stopPropagation();
    }
}

@Directive({
    selector: '[preventDefaultTab]'
})
export class PreventDefaultTabDirective {
    @HostListener('keydown.tab', ['$event']) onTab($event: Event) {
        $event.preventDefault();
    }
}

@Directive({
    selector: '[fsSticky]'
})
export class FSStickyDirective implements AfterViewInit, OnDestroy{
    private _topSticky: any;

    @Input() stickyTopClass: string;
    @Input() stickyOffsetCalculator : () => number = () => 0;
    

    constructor(
        @Inject(PLATFORM_ID) private _platformId) { }

    ngAfterViewInit() {
        if(isPlatformBrowser(this._platformId)){   
            if(!this._topSticky){
                this._topSticky = new Waypoint.Sticky({
                    element: $(`.${this.stickyTopClass}`),
                    offset: this.stickyOffsetCalculator,
                    enabled: true
                });
            }
        }
    }

    ngOnDestroy(): void {
        if(this._topSticky){
            this._topSticky.destroy();
            this._topSticky = null;
        }
    }
}

@Directive({
    selector: '[preventDoubleClick]',
    standalone: true
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
    private _clicks = new Subject();
    private _subscription: Subscription;

    @Input() public throttleMs = 500;

    @Output('throttledClick') sendThrottledClick = new EventEmitter<boolean>();

    @HostListener('click', ['$event'])
    public clickEvent(event): void {
        event.preventDefault();
        event.stopPropagation();
        this._clicks.next(event.ctrlKey);
    }

    ngOnInit() {
        this._subscription = this._clicks.pipe(throttleTime(this.throttleMs)).subscribe(
            (event: boolean) => this.emitThrottledClick(event)
        );
    }

    ngOnDestroy() {
        this._subscription?.unsubscribe();
    }

    public emitThrottledClick(isCtrlClick: boolean): void {
        this.sendThrottledClick.emit(isCtrlClick);
    }
}

@Directive({
    selector: '[inputFocusEvent]'
})
export class FocusEventDirective {
    constructor(
        @Inject(PLATFORM_ID)
        private _platformId,
        private _element: ElementRef,
        private _analyticsService: AnalyticsService
    ) { }

    @Input()
    public inputEventId: string;

    @Input()
    public dataContext: object;

    @HostListener("focus")
    onInputFocus() {
        if (isPlatformBrowser(this._platformId)){
            let evt = new InputFocusedEvent();
            evt.InputId = this.inputEventId ?? "unknown";
            evt.DataContext = this.dataContext ?? {};

            this._analyticsService.sendInputFocusedEvent(evt);
        };
    }
}
