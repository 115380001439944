import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ChangeCustomerPageModel, ChangeCustomerService } from '@Change-Customer/Services/change-customer.service';
import { plainToClass } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RedirectService } from '@shared/Services/redirect.service';
import { CartAccessor } from '@shared/Services/cart.accessor';
import { IdentityService } from '@shared/Modules/Identity/Services/identity.service';
import { RetireCartService } from '@shared/Services/retire-cart.service';

@Component({
    selector: 'app-change-customer',
    templateUrl: './app-change-customer.component.html'
})

export class AppChangeCustomerComponent {
    private _modalSubscription: Subscription;

    public changeCustomerModal: BsModalRef;
    public modalOpen: boolean;
    public availableCustomers: [string, string][];
    public changeCustomerRequested: boolean;
    public changeCustomerFailed: boolean;

    @ViewChild('changeCustomerModal', { read: TemplateRef, static: false }) public templateRef: TemplateRef<any>;

    constructor(
        @Inject('changeCustomerPageModel') changeCustomerPageModel: string,
        private _changeCustomerPageModel: ChangeCustomerPageModel,
        private _changeCustomerService: ChangeCustomerService,
        private _modalService: BsModalService,
        private _redirectService: RedirectService,
        private _identityService: IdentityService,
        private _cartAccessor: CartAccessor,
        private _retireCartService: RetireCartService
      ) {
          let changeCustomer = plainToClass(ChangeCustomerPageModel, changeCustomerPageModel);
          this._changeCustomerPageModel.setInitialValues(changeCustomer);
          this.availableCustomers = Object.entries(this._changeCustomerPageModel.availableCustomers);
      }

    ngOnDestroy() {
        if (this._modalSubscription) {
            this._modalSubscription.unsubscribe();
        }
    }

    public get currentCustomerName(): string {
        return this._changeCustomerPageModel.currentCustomerName;
    }
    
    public changeCustomer(customerId: string): void {
        this.changeCustomerRequested = true;

        if (this._cartAccessor.currentCart?.cartLines?.length > 0) {
            if (window.confirm(this._identityService.cartRetireWarning)) {
               this.proceedToRetireAndChangeCustomer(customerId);
            }
        } else {
            this.proceedToRetireAndChangeCustomer(customerId);
        }
        
    }

    public closeModal(): void {
        this.modalOpen = false;
        this.changeCustomerModal.hide();
    }

    public openModal(template: TemplateRef<any>): void {
        this.modalOpen = true;

        this._modalSubscription = this._modalService.onHide.subscribe((res) => {
            if (res === 'backdrop-click' || res === 'esc') {
                if (this._modalService.config.class.includes('fsChangeCustomerModal')) {
                    this.closeModal();

                    if (this._modalSubscription) {
                        this._modalSubscription.unsubscribe();
                    }
                }
            }
        });

        this.changeCustomerModal = this._modalService.show(template, { class: 'fsChangeCustomerModal' });
    }

    private proceedToRetireAndChangeCustomer(customerId: string): void {
        this._retireCartService.retireCart().subscribe(
            res => {
                if (res.value === true) {
                    this.proceedToChangeCustomer(customerId);
                } else {
                    this.changeCustomerFailed = true;
                    this.changeCustomerRequested = false;
                }
            },
            error => {
                this.changeCustomerFailed = true;
                this.changeCustomerRequested = false;
            }
        );
    }

    private proceedToChangeCustomer(customerId: string): void {
        this._changeCustomerService.changeCustomer(customerId).subscribe(
            res => {
                if (res?.success && res.value) {
                    this._redirectService.refreshPage();
                } else {
                    this.changeCustomerFailed = true;
                }

                this.changeCustomerRequested = false;
            },
            error => {
                this.changeCustomerFailed = true;
                this.changeCustomerRequested = false;
            }
        );
    }

}
