<div class="fsChangeCustomer--current" (click)="openModal(changeCustomerModal)">
    <span>Shopping On:</span>
    <b>{{currentCustomerName}}</b>
    
    <a>Change Customer</a>
</div>

<ng-template #changeCustomerModal>
    <span class="fs-icon fs-close" aria-label="Close" (click)="closeModal()"></span>

    <div class="modal-body">
        <h2 class="fs-h-border">Avaliable Customers</h2>
        <p>Select the customer you wish to change to.</p>
        <p><b>Your cart will be cleared as a result of changing customers.</b></p>
        <div class="my-4">
            <ng-container *ngFor="let customer of availableCustomers">
                <button class="my-2 fs-button-standard fs-button-100" (click)="changeCustomer(customer[0])">
                    <span>Change to&nbsp;<b>{{customer[1]}}</b></span> <mat-spinner *ngIf="changeCustomerRequested" aria-label="Changing customer"></mat-spinner>
                </button>
                <small class="fs-red mb-4 d-block" *ngIf="changeCustomerFailed">Your request to change customers failed. Please try again, or contact us if the issue persists.</small>
            </ng-container>   
        </div>
        <a class="fsChangeCustomer--close" (click)="closeModal()"><small>No, thanks. Stay on <b>{{currentCustomerName}}</b></small></a>
    </div>
</ng-template>
