<ul class="navbar-nav--cart" *ngIf="hasCartDTO">
    <li class="nav-item nav-item--cart" [ngClass]="{'dropdown' : shouldShowFlyout}" (mouseleave)="allowFlyoutClose()">

        <a *ngIf="!shouldShowMobileHeaderFlyout || !shouldShowMobileExperience" class="nav-link dropdown-toggle" (mouseenter)="openCartFlyout()" href="order/cart" aria-haspopup="true" aria-expanded="false" aria-label="Cart">
            <span class="fs-icon fs-cart" aria-hidden="true"></span>
            <span class="nav-item--cart__count" *ngIf="hasLineItems"><span [ngClass]="cartLineCountClass">{{cartLineCount}}</span></span><span class="nav-item--cart__text" *ngIf="hasLineItems" [innerHtml]="cartLineCountMessage"></span>
        </a>

        <a *ngIf="shouldShowMobileHeaderFlyout && shouldShowMobileExperience" class="nav-link dropdown-toggle" (click)="toggleMobileFlyout()" aria-haspopup="true" aria-expanded="false" aria-label="Cart">
            <span class="fs-icon fs-cart" aria-hidden="true"></span>
            <span class="nav-item--cart__count" *ngIf="hasLineItems"><span [ngClass]="cartLineCountClass">{{cartLineCount}}</span></span><span class="nav-item--cart__text" *ngIf="hasLineItems" [innerHtml]="cartLineCountMessage"></span>
        </a>

        <ul class="dropdown-menu show fsHeaderCartFlyout" *ngIf="shouldShowFlyout && (shouldShowMobileHeaderFlyout || !shouldShowMobileExperience)" [ngClass]="{'fsHeaderCartFlyout__sidebar' : shouldShowStickySidebarFlyout, 'fsHeaderCartFlyout__mobile' : shouldShowMobileHeaderFlyout}" [@flyoutAnimation]="shouldShowStickySidebarFlyout" (mouseenter)="keepFlyoutOpen()">
            <span class="fs-icon fs-close" (click)="flyoutBeingHovered = false; closeFlyout()"></span>

            <ng-container *ngIf="shouldShowNotificationDetails">
                <li>
                    <h2 class="fs-green" [innerHtml]="cartChangeMessage"></h2>
                </li>

                <ng-container *ngIf="shouldShowLineChanges">
                    <li class="nav-item dropdown-item nav-item--cart__item" *ngFor="let change of changedLines">
                        <sku-pricing-scope [sku]="getChangedLineItemSku(change)" [skuPrice]="getChangedLineItemSkuPrice(change)" [priceable]="getChangedLineItem(change)" [selectedUOM]="getChangedLineItemUOM(change)">
                            <div class="row">
                                <div class="col-12">
                                    <div class="fsHeaderCartFlyout__line-message" [innerHtml]="getLineChangeMessage(change)"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <cloudinary-image [url]="getChangedImageUrl(change)" [alt]="getChangedImageAltTag(change)" [type]="imageTypeLine" (click)="goToChangedSku(change)"></cloudinary-image>
                                </div>
                                <div class="col-6 px-0">
                                    <h4 *ngIf="brandName">{{brandName}}</h4>
                                    <sku-details [sku]="getChangedLineItemSku(change)" [displayBrand]="false" [displaySku]="false" [displayItemId]="false" (click)="goToChangedSku(change)"></sku-details>
                                </div>
                                <div class="col-3 pl-1" *ngIf="!hmpActive">
                                    <line-total [lineItem]="getChangedLineItem(change)"></line-total>
                                </div>
                            </div>

                        </sku-pricing-scope>
                    </li>
                    <li class="nav-item dropdown-item">
                        <hr class="mt-0" />
                    </li>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="shouldShowCartDetails">
                <li>
                    <h2 [innerHtml]="cartLineFlyoutMessage"></h2>
                </li>

                <li class="nav-item dropdown-item nav-item--cart__item" *ngFor="let line of recentLineItems; let i = index">
                    <a href="{{getSkuUrl(line)}}">
                        <sku-pricing-scope [sku]="getLineItemSku(line)" [skuPrice]="getLineItemSkuPrice(line)" [priceable]="line" [selectedUOM]="getLineItemUOM(line)">

                            <div class="row">
                                <div class="col-3">
                                    <cloudinary-image [url]="getImageUrl(line)" [alt]="getImageAltTag(line)" [type]="imageTypeLine" (click)="goToSku(line)"></cloudinary-image>
                                </div>
                                <div class="col-6 px-0">
                                    <h4 *ngIf="getLineItemBrand(line)">{{getLineItemBrand(line)}}</h4>
                                    <sku-details [sku]="getLineItemSku(line)" [displayBrand]="false" [displaySku]="false" [displayItemId]="false" (click)="goToSku(line)"></sku-details>
                                </div>
                                <div class="col-3 pl-1" *ngIf="!hmpActive">
                                    <line-total [lineItem]="line"></line-total>
                                </div>
                            </div>

                        </sku-pricing-scope>
                    </a>
                </li>

                <li class="nav-item dropdown-item fsHeaderCartFlyout__remaining" *ngIf="shouldShowRemainingItemMessage">
                    <a href="order/cart">{{remainingItemMessage}} &raquo;</a>
                </li>
                <li class="nav-item dropdown-item fsHeaderCartFlyout__total" *ngIf="hasLineItems && !hmpActive">
                    <div class="row">
                        <div class="col-6">Subtotal</div>
                        <div class="col-6">{{subtotal | currency}}</div>
                    </div>
                </li>
            </ng-container>

            <li class="nav-item dropdown-item fsHeaderCartFlyout__buttons">
                <div class="row">
                    <div class="col-6" *ngIf="!onCartPage">
                        <a class="fs-button-outline fs-button-spaced fsHeaderCartFlyout__cart-button" (click)="goToCart()" href="order/cart" role="button" preventDefaultClick>View Cart</a>
                    </div>
                    <div [ngClass]="onCartPage ? 'col-12' : 'col-6'">
                        <a class="fs-button-standard fs-button-spaced fsHeaderCartFlyout__checkout-button" [ngClass]="{'fs-button-100' : onCartPage}" href="order/checkout" role="button" (click)="goToCheckout()" preventDefaultClick>Checkout <span *ngIf="!checkoutClicked" class="fs-icon fs-chevron-right" aria-hidden="true"></span><mat-spinner *ngIf="checkoutClicked" aria-hidden="true"></mat-spinner></a>
                    </div>
                </div>
            </li>
        </ul>

    </li>
</ul>
