import { AppSearchBarComponent } from '@Search-Bar/Components/app-search-bar.component';
import { AutosuggestHighlightComponent } from '@Search-Bar/Components/autosuggest-highlight.component';
import { AutosuggestResultComponent } from '@Search-Bar/Components/autosuggest-result.component';
import { AutosuggestResultsComponent } from '@Search-Bar/Components/autosuggest-results.component';
import { SearchBarResultWrapperComponent } from '@Search-Bar/Components/search-bar-result-wrapper.component';
import { SearchBoxComponent } from '@Search-Bar/Components/search-box.component';
import { SearchHistoryResultsComponent } from '@Search-Bar/Components/search-history-results.component';
import { SearchHistoryWrapperComponent } from '@Search-Bar/Components/search-history-wrapper.component';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared-module';
import { SearchBarService } from '@Search-Bar/Services/search-bar.service';

@NgModule({
  declarations: [
    AppSearchBarComponent,
    SearchBarResultWrapperComponent,
    SearchBoxComponent,
    SearchHistoryResultsComponent,
    SearchHistoryWrapperComponent,
    AutosuggestResultComponent,
    AutosuggestResultsComponent,
    AutosuggestHighlightComponent
  ],
  imports: [
    BrowserModule,
    SharedModule.forRoot()
  ],
  bootstrap: [AppSearchBarComponent],
  providers: [SearchBarService]
})
export class SearchBarModule {
  constructor(private injector: Injector) { }

  ngDoBootstrap() {
    const SearchBarElement = createCustomElement(AppSearchBarComponent, { injector: this.injector });
    customElements.get('search-bar-app') || customElements.define('search-bar-app', SearchBarElement);
  }
}
