import { Component } from '@angular/core';
import { RedirectService } from '@shared/Services/redirect.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoginDTO } from '../Models/LoginDTO';
import { IdentityService } from '../Services/identity.service';

@Component({
    selector: 'shared-login-forgot-password',
    templateUrl: './shared-login-forgot-password.component.html'
})

export class SharedLoginForgotPasswordComponent {
    public resetPasswordEmailSent: boolean;
    public resetPasswordFailed: boolean;

    constructor(
        private _loginDto: LoginDTO,
        private _redirect: RedirectService,
        private _router: Router,
        private _modalService: BsModalService,
        private _identityService: IdentityService) { }

    ngOnInit() {
        this._identityService.generatePasswordReset(this._loginDto.email).subscribe((res) => {
            if (res) {
                if (res.success) {
                    if (res.value === true) {
                        this.resetPasswordEmailSent = true;
                    } else {
                        this.resetPasswordFailed = true;
                    }
                }
            }
        });
    }

    public get email(): string {
        return this._loginDto.email;
    }

    public goToEmail(): void {
        if (this._router.url.includes('proceed')) {
            this._redirect.goToOrderLoginEmail();
        } else {
            this._redirect.goToLoginEmail();
        }
    }

    public closeModal(): void {
        this._modalService.hide(1);
    }
}
