import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FSResult } from '@shared/Models/FSResultModel';
import { plainToClass } from 'class-transformer';
import { ShopperDTO } from '../Models/IdentityModel';
import { LoginHistoryResult, ValidateEmailRequest, ValidateEmailResponse, LoginDTO } from '../Models/LoginDTO';
import { GuestDetails } from '../Models/RegistrationDTO';

@Injectable()
export class LoginService {
    private _origin: string;

    public get loginHistoryResults(): LoginHistoryResult[] {
        if (this.fisheriesModule) {
            return this.fisheriesModule.loginHistoryResults;
        }
    }
    public set loginHistoryResults(value: LoginHistoryResult[]) {
        if (this.fisheriesModule) {
            (<any>window).fisheriesModule.loginHistoryResults = value;
        }
    }

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public get fisheriesModule(): any {
        if (typeof window !== 'undefined') {
            return (<any>window).fisheriesModule;
        } else {
            return {};
        }
    }

    public checkEmail(data: ValidateEmailRequest): Observable<FSResult<ValidateEmailResponse>> {
        return this.http.post<FSResult<ValidateEmailResponse>>(this._origin + `/api/identity/validateemail`, JSON.stringify(data));
    }

    public validateUser(data: LoginDTO): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(this._origin + `/api/identity/signin/`, data.toJSON());
    }

    public async checkLoggedInStatusAsync(): Promise<boolean> {
        if (typeof window !== 'undefined') {
            const shopperResult = await this.http.get<FSResult<ShopperDTO>>(this._origin + `/api/identity/currentshopper`).toPromise();
            if (shopperResult.success && shopperResult.value && shopperResult.value.currentPermissions && shopperResult.value.currentPermissions.customerId)
                return true;
        } 
        return false;
    }

    public getGuestDetails(): Observable<FSResult<GuestDetails>> {
        return this.http.get<FSResult<GuestDetails>>(this._origin + `/api/guest`);
    }

    public async checkIfGuestDetailsAreCompleteAsync(): Promise<boolean> {
        if (typeof window !== 'undefined') {
            const guestResult = await this.getGuestDetails().toPromise();
            if (guestResult.value) {
                let guestObj = plainToClass(GuestDetails, guestResult.value);
                if (guestObj.isValid)
                    return true;
            }
            return false;     
        }
        return false;     
    }
}
