export enum FSObjectStore {
    FSLoginHistory = 'fsLoginHistory',
    FSSearchHistory = 'fsSearchHistory',
    FSUserIP = 'fsUserIP'
}

export class FSWebWorkerRequest {
    public action: string;
    public value: FSWebWorkerRequestValue;
}

export class FSWebWorkerRequestValue {
    public date = new Date();
    public type: string;
    public dto: any;
}
