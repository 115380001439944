import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewRef } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { CartDTO, CartResultPayloadDTO, FSCartDTOResult, LineChanges, LineItem } from '@shared/Models/CartModel';
import { CloudinaryImageType } from '@shared/Models/CloudinaryImageModel';
import { FSResult } from '@shared/Models/FSResultModel';
import { Sku, SkuPrice, UnitOfMeasure } from '@shared/Models/SkuModel';
import { CartAccessor } from '@shared/Services/cart.accessor';
import { LoggingService } from '@shared/Services/logging.service';
import { SettingsAccessor } from '@shared/Services/settings.accessor';
import { plainToClass } from 'class-transformer';
import { Subscription, timer } from 'rxjs';
import { calculateFSLSUrl } from '@shared/Services/fsls-calculator';

@Component({
    selector: 'app-header-cart-flyout',
    templateUrl: './app-header-cart-flyout.component.html',
    animations: [
        trigger('flyoutAnimation', [
            transition('void => false', [
                style({ opacity: 0 }),
                animate(100, style({ opacity: 1 }))
            ]),
            transition('false => void', [
                style({ 'margin-right': '15px' }),
                animate(100, style({ opacity: 0, 'margin-right': '15px' }))
            ]),
            transition('void => true', [
                style({ opacity: 0, right: '-70px' }),
                animate(300, style({ opacity: 1, right: '0px' }))
            ]),
            transition('true => void', [
                style({ 'margin-right': '0px' }),
                animate(300, style({ opacity: 0, right: '-70px', 'margin-right': '0px' }))
            ])
        ])
    ]
})

export class AppHeaderCartFlyoutComponent implements OnInit, OnDestroy {
    private _cartChangedDelegate: (message: FSResult<CartResultPayloadDTO>) => void;
    private _cartDTO: CartDTO;
    private _cartSubscription: Subscription;
    private _connection: signalR.HubConnection;
    private _clientId: number;
    private _flyoutTimerSubscription: Subscription;
    private _cartUrl = 'order/cart';
    private _checkoutUrl = 'order/checkout';
    private _preCheckoutUrl = 'order/cart/proceed';

    public cartChanges: CartResultPayloadDTO;
    public shouldShowFlyout = false;
    public shouldShowNotificationDetails = false;
    public shouldShowCartDetails = false;
    public flyoutBeingHovered = false;
    public durationFlyoutOpen: number;
    public shouldShowStickySidebarFlyout = false;
    public checkoutClicked = false;

    @HostListener('document:click', ['$event'])
    public clickOutside(event): void {
        if (!this._element.nativeElement.contains(event.target)) {
            this.closeFlyout();
        }
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        if (!this.shouldShowStickySidebarFlyout && window.scrollY > 80) {
            this.shouldShowStickySidebarFlyout = true;
        } else if (this.shouldShowStickySidebarFlyout && window.scrollY < 40) {
            this.shouldShowStickySidebarFlyout = false;
        }
    }

    constructor(
        private _cartAccessor: CartAccessor,
        private _element: ElementRef,
        private _changeDetector: ChangeDetectorRef,
        private _loggingService: LoggingService,
        @Inject('clientHub') clientHub: signalR.HubConnection,
        @Inject('clientId') clientId: any,
        private _settingsAccessor: SettingsAccessor) {

        this._connection = clientHub;
        this._clientId = clientId;

        this._cartChangedDelegate = function (message: FSResult<CartResultPayloadDTO>) {
            if (message.value.action !== 'merge') {
                const updatedCartDTO: FSCartDTOResult = plainToClass(FSCartDTOResult, message);

                this._cartDTO = updatedCartDTO.value.cart;

                this._loggingService.logDeveloperInfo('%cupdating cart:' + '%c kafka - header cart flyout', updatedCartDTO.value.cart);

                // to catch changes that shouldnt open the flyout (for now)
                if (!(updatedCartDTO.value.action === '' && updatedCartDTO.value.lineChanges.length === 0)
                    && !(updatedCartDTO.value.action === 'update-lines' && updatedCartDTO.value.lineChanges.length === 0)
                    && !(updatedCartDTO.value.action === 'update-lines-taxable-all'
                        && updatedCartDTO.value.lineChanges.length === 0)
                    && this.atLeastOneChangeReturned(updatedCartDTO.value.lineChanges)
                    && (!this.onCartPage || this._clientId != updatedCartDTO.value.clientId)) {

                    this.openLineChangesFlyout(updatedCartDTO.value);
                }
            }
        }.bind(this);
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            this._connection.on('cart-change', this._cartChangedDelegate);
        }

        this._cartSubscription = this._cartAccessor.cartObservable?.subscribe((data) => {
            this._cartDTO = data;
            this.safeDetectChanges();
        });
    }

    ngOnDestroy() {
        if (typeof window !== 'undefined') {
            this._connection.off('cart-change', this._cartChangedDelegate);
        }

        if (this._flyoutTimerSubscription) {
            this._flyoutTimerSubscription.unsubscribe();
        }

        if (this._cartSubscription) {
            this._cartSubscription.unsubscribe();
        }
    }
    
    public get hmpActive(): boolean {
        return this._settingsAccessor.hmpActive;
    }

    public get pageWidth(): number {
        if (typeof window !== 'undefined') {
            return window.innerWidth;
        }
    }

    public get hasCartDTO(): boolean {
        return this._cartDTO != null;
    }

    public get shouldShowMobileExperience(): boolean {
        return this.pageWidth < 768;
    }

    public get shouldShowMobileHeaderFlyout(): boolean {
        return this._settingsAccessor.headerCartFlyOutMobile === 'enabled';
    }

    public get cartLineCount(): number {
        if (this._cartDTO.cartLines) {
            return this._cartDTO.cartLines.length;
        }
    }

    public get cartLineCountClass(): string {
        if (this.cartLineCount > 99) {
            return `nav-item--cart__count--3-digits`;
        } else if (this.cartLineCount > 9) {
            return `nav-item--cart__count--2-digits`;
        } else {
            return ``;
        }
    }

    public get cartLineCountMessage(): string {
        if (this.cartLineCount === 1) {
            return `item`;
        } else {
            return `items`;
        }
    }

    public get cartLineFlyoutMessage(): string {
        if (this.cartLineCount === 1) {
            return `You have ${this.cartLineCount} item in your cart`;
        } else {
            return `You have ${this.cartLineCount} items in your cart`;
        }
    }

    public get changedLines(): LineChanges[] {
        return this.cartChanges.lineChanges;
    }

    public get hasLineItems(): boolean {
        return this.cartLineCount > 0;
    }

    public get recentLineItems(): LineItem[] {
        if (!this.shouldShowMobileExperience) {
            const last4 = this._cartDTO.cartLines.slice(-4);
            return last4.reverse();
        } else {
            const last2 = this._cartDTO.cartLines.slice(-2);
            return last2.reverse();
        }

    }

    public get shouldShowRemainingItemMessage(): boolean {
        if (!this.shouldShowMobileExperience) {
            return this.cartLineCount - 4 > 1;
        } else {
            return this.cartLineCount - 2 > 1;
        }
    }

    public get remainingItemMessage(): string {
        if (!this.shouldShowMobileExperience) {
            if (this.cartLineCount - 4 === 1) {
                return `and ${this.cartLineCount - 4} more item`;
            } else {
                return `and ${this.cartLineCount - 4} more items`;
            }
        } else {
            if (this.cartLineCount - 2 === 1) {
                return `and ${this.cartLineCount - 2} more item`;
            } else {
                return `and ${this.cartLineCount - 2} more items`;
            }
        }

    }

    public get subtotal(): number {
        return this.taxableSubtotal + this.nonTaxableSubtotal;
    }

    public get taxableSubtotal(): number {
        return this._cartDTO.taxableSubtotal;
    }

    public get nonTaxableSubtotal(): number {
        return this._cartDTO.nonTaxableSubtotal;
    }

    public get msFromMinDuration(): number {
        return (3 - this.durationFlyoutOpen) * 1000;
    }

    public get imageTypeLine(): CloudinaryImageType {
        return CloudinaryImageType.line;
    }

    public get onCartPage(): boolean {
        if (typeof window !== 'undefined') {
            return location.href.includes('order/cart');
        }
    }

    public openLineChangesFlyout(cartChanges: CartResultPayloadDTO): void {
        this.cartChanges = cartChanges;
        this.shouldShowFlyout = true;
        this.shouldShowNotificationDetails = true;
        this.shouldShowCartDetails = false;

        this.safeDetectChanges();
        setTimeout(() => this.closeFlyout(), 6000);

        const timer$ = timer(0, 1000);
        this._flyoutTimerSubscription = timer$.subscribe((s) => this.durationFlyoutOpen = s);
    }

    public openCartFlyout(): void {
        if (this.cartLineCount > 0) {
            this.shouldShowFlyout = true;
            this.shouldShowCartDetails = true;
            this.shouldShowNotificationDetails = false;
        }
    }

    public closeFlyout(): void {
        if (!this.flyoutBeingHovered) {
            this.shouldShowFlyout = false;
            this.shouldShowNotificationDetails = false;
            this.shouldShowCartDetails = false;
            this.safeDetectChanges();
            if (this._flyoutTimerSubscription) {
                this._flyoutTimerSubscription.unsubscribe();
            }
        } else if (this.shouldShowMobileExperience) {
            this.shouldShowFlyout = false;
            this.shouldShowNotificationDetails = false;
            this.shouldShowCartDetails = false;
        }
    }

    public toggleMobileFlyout(): void {
        if (this.cartLineCount > 0) {
            this.shouldShowFlyout = !this.shouldShowFlyout;
            this.shouldShowCartDetails = true;
            this.shouldShowNotificationDetails = false;
        } else {
            this.goToCart();
        }
    }

    public keepFlyoutOpen(): void {
        this.shouldShowFlyout = true;
        this.flyoutBeingHovered = true;
    }

    public allowFlyoutClose(): void {
        if (!this.shouldShowMobileExperience) {
            this.flyoutBeingHovered = false;

            if (this.msFromMinDuration > 0 && this.shouldShowNotificationDetails) {
                setTimeout(() => this.closeFlyout(), this.msFromMinDuration);
            } else {
                this.closeFlyout();
            }
        }
    }

    public get cartChangeMessage(): string {
        if (this.cartChanges.action === 'add-lines') {
            if (this.cartChanges.lineChanges.length === 1) {
                return `${this.cartChanges.lineChanges.length} item added to your cart.`;
            } else {
                return `${this.cartChanges.lineChanges.length} items added to your cart.`;
            }
        } else if (this.cartChanges.action === 'remove-lines') {
            if (this.cartChanges.lineChanges.length === 1) {
                return `${this.cartChanges.lineChanges.length} item removed from your cart.`;
            } else {
                return `${this.cartChanges.lineChanges.length} items removed from your cart.`;
            }
        } else if (this.cartChanges.action === 'update-lines') {
            if (this.cartChanges.lineChanges.length === 1) {
                return `${this.cartChanges.lineChanges.length} item updated in your cart.`;
            } else {
                return `${this.cartChanges.lineChanges.length} items updated in your cart.`;
            }
        } else if (this.cartChanges.action === 'update-lines-taxable-all') {
            if (this.cartChanges.cart.cartLines.filter((x) => x.taxable === true).length > 0) {
                return `All cart items are now set to <b>taxable</b>.`;
            } else {
                return `All cart items are now set to <b>non-taxable</b>.`;
            }
        }
    }

    public getOldLineQuanity(change: LineChanges): number {
        if (change.oldLine) {
            return change.oldLine.quantity;
        } else {
            return 0;
        }
    }

    public getNewLineQuanity(change: LineChanges): number {
        if (change.newLine) {
            return change.newLine.quantity;
        } else {
            return 0;
        }
    }

    public getLineChangeMessage(change: LineChanges): string {
        const difference = this.getOldLineQuanity(change) - this.getNewLineQuanity(change);

        // quantity
        if (change.quantityChanged) {
            if (difference > 0) {
                return `Quantity decreased to ${this.getNewLineQuanity(change)} (<b>${difference} removed</b>)`;
            } else {
                return `Quantity increased to ${this.getNewLineQuanity(change)} (<b>${difference * -1} added</b>)`;
            }
        } else if (change.noteChanged) {
            return `<b>Note updated</b>: ${change.newLine.note}`;
        } else if (change.taxChanged) {
            if (change.newLine.taxable) {
                return `Item is now <b>taxable</b>`;
            } else {
                return `Item is now <b>non-taxable</b>`;
            }
        } else if (change.uomChanged) {
            return `Unit of measure changed to <b>${change.newLine.selectedUnitOfMeasure.displayName}</b>`;
        } else if (change.lineAdded) {
            return `${difference * -1} added`;
        } else if (change.lineRemoved) {
            return `Item removed`;
        }

    }

    public get shouldShowLineChanges(): boolean {
        if (this.cartChanges) {
            if (this.cartChanges.action === 'update-lines-taxable-all') {
                return false;
            } else {
                return true;
            }
        }
    }

    public atLeastOneChangeReturned(changes: LineChanges[]): boolean {
        let changeCount = 0;
        for (const change of changes) {
            for (const key in change) {
                if (change[key] === true) {
                    changeCount++;
                }
            }
        }

        return changeCount > 0;
    }

    public getImageUrl(line: LineItem): string {
        return line.sku.imageUrl;
    }
    
    public getImageAltTag(line: LineItem): string {
        return line.sku.imageAltTag;
    }

    public getChangedImageUrl(change: LineChanges): string {
        if (change.newLine) {
            return change.newLine.sku.imageUrl;
        } else {
            return change.oldLine.sku.imageUrl;
        }
    }
    
    public getChangedImageAltTag(change: LineChanges): string {
        if (change.newLine) {
            return change.newLine.sku.imageAltTag;
        } else {
            return change.oldLine.sku.imageAltTag;
        }
    }

    public getLineItemSku(line: LineItem): Sku {
        return line.sku;
    }

    public getLineItemSkuPrice(line: LineItem): SkuPrice {
        return line.skuPrice;
    }

    public getLineItemBrand(line: LineItem): string {
        return line.sku.brandName;
    }

    public getLineItemUOM(line: LineItem): UnitOfMeasure {
        return line.selectedUnitOfMeasure;
    }

    public getLineItemImage(line: LineItem): string {
        return line.productImageUrl;
    }

    public getChangedLineItem(change: LineChanges): LineItem {
        if (change.newLine) {
            return change.newLine;
        } else {
            return change.oldLine;
        }
    }

    public getChangedLineItemSku(change: LineChanges): Sku {
        if (change.newLine) {
            return change.newLine.sku;
        } else {
            return change.oldLine.sku;
        }
    }

    public getChangedLineItemSkuPrice(change: LineChanges): SkuPrice {
        if (change.newLine) {
            return change.newLine.skuPrice;
        } else {
            return change.oldLine.skuPrice;
        }
    }

    public getChangedLineItemUOM(change: LineChanges): UnitOfMeasure {
        if (change.newLine) {
            return change.newLine.selectedUnitOfMeasure;
        } else {
            return change.oldLine.selectedUnitOfMeasure;
        }
    }

    public goToCheckout(): void {
        this.checkoutClicked = true;
        location.href = this._checkoutUrl;
    }

    public goToCart(): void {
        location.href = this._cartUrl;
    }

    public goToSku(line: LineItem): void {
        location.href = this.getSkuUrl(line);
    }

    public getSkuUrl(line: LineItem): string {
        const url = line.sku.fullUrl || line.sku.url;
        return calculateFSLSUrl(url,"cart", "chf", null);
    }

    public goToChangedSku(change: LineChanges): void {
        if (change.newLine) {
            location.href = this.getSkuUrl(change.newLine); //change.newLine.sku.fullUrl || change.newLine.sku.url;
        } else {
            location.href = this.getSkuUrl(change.oldLine); //change.oldLine.sku.fullUrl || change.oldLine.sku.url;
        }
    }

    private safeDetectChanges(): void {
        const vr: ViewRef = (this._changeDetector as ViewRef);
        if (vr && !vr.destroyed) {
            this._changeDetector.detectChanges();
        }
    }

}
