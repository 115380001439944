import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDTO, LoginHistoryResult } from '@shared/Modules/Identity/Models/LoginDTO';
import { FSObjectStore } from '@shared/Models/WebWorkerModel';
import { LoginService } from '@shared/Modules/Identity/Services/login.service';
import { RedirectService } from '@shared/Services/redirect.service';
import { WebWorkerService } from '@shared/Services/web-worker.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './app-login.component.html'
})

export class AppLoginComponent implements OnDestroy {
    private _loginHistorySubscription: Subscription;
    private _loginModalSubscription: Subscription;

    public loginModalOpen = false;
    public loginModal: BsModalRef;

    @ViewChild('loginModal', { read: TemplateRef, static: false }) public templateRef: TemplateRef<any>;

    constructor(
        private _modalService: BsModalService,
        private _redirect: RedirectService,
        private _loginService: LoginService,
        private _loginDto: LoginDTO,
        private _route: ActivatedRoute,
        private _worker: WebWorkerService,
        private _router: Router) {
    }

    ngOnDestroy() {
        if (this._loginHistorySubscription) {
            this._loginHistorySubscription.unsubscribe();
        }

        if (this._loginModalSubscription) {
            this._loginModalSubscription.unsubscribe();
        }
    }

    public get loginHistoryResults(): LoginHistoryResult[] {
        return this._loginService.loginHistoryResults;
    }

    public get routeNotSet(): boolean {
        return !this._route.firstChild;
    }

    public closeLoginModal(): void {
        this.loginModalOpen = false;

        this.loginModal.hide();

        this.redirectBasedOnLoginHistory(this.loginHistoryResults);

        if (this._router.url.includes('success')) {
            this._redirect.refreshPage();
        }
    }

    public openLoginModal(template: TemplateRef<any>): void {
        this.loginModalOpen = true;

        this.subscribeToLoginHistory();

        this._worker.postMessage('get', FSObjectStore.FSLoginHistory);

        this._loginModalSubscription = this._modalService.onHide.subscribe((res) => {
            if (res === 'backdrop-click' || res === 'esc') {
                if (this._modalService.config.class.includes('fsLoginModal')) {
                    this.closeLoginModal();

                    if (this._loginModalSubscription) {
                        this._loginModalSubscription.unsubscribe();
                    }
                }
            }
        });

        setTimeout(() => {
            if (!this._loginService.loginHistoryResults) {
                this._redirect.goToLoginEmail();

                if (this._loginHistorySubscription) {
                    this._loginHistorySubscription.unsubscribe();
                }
            }
        }, 1000);

        this.loginModal = this._modalService.show(template, { class: 'fsLoginModal' });
    }

    private subscribeToLoginHistory(): void {
        if (!this._loginHistorySubscription) {
            this._loginHistorySubscription = this._worker.fsLoginHistorySubscription$.subscribe((res) => {
                if (res) {
                    this.redirectBasedOnLoginHistory(res);

                    if (this._loginHistorySubscription) {
                        this._loginHistorySubscription.unsubscribe();
                    }
                }
            });
        }
    }

    private redirectBasedOnLoginHistory(res: LoginHistoryResult[]): void {
        if (res !== this._loginService.loginHistoryResults) {
            this._loginService.loginHistoryResults = res;
        }

        if (res.length > 0) {
            if (this.loginHistoryResults.length === 1) {
                this._loginDto.email = this.loginHistoryResults[0].email;
                this._loginDto.firstName = this.loginHistoryResults[0].firstName;

                this._redirect.goToLoginPassword();
            } else {
                this._redirect.goToLoginUsername();
            }
        } else {
            this._redirect.goToLoginEmail();
        }
    }
}
