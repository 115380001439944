import { maketype } from '@shared/Common/ClassTransformerHelpers';
import { Type } from 'class-transformer';

export class PhoneContact {
    phone: string;
}

export class EmailContact {
    email: string;
}

export class PickUpContact {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;

    public get isValid(): boolean {
        return !!this.firstName && !!this.lastName && (!!this.phoneNumber || !!this.email);
    }
}

export class NotificationSettings {
  notificationsEnabled: boolean;
  phoneList: PhoneContact[];
  emailList: EmailContact[];

  @Type(maketype(PickUpContact))
  contact: PickUpContact;
}
