import { Component, Input } from '@angular/core';
import { ClickableImage, ClickableImageContainer } from '@shared/Models/sharedModels';

@Component({
    selector: 'clickable-image-container',
    templateUrl: './clickable-image-container.component.html'
})
export class ClickableImageContainerComponent {
    @Input() data: ClickableImageContainer;

    // to do: slider vs static settings
    public get configTypeIsSlider(): boolean {
        return false;
    }

    // to do: slider vs static settings
    public get configTypeIsStatic(): boolean {
        return true;
    }

    public get clickableImageList(): ClickableImage[] {
        return this.data.clickableImageList;
    }

    public get clickableImageContainerTitle(): string { 
        return this.data.clickableImageContainerTitle;
    }

    public get maxClickableImageCount(): number { 
        return this.data.maxClickableImageCount;
    }

    public get clickableImageTextColor(): string { 
        return this.data.clickableImageTextColor;
    }

    public get renderClickableImageHorizontalRule(): boolean { 
        return this.data.renderClickableImageHorizontalRule;
    }

    public get clickableImageRenderSize(): string{
        return this.data.clickableImageRenderSize;
    }

    public goToUrl(clickableImage: ClickableImage): void {
        location.href = clickableImage.linkUrl;
    }
}
