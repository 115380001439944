import { IResult } from '@shared/Models/ResultModel';
import { Link } from '@shared/Models/sharedModels';

export class ClientAnalyticsDTO {
    public screenResolution: string;
    public viewportWidth: number;
    public viewportHeight: number;
    public viewportSize: string;
    public documentEncoding: string;
    public screenColors: string;
    public userLanguage: string;

    constructor() {
        this.screenResolution = `${window?.screen?.width}x${window?.screen?.height}`;
        this.viewportWidth = window?.innerWidth;
        this.viewportHeight = window?.innerHeight;
        this.viewportSize = `${window?.innerWidth}x${window?.innerHeight}`;
        this.screenColors = window?.screen?.colorDepth?.toString();
        this.userLanguage = window?.navigator?.language;
        this.documentEncoding = window?.document?.characterSet;
    }
}

export interface IAnalyzableEvent {
    analytics: ClientAnalyticsDTO,
    ac: any
}

export class AnalyzableEvent implements IAnalyzableEvent {
    public analytics: ClientAnalyticsDTO = new ClientAnalyticsDTO();
    public ac: any = (<any>window)?.fisheriesModule?.ac ?? {};
}

export class FSAnalyticsDTO<T> {
    public clientInfo: ClientAnalyticsDTO;
    public documentLocation: string;
    public referrer: string;
    public value: T;
    public timing: any;
    public ac: any;

    constructor(value: T, additionalContext?: [string, string]) {
        this.value = value;
        this.documentLocation = window?.location.href;
        this.referrer = window?.document?.referrer;
        this.clientInfo = new ClientAnalyticsDTO();
        this.ac = (<any>window)?.fisheriesModule?.ac ?? {};

        if (additionalContext)
            this.ac[additionalContext[0]] = additionalContext[1];
    }
}

export class RoutableEvent {
    private static targetBase: string = "api/fa/event"
    private _event: string

    public constructor(event: string) {
        this._event = event;
    }

    public metaData: object;

    public get url() { return `${RoutableEvent.targetBase}/${this._event}` }
}

export class GenericEvent extends RoutableEvent {

    constructor(event: string) {
        super(event);
    }

    public category: string;
    public action: string;
    public label?: string;
    public value?: number;
}

export enum ProductList {
    BrowseResultList = 'Browse Result List',
    SearchResultList = 'Search Result List',
    RecommendationResultList = 'Recommendations Result List'
}

export class ResultInteractionEvent extends RoutableEvent {
    constructor(event: string) {
        super(event);
    }
    public result: IResult;
    public listName: string;
    public position: number;

    fsls : string;
    fslsid : string;
    fslsp: string[];
}


export class ResultPaginationEvent extends RoutableEvent {

    constructor() {
        super("browse/pagination/changed")
    }
    pageNumber: number;
    paginationUrl: string;

}

export class GlobalImpressionEvent extends RoutableEvent {
    constructor() {
        super("global/impression");
    }
    key: string;
    id: string;
    params: object;
}

export class ResultImpressionEvent extends ResultInteractionEvent {
    constructor() {
        super("browse/product/impression");
    }
}

export class ResultClickEvent extends ResultInteractionEvent {
    constructor() {
        super("browse/product/click");
    }

  
}

export class ProductViewEvent extends RoutableEvent {
    constructor() {
        super("product/viewed");
    }
    productId: string;
    productName: string;
    brands: Link[];
    categories: Link[];
    skuId: number;
    itemId: string;
    skuName: string;
    isRefresh: boolean;
    browseReferrer : object;
}

export class ProductSkuSelectionChangedEvent extends RoutableEvent {
    constructor() {
        super("product/sku/selection-changed");
    }
    productId: string;
    productName: string;
    brand: string;
    category: any;
    selectedSkuId: number;
    selectedItemId: string;
    selectedSkuName: string;
    attributeCount: number;
    attributeId: number;
    attributeName: string;
    attributeType: string;
    attributeValuesCount: number;
    selectedAttributeValue: string;
}

export class CheckoutStepEvent extends RoutableEvent {
    constructor(step: number) {
        super(`checkout/step/${step}`);
        this.step = step;
    }
    public step: number;
    public option?: string;
}


export class SearchSubmitEvent extends RoutableEvent {
    constructor() {
        super("global/search-submit");
    }

    public terms: string;
    public rawTerms: string;
    public isClick: boolean;
    public searchType: number;
    public suggestionPosition: number;
    public keyCount: number;
}

export class InputFocusedEvent extends RoutableEvent {
    constructor() {
        super("global/input-focused");
    }

    public InputId: string;
    public DataContext: object;
}

export enum MetaDataKeys {
    AutocompleteResultId = "autocomplete_resultId"
}



