<div id="fsBreadcrumbs__container" class="container">
    <ng-container *ngIf="shouldShowHome">
        <a class="fsBreadcrumbs__ancestor" href="/">Home</a>
        <span class="fs-icon fs-chevron-right"></span>
    </ng-container>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index;">
        <ng-container *ngIf="getLink(breadcrumb)">
            <a class="fsBreadcrumbs__ancestor" [href]="getLink(breadcrumb) | leadingslash">
                {{getText(breadcrumb)}}
            </a>
            <ng-container *ngIf="shouldShowEndSeparator(i)"><span class="fs-icon fs-chevron-right"></span></ng-container>
        </ng-container>
        <div class="fsBreadcrumbs__current" *ngIf="!getLink(breadcrumb)">{{getText(breadcrumb)}}</div>
    </ng-container>
    <div class="fsBreadcrumbs__flex-end d-flex d-md-none"></div>
</div>
