import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { PriceParameter } from '@shared/Models/PriceParameter';
import { P21PriceRounder } from '@shared/Services/p21-priceRounder.service';
import { PriceService } from '@shared/Services/price.service';
import { plainToClass } from 'class-transformer';
import { SkuPrice } from '@shared/Models/SkuModel';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'sku-price',
    templateUrl: './sku-price.component.html'
})

export class SkuPriceComponent {
    @Input() displaySimplePrice = false;
    @Input() displaySale = false;
    @Input() displayPriceForQty = false;
    @Input() canSeeReferencePricing = false;
    @Input() unitPriceLabel: string;

    constructor(
        private _rounder: P21PriceRounder,
        private _priceParameter: PriceParameter,
        private _priceService: PriceService,
        @Inject(PLATFORM_ID) private platformId) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId) && !this.hasActivePrice && !this._priceParameter.priceRequestInProgress) {
            this._priceParameter.priceRequestInProgress = true;

            this._priceService.getSkuPrices(this._priceParameter.sku.invMastUid.toString()).subscribe((res) => {
                if (res.success) {
                    if (res.value[0]) {
                        this._priceParameter.skuPrice = plainToClass(SkuPrice, res.value[0]);
                        this._priceParameter.updatePrice();
                    }
                }

                this._priceParameter.priceRequestInProgress = false;
            });
        }
    }

    public get hasActivePrice(): boolean {
        return this._priceParameter.activePrice != null;
    }

    public get isPurchasable(): boolean {
        return this._priceParameter.sku.availability?.isPurchasable;
    }

    public get displayFull(): boolean {
        return !this.displaySimplePrice && !this.displaySale && !this.displayPriceForQty;
    }

    public get shouldShowReferencePrice(): boolean {
        if (this.canSeeReferencePricing) {
            return this.unitPrice < this.streetPrice;
        }
    }

    public get priceForQuantity(): number {
        const price = this.unitPrice;
        const qty = this._priceParameter.quantity;
        const total = qty * price;
        return total;
    }

    public get shouldShowWasPrice(): boolean {
        return this._priceParameter.activePrice.baseUnitPrice < this._priceParameter.activePrice.baseUnitRegularPrice;
    }

    public get activeUom(): string {
        return this._priceParameter.uom.displayName;
    }

    public get unitPrice(): number {
        if (this._priceParameter.activePrice) {
            return this._rounder.roundPrice(this._priceParameter.activePrice.baseUnitPrice, this._priceParameter.uom.unitSize, 1);
        }
    }

    public get wasPrice(): number {
        return this._rounder.roundPrice(this._priceParameter.activePrice.baseUnitRegularPrice, this._priceParameter.uom.unitSize, 1);
    }

    public get amountSaved(): number {
        return this._rounder.round(this.wasPrice - this.unitPrice, 2);
    }

    public get listPrice(): number {
        return this._rounder.round(this._priceParameter.skuPrice.baseUnitListPrice * this._priceParameter.uom.unitSize, 2);
    }

    public get streetPrice(): number {
        let price = this._priceParameter.skuPrice.getStreetPriceForQuantity
                (this._priceParameter.quantity * this._priceParameter.uom.unitSize);

        return this._rounder.round(price.baseUnitPrice * this._priceParameter.uom.unitSize, 2);
    }

    public get hasListPrice(): boolean {
        return this.listPrice > 0;
    }
    
    public get hasStreetPrice(): boolean {
        return this.streetPrice > 0;
    }

}
