import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, WritableSignal, signal } from '@angular/core';
import { AssortmentSettings } from '@shared/Models/CatalogModel';
import { Assortment, GetAssortmentRequest } from '@shared/Models/OrderHelper';
import { AssortmentService } from '@shared/Services/assortment-service';
import { AssortmentResultsComponent } from './assortment-results.component';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from '@shared/Services/analytics.service';

@Component({
    selector: 'assortment',
    templateUrl: './assortment.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        AssortmentResultsComponent
    ],
    providers: [
        AssortmentService,
        AnalyticsService
    ]
})

export class AssortmentComponent implements OnInit {
    private _assortments: WritableSignal<Assortment[]>;
    private _assortmentConfigs: AssortmentSettings[];

    @Input()
    public assortmentSettingsFactory: () => AssortmentSettings[];

    @Input()
    public requestedAssortments: AssortmentSettings[];

    @Input()
    public url: string;

    @Input()
    public prependExplictAssortments = true;

    @Input()
    public fsls: string;

    @Output()
    public onAssortmentLoaded = new EventEmitter<Assortment[]>();

    constructor(
        private _assortmentService: AssortmentService
    ) {
        this._assortments = signal<Assortment[]>([]);
    }

    ngOnInit() {
        this._assortmentConfigs = this.assortmentSettingsFactory ? this.assortmentSettingsFactory() : new Array<AssortmentSettings>();

        if (this.requestedAssortments) {
            if (this.prependExplictAssortments) {
                this._assortmentConfigs = this._assortmentConfigs.splice(0, 0, ...this.requestedAssortments);
            }
            else {
                this._assortmentConfigs.push(...this.requestedAssortments);
            }
        }

        for (let i = 0; i < this._assortmentConfigs.length; i++) {
            const assortmentId = this._assortmentConfigs[i].name;
            var filters = this._assortmentConfigs[i].filters;
            const placeHolder = new Assortment();
            placeHolder.assortmentId = assortmentId;
            this._assortments.update(old => {
                old.push(placeHolder)
                return old;
            });

            var request = new GetAssortmentRequest();
            request.url = this.url ?? "";
            request.assortmentId = assortmentId;
            request.filters = filters;

            this._assortmentService.getAssortment(request).subscribe((res) => {
                if (res && res.length > 0) {
                    for (let j = i; j < this._assortments().length; j++) {
                        if (this._assortments()[j].assortmentId == assortmentId) {
                            this._assortments.update(old => {
                                old.splice(j, 1, ...res);
                                return old;
                            });

                            this.onAssortmentLoaded.next(res);

                            break;
                        }
                    }
                }
            });
        }
    }

    public get assortments(): Assortment[] {
        return this._assortments();
    }

}
