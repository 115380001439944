import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boldterms'
})

export class BoldTermsPipe implements PipeTransform {
    public transform(text: string, search: string): string {
        let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        pattern = pattern.split(' ').filter((t) => {
            return t.length > 0;
        }).join('|');
        const regex = new RegExp(pattern, 'gi');

        return search ? text.replace(regex, (match) => `<b>${match}</b>`) : text;
    }
}
