import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Link } from '@shared/Models/sharedModels';

@Injectable()
export class BreadcrumbsService {
    public breadcrumbs: Link[];

    constructor(private _route: ActivatedRoute) { }

    public constructBreadcrumbs(route = this._route.root, url: string = '', breadcrumbs: Link[] = [], overrideParam: string = ''): Link[] {
        let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
        let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

        const nextUrl = path ? `${url}/${path}` : url;

        if (label.length > 0) {
            let id = route.snapshot.params['id'];

            if (id) {
                if (overrideParam.length > 0) {
                    label = overrideParam;
                } else {
                    label += ` #${id}`;
                }
            }

            const breadcrumb: Link = {
                text: label,
                linkUrl: nextUrl,
            };

            const newBreadcrumbs = breadcrumb.linkUrl ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

            if (route.firstChild) {
                return this.constructBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs, overrideParam);
            }

            newBreadcrumbs[newBreadcrumbs.length - 1].linkUrl = null;

            return newBreadcrumbs;
        }

        if (route.firstChild) {
            return this.constructBreadcrumbs(route.firstChild, nextUrl, breadcrumbs, overrideParam);
        }

        breadcrumbs[breadcrumbs.length - 1].linkUrl = null;

        return breadcrumbs;
    }
}
