import { Component } from '@angular/core';
import { ClickableImageContainerComponent } from './clickable-image-container.component';

@Component({
    selector: 'clickable-image-container-static',
    templateUrl: './clickable-image-container-static.component.html'
})
export class ClickableImageContainerStaticComponent extends ClickableImageContainerComponent {
    public get columnClass(): string {
        // angular side can support specifying exact column count
        // keeping as a reminder in case we decide to transition
        
        // switch (this.config['columns']) {
        //     case 2:
        //         return 'col-md-6';
        //     case 3:
        //         return 'col-md-4';
        //     case 4:
        //         return 'col-md-3';
        //     case 5:
        //         return 'col-md-3 col-lg-5ths';
        //     case 6:
        //         return 'col-md-3 col-lg-2';
        //     default:
        //         return 'col-md-3';
        // }

        if (this.clickableImageRenderSize === 'Large') {
            return 'col-md-4 col-6 pb-1 pt-1';
        }

        if (this.clickableImageRenderSize === 'Small') {
            return 'col-md-2 col-4 pb-1 pt-1';
        }

        return 'col-md-3 col-6 pb-1 pt-1';
    }
}
