import { Component, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InvasiveErrorComponent } from './invasive-error.component';

@Component({
    selector: 'error-modal',
    templateUrl: './error-modal.component.html'
})

export class ErrorModalComponent extends InvasiveErrorComponent implements OnDestroy {
    public errorModalSubscription: Subscription;
    public errorModalShownSubscription: Subscription;

    @ViewChild('errorModal', { read: ModalDirective }) public errorModal: ModalDirective;

    constructor(
        public changeDetector: ChangeDetectorRef
    ) {
        super(changeDetector);
    }

    ngOnDestroy() {
        if (this.errorModalSubscription) {
            this.errorModalSubscription.unsubscribe();
        }
    }

    public onHidden(): void {
        this.selfReference.destroy();

        if (!this.allowDismissal) {
            window.location.reload();
        }
    }

    public closeModal(): void {
        this.errorModal.hide();
    }
}
