<div class="container" *ngIf="clickableImageContainerTitle">
    <h2 [ngClass]="{'fs-heading-separator' : renderClickableImageHorizontalRule}" 
    [class]="clickableImageTextColor" 
    class="pt-4 mt-4">{{clickableImageContainerTitle}}</h2>
</div>

<clickable-image-container-slider [data]="data" *ngIf="configTypeIsSlider"></clickable-image-container-slider>

<clickable-image-container-static [data]="data" *ngIf="configTypeIsStatic"></clickable-image-container-static>

<hr *ngIf="!clickableImageContainerTitle && renderClickableImageHorizontalRule">