<li class="nav-item nav-item--user">
    <a (click)="openLoginModal(loginModal)" class="nav-link">
        <span class="fs-icon fs-user-profile" aria-hidden="true"></span> <div class="nav-item-text-wrap">Login <small>or Register</small></div>
    </a>
</li>

<ng-template #loginModal>
    <span class="fs-icon fs-close" aria-label="Close" (click)="closeLoginModal()"></span>

    <div class="modal-body">
        <mat-spinner *ngIf="routeNotSet" aria-hidden="true"></mat-spinner>
        <router-outlet name="login"></router-outlet>
    </div>
</ng-template>
