import { ErrorBarComponent } from '@Error-Handler/Components/error-bar.component';
import { ErrorHeaderComponent } from '@Error-Handler/Components/error-header.component';
import { ErrorModalComponent } from '@Error-Handler/Components/error-modal.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, ViewContainerRef } from '@angular/core';
import { FSError, FSResult } from '@shared/Models/FSResultModel';
import { UnresolvedError } from '@shared/Models/ErrorHandlerModel';
import { BehaviorSubject, Observable } from 'rxjs';
declare var fisheriesModule: any;

@Injectable()
export class ErrorHandlerService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string
    ) {
        this._origin = originUrl;
    }

    public errorHeaderCreated: boolean;

    public postUnresolvedErrors(): Observable<FSResult<UnresolvedError[]>> {
        return this.http.post<FSResult<UnresolvedError[]>>(`${this._origin}/errors/currentshopper?cid=${(window as any)
            .fs.clientId}`, JSON.stringify(fisheriesModule.unresolvedErrors));
    }

    public registerErrorContainers(
        modal: ViewContainerRef,
        toast: ViewContainerRef,
        bar: ViewContainerRef,
        header: ViewContainerRef): void {
        if (typeof window !== 'undefined') {
            const fsm: any = (<any>window).fisheriesModule;

            if (fsm && !fsm.errorModalContainer) {
                fsm.errorModalContainer = modal;
                fsm.errorToastContainer = toast;
                fsm.errorBarContainer = bar;
                fsm.errorHeaderContainer = header;
                fsm.unresolvedErrors = [];
                fsm.errorRetrySubject = new BehaviorSubject<boolean>(false);
                fsm.errorRetryObservable = fsm.errorRetrySubject.asObservable();
                fsm.updateErrorsSubject = new BehaviorSubject<boolean>(false);
                fsm.updateErrorsObservable = fsm.updateErrorsSubject.asObservable();
            }
        }
    }

    // to do, refactor to named parameter pattern
    public createErrorModal(
        title: string,
        message: string,
        httpErrorResponse?: HttpErrorResponse,
        fsError?: FSError,
        allowDismissal?: boolean,
        displayRetry?: boolean,
        displayGoBack?: boolean,
        goBackUrl?: string,
        goBackText?: string,
        dismissOnTimeout?: number,
        hideReload?: boolean,
        hideHelp?: boolean,
        urgency?: string): void {

        var componentRef = fisheriesModule.errorModalContainer.createComponent(ErrorModalComponent);

        componentRef.instance.title = title || 'Oops! Something went wrong.';
        componentRef.instance.message = message || 'Request failed. Please try again.';
        componentRef.instance.allowDismissal = allowDismissal;
        componentRef.instance.displayRetry = displayRetry;
        componentRef.instance.displayGoBack = displayGoBack;
        componentRef.instance.goBackUrl = goBackUrl;
        componentRef.instance.goBackText = goBackText;
        componentRef.instance.dismissOnTimeout = dismissOnTimeout;
        componentRef.instance.hideReload = hideReload;
        componentRef.instance.hideHelp = hideHelp;
        componentRef.instance.urgency = urgency;
        componentRef.instance.selfReference = componentRef;
        componentRef.changeDetectorRef.detectChanges();

        this.pushToUnresolvedErrors(
            componentRef, title, message, httpErrorResponse, fsError
        );
    }

    // to do, refactor to named parameter pattern
    public createErrorBar(
        title: string,
        message: string,
        urgency: string,
        httpErrorResponse?: HttpErrorResponse,
        fsError?: FSError,
        displayGoBack?: boolean,
        goBackUrl?: string,
        goBackText?: string,
        dismissOnTimeout?: number): void {

        let componentRef = fisheriesModule.errorBarContainer.createComponent(ErrorBarComponent);

        componentRef.instance.title = title || 'Oops! Something went wrong.';
        componentRef.instance.message = message || 'Request failed. Please try again.';
        componentRef.instance.urgency = urgency;
        componentRef.instance.displayGoBack = displayGoBack;
        componentRef.instance.goBackUrl = goBackUrl;
        componentRef.instance.goBackText = goBackText;
        componentRef.instance.dismissOnTimeout = dismissOnTimeout;
        componentRef.instance.selfReference = componentRef;
        componentRef.changeDetectorRef.detectChanges();

        this.pushToUnresolvedErrors(
            componentRef, title, message, httpErrorResponse, fsError
        );
    }

    public createErrorHeader(
        title: string,
        message: string): void {

        this.errorHeaderCreated = true;

        let componentRef = fisheriesModule.errorHeaderContainer.createComponent(ErrorHeaderComponent);

        componentRef.instance.title = title || 'Oops! Something went wrong.';
        componentRef.instance.message = message || 'Request failed. Please try again.';
        componentRef.instance.selfReference = componentRef;
        componentRef.changeDetectorRef.detectChanges();

        this.pushToUnresolvedErrors(
            componentRef, title, message
        );
    }

    private pushToUnresolvedErrors(
        componentRef: any,
        title: string,
        message: string,
        httpErrorResponse?: HttpErrorResponse,
        fsError?: FSError): void {

        const error = new UnresolvedError();
        error.componentRef = componentRef;
        error.title = title;
        error.message = message;
        error.httpErrorResponse = httpErrorResponse;
        error.fsError = fsError;

        fisheriesModule.unresolvedErrors.push(error);

        fisheriesModule.updateErrorsSubject.next(true);
    }
}
