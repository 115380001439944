export function calculateFSLSUrl(sourceUrl: string, fsls: string, fslsid: string, fslsp: string[]){
    let url = sourceUrl;
        if(url && url.length > 0){
            if(fsls){
                let seperator = url.indexOf("?") > 0 ? "&" : "?";
                
                url = `${url}${seperator}fsls=${fsls}`;
                seperator = "&"

                if(fslsid){
                    url = `${url}${seperator}fslsid=${fslsid}`; 
                }

                if(fslsp?.length > 0){
                    for(let i = 0; i < this.fslsp.length; i++){
                        url = `${url}${seperator}fslsp${i}=${fslsp[i]}`;
                    }
                }
            }

            return url;
    }
}