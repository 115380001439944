import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FSCartDTOResult } from '@shared/Models/CartModel';
import { AddCartLineRequestDTO, AddToCartDTO, AddToCartSkuDTO } from '@shared/Models/CartServiceModel';
import { Sku, UnitOfMeasure } from '@shared/Models/SkuModel';
import { SubstituteData } from '@shared/Models/SubstituteModel';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { SettingsAccessor } from './settings.accessor';

@Injectable()
export class AddToCartService {
    private static readonly __currentCartMagicString: string = "currentcart";
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string, 
        private _settingsAccessor: SettingsAccessor) {

        this._origin = originUrl;
    }

    private createCartLine(quantity: number, uom: UnitOfMeasure, sku: Sku, notes: string[]): AddToCartDTO {
        var line = new AddToCartDTO();
        line.qty = quantity;
        line.unitOfMeasure = uom; 
        line.sku = plainToInstance(AddToCartSkuDTO, sku, { excludeExtraneousValues: true  });
        line.taxable = this._settingsAccessor.taxToggleVisibility === 'disabled';
        line.notes = notes;
        return line;
    }

    public createCartLineOneNote(quantity: number, uom: UnitOfMeasure, sku: Sku, note: string = "",): AddToCartDTO {
        var notes = new Array<string>();
        notes.push(note);
        var line = this.createCartLine(quantity, uom, sku, notes);
        return line;
    }

    public addLines(source:string, dtos: AddToCartDTO[], cartId:string = AddToCartService.__currentCartMagicString): Observable<FSCartDTOResult> {
        const addRequest : AddCartLineRequestDTO = new AddCartLineRequestDTO();
        addRequest.items = dtos;

        return this.http.post<FSCartDTOResult>(`${this._origin}/api/cart/${cartId}/lines?cid=${(window as any)
            .fs.clientId}&source=${source}`, addRequest);
    }
    
    public addSubstituteToCart(source: string, event: SubstituteData, selectedUOM: UnitOfMeasure, callBack): void {
        const lines: AddToCartDTO[] = new Array<AddToCartDTO>();
        const line: AddToCartDTO = this.createCartLineOneNote(1, selectedUOM, event.sku);
        lines.push(line);

        this.addLines(source, lines).subscribe(() => callBack());
    }
}
