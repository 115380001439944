<ul id="fsAutosuggestResults">
     <li class="fsAutosuggestResults__result-wrapper--default" *ngIf="hasAutosuggestHighlights">
          <ul class="fsAutosuggestResults__highlight-wrapper">
               <li>
                    <autosuggest-highlight class="container fsAutosuggestResults__highlight" [resultId]="defaultResultId" [highlights]="defaultHighlights"></autosuggest-highlight>
               </li>
          </ul>
     </li>
     <li *ngFor="let result of results; let i = index" 
          class="fsAutosuggestResults__result-wrapper" 
          [ngClass]="{'selected' : getIsSelected(result)}" 
          autosuggestResult 
          #autosuggestResultRef 
          [result]="result" 
          [resultIndex]="i"></li>       
</ul>  