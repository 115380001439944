import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FSResult } from '@shared/Models/FSResultModel';
import { VerificationMethodDTO, FSPendingDTO, PendingDTO } from '../Models/LoginDTO';
import { CompleteNewUserInvitationDTO, CompleteExistingUserInvitationDTO, NewUserValidationResponse, ExistingUserValidationResponse, RegistrationDTO, FSRegistrationResult, RegisterCustomerDTO, GuestDetails, ValidationDTO } from '../Models/RegistrationDTO';

@Injectable()
export class RegistrationService {
    private static __registrationServiceEndpoint = '/api/registration/';
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    private get endpoint(): string {
        return this._origin + RegistrationService.__registrationServiceEndpoint;
    }
    
    public completeInvitationNewUser(dto: CompleteNewUserInvitationDTO): Observable<FSResult<CompleteNewUserInvitationDTO>> {
      return this.http.post<FSResult<CompleteNewUserInvitationDTO>>(this.endpoint + 'complete-invitation-new', dto);
    }
   
    public completeInvitationExistingUser(dto: CompleteExistingUserInvitationDTO): Observable<FSResult<CompleteExistingUserInvitationDTO>> {
        return this.http.post<FSResult<CompleteExistingUserInvitationDTO>>(this.endpoint + 'complete-invitation-existing', dto);
    }
  
    public validateInvitationNewUser(dto: ValidationDTO): Observable<FSResult<NewUserValidationResponse>> {
        return this.http.post<FSResult<NewUserValidationResponse>>(this.endpoint + 'validate-invitation-new-user', dto);
    }
  
    public validateInvitationExistingUser(dto: ValidationDTO): Observable<FSResult<ExistingUserValidationResponse>> {
        return this.http.post<FSResult<ExistingUserValidationResponse>>(this.endpoint + 'validate-invitation-existing-user', dto);
    }
  
    public postRegistrationRequest(registration: RegistrationDTO): Observable<FSRegistrationResult> {
        return this.http.post(this.endpoint, registration).pipe(
            map((r: FSResult<RegistrationDTO>) => plainToClassFromExist(new FSResult<RegistrationDTO>(), r)));
    }
    
    public postRegisterCustomer(customer: RegisterCustomerDTO): Observable<FSResult<RegisterCustomerDTO>> {
        return this.http.post<FSResult<RegisterCustomerDTO>>(this.endpoint + 'register-customer', customer);
    }

    public getGuestDetails(): Observable<FSResult<GuestDetails>> {
        return this.http.get<FSResult<GuestDetails>>('/api/guest');
    }
    
    public putGuestDetails(guest: GuestDetails): Observable<FSResult<GuestDetails>> {
        return this.http.put<FSResult<GuestDetails>>('/api/guest', guest);
    }

    public postRegistrationRequestCustomerId(registration: RegistrationDTO, customerId: number): Observable<FSRegistrationResult> {
        return this.http.post(this.endpoint + `${customerId}/`, registration).pipe(
            map((r: FSResult<RegistrationDTO>) => plainToClassFromExist(new FSResult<RegistrationDTO>(), r)));
    }

    public postValidationRequest(method: VerificationMethodDTO, customerId: number): Observable<FSResult<boolean>> {
        return this.http.post<FSResult<boolean>>(`registration/pendingregistration/` + customerId + `/requestvalidation`, method);
    }

    public getPendingDTO(customerId: number): Observable<FSPendingDTO> {
        return this.http.get<FSResult<PendingDTO>>(`registration/pendingregistration/` + customerId);
    }

}
