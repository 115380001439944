import { maketype } from "@shared/Common/ClassTransformerHelpers";
import { Type } from "class-transformer";
import { Sku, SkuPrice, UnitOfMeasure } from "./SkuModel";

export class SubstitutesDTO {
    public substitutes: [string, SubstituteData[]];
}

export class SubstituteData {
    @Type(maketype(Sku))
    public sku: Sku;
    @Type(maketype(SkuPrice))
    public pricing: SkuPrice;
}

export class SubstituteManager {
    public sku: Sku;
    public skuPrice: SkuPrice;
    public parentSkuIndex: number;
    public parentSku: Sku;
    public selectedUom: UnitOfMeasure;
    public selectedQuantity: number;
    
    onSuccess?: () => void;
}