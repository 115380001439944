import { Component, Input } from '@angular/core';
import { PasswordHint } from '@shared/Modules/Identity/Services/password-validation.service';

@Component({
    selector: 'password-hints',
    templateUrl: './password-hints.component.html'
})
export class PasswordHintsComponent {
    @Input() shouldShowPasswordMatchMessage: boolean;
    @Input() passwordInputTouched: boolean
    @Input() passwordHints: PasswordHint[];
}
